import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Row, Col, DatePicker } from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import { momentDate } from "utils/Date";
import { NON_EMPTY_REGEX, NON_CHARACTER } from "common/Constants";

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  margin-top: 20px;
  font-weight: bold;
`;
const Styleddiv = styled.div`
  .ant-form-item-label > label {
    font-weight: bold;
  }
`;

const BasicInformation = ({ step, setStep, title, setValues, values }) => {
  const [form] = Form.useForm();
  const containerRef = useRef(null);

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const onFinish = (values) => {
    setValues(values);
    setStep(step + 1);
  };

  useEffect(() => {
    form.setFieldsValue(values);
  }, [values])

  return (
    <Styleddiv>
      <Form
        form={form}
        name="AddDepartment"
        layout="vertical"
        initialValues={{
          name: "",
          latitude: "",
          longtitude: "",
          start_date: momentDate(new Date()),
          end_date: "",
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={
            title === "Add Location"
              ? "Location Name"
              : title === "holiday"
              ? "Holiday Name"
              : title === "break"
              ? "Break Name"
              : "Department Name"
          }
          name="name"
          rules={[
            {
              required: true,
              message:
                title === "Add Location"
                  ? "Please enter location name"
                  : title === "holiday"
                  ? "Please enter holiday name"
                  : title === "break"
                  ? "Please enter Break name"
                  : "Please enter department name",
            },
          ]}
        >
          <Input
            placeholder={
              title === "Add Location"
                ? "Location Name"
                : title === "holiday"
                ? "Department name"
                : title === "break"
                ? "Break Name"
                : "Department name"
            }
            maxLength={25}
          />
        </Form.Item>
        {title === "Add Location" && (
          <>
            <Form.Item
              label="Latitude"
              name="location_latitude"
              rules={[
                {
                  required: true,
                  pattern: NON_CHARACTER,
                  message: "Please enter latitude!",
                },
              ]}
            >
              <Input placeholder="Enter latitude" maxLength={20} />
            </Form.Item>
            <Form.Item
              label="Longitude"
              name="location_longitude"
              rules={[
                {
                  required: true,
                  pattern: NON_CHARACTER,
                  message: "Please enter longitude!",
                },
              ]}
            >
              <Input placeholder="Enter longitude" maxLength={20} />
            </Form.Item>
          </>
        )}

        {title === "holiday" && (
          <>
            <Row>
              <Col xs={24} xl={24} ref={containerRef}>
                <Form.Item
                  label="Start date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Please enter start date!",
                    },
                    () => ({
                      validator(rule, value) {
                        const date = moment(value);
                        return date.isValid()
                          ? Promise.resolve()
                          : Promise.reject();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    onChange={validateDateRange}
                    placeholder="Select start date"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} ref={containerRef}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.start_date !== currentValues.start_date
                  }
                >
                  {() => (
                    <Form.Item
                      label="End date"
                      name="end_date"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Please enter start date!",
                        },
                        () => ({
                          validator(rule, value) {
                            const date = moment(value);
                            return date.isValid()
                              ? Promise.resolve()
                              : Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        placeholder="Select end date"
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <div className="text-end w-100">
          <StyledButton type="primary" htmlType="submit">
            Next
          </StyledButton>
        </div>
      </Form>
    </Styleddiv>
  );
};

export default BasicInformation;
