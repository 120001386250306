import React from "react";
import { Row, Steps } from "antd";
import styled from "@emotion/styled";
import { CheckOutlined } from "@ant-design/icons";
const { Step } = Steps;
const Styleddiv = styled(Row)`
  img,
  svg {
    vertical-align: unset;
    font-size: 25px;
    background: #26A4FF;
    border-radius: 50px;
    padding: 6px;
  }
  .icon-color {
    color: #fff;
  }
  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: transparent;
    border: 3px solid #26A4FF;
  }
  .ant-steps-dot .ant-steps-item-tail::after,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    width: calc(100% - 20px);
    height: 10px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #26A4FF;
    font-size: 16px;
    font-weight: bold;
  }
  .ant-steps-item-title {
    font-weight: bold;
  }
  .ant-steps-item-wait
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: transparent;
    border: 3px solid rgba(0, 0, 0, 0.25);
  }
  .ant-form-item-label > label {
    font-weight: bold;
  }
  .ant-steps-item-icon {
    margin-top: 5px;
    width: 25px;
    height: 25px;
    line-height: 23px;
  }
  .ant-steps-item-custom
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > .ant-steps-icon {
    top: -5px;
  }
`;
const StepsProgress = ({ step, stepdata }) => {
    return (
        <>
            <Styleddiv className="w-100 mb-2">
                <Steps current={step} labelPlacement="vertical">
                    {stepdata.map((item, index) => (
                        <Step
                            title={item.title}
                            key={item.title}
                            icon={
                                step > index ? <CheckOutlined className="icon-color" /> : null
                            }
                        />
                    ))}
                </Steps>
            </Styleddiv>
        </>
    );
};
export default StepsProgress;
