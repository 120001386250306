import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Checkbox,
  Switch,
  Tabs
} from "antd";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import moment from "moment";
import {
  NON_EMPTY_REGEX,
  STATUS_CODE,
} from "common/Constants";
import {
  getAllDepartmentList,
  getLocations,
  getClientid,
  getLeaveCategoryList,
  getRoleList,
  getBandList,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { setUserList } from "redux/actions";
import { useDropzone } from "react-dropzone";
import { get, includes } from "lodash";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SelectTable from "../molecules/EditEmployeeRole";
import FormModal from "./FormModal";
import TabPane from "antd/lib/tabs/TabPane";

const StyledModal = styled(Form)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1b1b1b;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
  .check-box {
    font-size: 14px;
    font-weight: 500;
  }
  .ant-tabs-nav {
    background: #e6f5ff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    border-radius: 5px;
    padding-left: 20px;
  }
`;

const StyledDropzone = styled.div`
  text-align: center;
  width: 100%;
  height: 40px;
  background: #e3e3e3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    padding-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;

function EditEmployee({
  closeModal,
  getEmployeeList,
  allDepartmentList,
  EmployeeDetails,
  ClientId,
  updateUserList,
  roleList,
  bandList
}) {
  const [showLoader, toggleLoader] = useState(false);
  const [form] = Form.useForm();
  const [step, setStep] = useState(0);
  const { Option } = Select;
  const containerRef = useRef(null);
  const [filename, setFileName] = useState("");
  const [profileUploaded, setProfileUploaded] = useState(false);
  const [uniqueId, setUniqueId] = useState(generateUniqueId());
  const [storeId, setStoreId] = useState("");
  const [formValues, setFormValues] = useState({});
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);

  const [employeeStatusId, setEmployeeStatusId] = useState("1")
  const [bandId, setbandId] = useState("")

  useEffect(() => {
    setEmployeeStatusId(EmployeeDetails?.statusid !== "" ? EmployeeDetails?.statusid : "")
    setbandId(EmployeeDetails?.bandid !== "" ? EmployeeDetails?.bandid : "")
  }, [])

  const [activeTab, setActiveTab] = useState("0");

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        updateUserList(userList);
      }
    }
  };

  useEffect(() => {
    const {
      userid = "",
      fullname = "",
      startdate = "",
      dateofbirth = "",
      enddate = "",
      bandname = "",
      bandid = "",
      overtimestatus = "",
      salary = "",
      password = "",
      profileurl = "",
      gender = "",
      age = "",
      maritalstatus = "",
      nationality = "",
      facerecognition,
      roleid = "",
      uan = "",
      pan = "",
      address = "",
      phoneno = "",
      institutename = "",
      degree = "",
      specialization = "",
      yearofcompletion = "",
      companyname = "",
      jobtitle = "",
      professionalsince = "",
      hireddate = "",
      relevant = "",
      employeesponser = "",
      statusname = "",
      jobdescription = ""
    } = EmployeeDetails;
    setFormValues({
      user_id: userid,
      full_name: fullname,
      gender: gender,
      dateof_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      age: age,
      password: password,
      maritalstatus: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandName: bandname,
      bandId: bandid,
      overtime: overtimestatus,
      salary: salary,
      facerecognition: facerecognition,
      profile_url: profileurl != ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      uan: uan,
      pan: pan,
      address: address,
      phone: phoneno,
      institutename: institutename,
      degree: degree,
      specialization: specialization,
      yearofcompletion: yearofcompletion ? moment(yearofcompletion, 'YYYY-MM-DD') : null,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince ? moment(professionalsince, 'YYYY-MM-DD') : null,
      hireddate: hireddate ? moment(hireddate, 'YYYY-MM-DD') : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      status_id: employeeStatusId,
      description: jobdescription
    });
    form.setFieldsValue({
      user_id: userid,
      full_name: fullname,
      password: password,
      gender: gender,
      dateof_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      age: age,
      maritalstatus: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandName: bandname,
      overtime: overtimestatus,
      salary: salary,
      facerecognition: facerecognition,
      profile_url: profileurl != ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      uan: uan,
      pan: pan,
      address: address,
      phone: phoneno,
      institutename: institutename,
      degree: degree,
      specialization: specialization,
      yearofcompletion: yearofcompletion ? moment(yearofcompletion, 'YYYY-MM-DD') : null,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince ? moment(professionalsince, 'YYYY-MM-DD') : null,
      hireddate: hireddate ? moment(hireddate, 'YYYY-MM-DD') : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      description: jobdescription
    });
  }, [EmployeeDetails]);

  useEffect(() => {
    setSelectedRoleId(EmployeeDetails.roleid);
  }, [EmployeeDetails]);

  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  const onFinish = () => {
    const currentFormValues = form.getFieldValue();

    const updatedFormValues = {
      ...currentFormValues,
      selectedRoleId,
    };

    setFormValues(updatedFormValues);
  };


  useEffect(() => {
    onFinish();
  }, [selectedRoleId])

  useEffect(() => { }, [storeId]);

  const handlesubmit = async (values) => {
    try {
      toggleLoader(true);
      setUniqueId(generateUniqueId());
      let userId = form.getFieldValue("user_id");
        let splitUserId = userId.split("@");
        let userIdWithoutDomain = splitUserId[0];
        let updatedUserId = userIdWithoutDomain + storeId;
      const {
        user_id,
        full_name,
        start_date,
        password,
        end_date,
        gender,
        dateof_birth,
        age,
        nationality,
        maritalstatus,
        bandName,
        overtime,
        salary,
        facerecognition,
        uan,
        pan,
        address,
        phone,
        selectedRoleId,
        institutename,
        degree,
        specialization,
        yearofcompletion,
        companyname,
        jobtitle,
        professionalsince,
        hireddate,
        relevent,
        employeesponser,
        employeestatus,
        description
      } = formValues;

      const roleNames = roleList
        .filter((item) => selectedRoleId?.includes(item.roleId))
        .map((item) => item.roleName);

      const obj = {
        user_id: user_id,
        full_name: full_name,
        end_date: formValues?.start_date ? moment(formValues?.start_date?._d).format("YYYY-MM-DD") : "",
        start_date: formValues?.start_date ? moment(formValues?.start_date?._d).format("YYYY-MM-DD") : "",
        overtime_status: overtime,
        bandname: bandName,
        bandid: bandId ? bandId : "",
        gender: gender,
        dateofbirth: dateof_birth,
        age: age,
        password: password,
        maritalstatus: maritalstatus,
        nationality: nationality,
        salary: salary,
        facerecognition: facerecognition,
        role_id: selectedRoleId,
        role_name: roleNames ? roleNames : "",
        uan: uan,
        pan: pan,
        phoneno: phone,
        address: address,
        permanentaddress: address,
        presentaddress: address,
        profile_url: profileUploaded
          ? `https://tymeplusprofiledev.s3.ap-south-1.amazonaws.com/${ClientId}/${updatedUserId}/${storeId}.jpg`
          : EmployeeDetails?.profileurl,
        institutename: institutename,
        degree: degree,
        specialization: specialization,
        yearofcompletion: yearofcompletion ? moment(yearofcompletion?._d).format("YYYY-MM-DD") : "",
        companyname: companyname,
        jobtitle: jobtitle,
        professionalsince: professionalsince ? moment(professionalsince?._d).format("YYYY-MM-DD") : "",
        hireddate: hireddate ? moment(hireddate?._d).format("YYYY-MM-DD") : "",
        relevant: relevent,
        employeesponser: employeesponser,
        statusname: employeestatus,
        status_id: employeeStatusId,
        jobdescription: description
      }

      const response = await CALL_API(`add-users/${ClientId}`, "patch", {
        user_id: user_id,
        full_name: full_name,
        end_date: formValues?.start_date ? moment(formValues?.start_date?._d).format("YYYY-MM-DD") : "",
        start_date: start_date ? moment(start_date?._d).format("YYYY-MM-DD") : "",
        overtime_status: overtime,
        bandname: bandName,
        bandid: bandId ? bandId : "",
        gender: gender,
        dateofbirth: dateof_birth ? moment(dateof_birth?._d).format("YYYY-MM-DD") : "",
        age: age,
        password: password,
        maritalstatus: maritalstatus,
        nationality: nationality,
        salary: salary,
        facerecognition: facerecognition,
        role_id: selectedRoleId,
        role_name: roleNames ? roleNames : "",
        uan: uan,
        pan: pan,
        phoneno: phone,
        address: address,
        permanentaddress: address,
        presentaddress: address,
        profile_url: profileUploaded
          ? `https://tymeplussystemprofile-dev.s3.ap-south-1.amazonaws.com/${ClientId}/${user_id}/${storeId}.jpg`
          : EmployeeDetails?.profileurl,
        institutename: institutename,
        degree: degree,
        specialization: specialization,
        yearofcompletion: yearofcompletion ? moment(yearofcompletion?._d).format("YYYY-MM-DD") : "",
        companyname: companyname,
        jobtitle: jobtitle,
        professionalsince: professionalsince ? moment(professionalsince?._d).format("YYYY-MM-DD") : "",
        hireddate: hireddate ? moment(hireddate?._d).format("YYYY-MM-DD") : "",
        relevant: relevent,
        employeesponser: employeesponser,
        statusname: employeestatus,
        status_id: employeeStatusId,
        jobdescription: description
      });

      toggleLoader(false);
      setFileName("");
      form.resetFields();
      closeModal(true);

      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
        }
      }

      await getEmployeeList();
      await fetchMasterData(true);

    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  const Reset = () => {
    form.resetFields();
    setFileName("");
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    if (form.getFieldValue("user_id")) {
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          if (
            includes(
              [
                "image/jpeg",
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/tif",
                "image/tiff",
                "png",
                "jpg",
                "tif",
              ],
              get(file, "type", "")
            )
          ) {
            setFileName(acceptedFiles[0].path);
            setProfileUploaded(true);
            setUniqueId(generateUniqueId());
            let userId = form.getFieldValue("user_id");
            let splitUserId = userId.split("@");
            let userIdWithoutDomain = splitUserId[0];
            let updatedUserId = userIdWithoutDomain + uniqueId;
            const { code } = await CALL_API(
              `profile-upload/${ClientId}`,
              "post",
              {
                userid: updatedUserId,
                filetype: get(file, "type", ""),
                content: e.target.result,
                filename: uniqueId,
                emailid: '',
              }
            );
            setStoreId(uniqueId);
          } else {
            setProfileUploaded(false);
            message.error("Please upload valid image file.");
          }
        };
        reader.readAsDataURL(file);
        return file;
      });
    }
  }, [acceptedFiles]);

  const validateDateofBirth = (date) => {
  }

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId, record) => {
        const check = selectedRoleId?.includes(roleId) ? true : false;
        return (
          <Switch
            size="small"
            defaultChecked
            checked={check}
            onChange={(checked) => { getRoleId(roleId, checked); }}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
    onFinish();
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  };

  const handleResetAllRole = (e) => {
    setSelectedRoleId([])
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  }

  const tabTitles = [
    "Basic",
    "Role",
    "Education",
    "Work",
  ];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <StyledModal>
        <label
          className="bg-transparent border-0 mb-3 text-left cursor-pointer"
          onClick={() => closeModal(false)}
        >
          <b className="">Employee</b>{" "}
          <ArrowForwardIosIcon className="px-1" />
        </label>{" "}
        <b className="text-info pb-0 pt-1">Edit Employee</b>
        <Tabs
          defaultActiveKey={activeTab}
          className="mt-3"
          onChange={handleTabChange}
        >
          {tabTitles.map((title, index) => (
            <TabPane tab={title} key={index.toString()}>
              {activeTab === "0" && (
                <>
                  <div className="styled-card mb-5">
                    {step == 0 && (
                      <StyledModal>
                        <Form
                          className=""
                          form={form}
                          name="addemployeeform"
                          layout="vertical"
                          initialValues={{
                            user_id: "",
                            department_id: "",
                            full_name: "",
                            password: "",
                            gender: "",
                            age: "",
                            dateof_birth: "",
                            maritalstatus: "",
                            nationality: "",
                            uan: "",
                            pan: "",
                            address: "",
                            phone: "",
                            bandName: "",
                            overtime: "",
                            start_date: "",
                            halfpay: false,
                            facerecognition: false,
                          }}
                          onFinish={() => { handlesubmit() }}
                        >
                          <Row gutter={20}>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="full_name"
                                label="Full Name"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Please enter First name",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Full name"
                                  maxLength={15}
                                  onChange={onFinish}
                                  onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} >
                              <Form.Item
                                // className="add_employee_col"
                                name="user_id"
                                label="User ID"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Email ID",
                                  },
                                ]}
                              >
                                <Input placeholder="Email ID" onChange={onFinish} maxLength={60} />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="phone"
                                label="Phone No"
                              >
                                <Input
                                  type="number"
                                  placeholder="Phone No"
                                  maxLength={15}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="gender"
                                label="Gender"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Please Select gender",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select gender"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="male">Male</Option>
                                  <Option value="female">Female</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="age"
                                label="Age"
                              >
                                <Input
                                  type="number"
                                  placeholder="Age"
                                  maxLength={15}
                                  onChange={onFinish}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                label="Date of Birth"
                                name="dateof_birth"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Please enter date of birth",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-100"
                                  onChange={() => { validateDateofBirth(); onFinish() }}
                                  placeholder="Select Date of Birth"
                                  getPopupContainer={() => containerRef.current}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="maritalstatus"
                                label="Marital Status"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Please Select",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="single">Single</Option>
                                  <Option value="married">Married</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="nationality"
                                label="Nationality"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Please Select",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="indian">Indian</Option>
                                  <Option value="african">African</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="uan"
                                label="UAN"
                              >
                                <Input placeholder="UAN" onChange={onFinish} maxLength={20} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="pan"
                                label="PAN"
                              >
                                <Input placeholder="PAN" onChange={onFinish} maxLength={20} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="address"
                                label="Address"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Address",
                                  },
                                ]}
                              >
                                <Input placeholder="Address" onChange={onFinish} maxLength={60} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="bandName"
                                label="Band"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Please Select",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={(value, key) => { setbandId(key?.key); onFinish() }}
                                >
                                  {bandList?.map((data, index) => {
                                    return (
                                      <Option
                                        key={index}
                                        value={data.bandName}
                                      >
                                        {data.bandName}
                                      </Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="overtime"
                                label="Overtime Eligible"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Please Select",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="yes">Yes</Option>
                                  <Option value="no">No</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                label="Start Date"
                                name="start_date"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Please Select Start Date",
                                  },]}
                              >
                                <DatePicker
                                  className="w-100"
                                  onChange={() => { onFinish() }}
                                  value={null}
                                  format="YYYY-MM-DD"
                                  placeholder="Select start date"
                                  getPopupContainer={() => containerRef.current}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="salary" label=" Salary">
                                <Input placeholder="Salary" type="number" maxLength={10} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} className="mb-2">
                              <Form.Item name="facerecognition" valuePropName="checked">
                                <Checkbox>
                                  <p className="check-box">Face Recognition</p>
                                </Checkbox>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24}>
                              <Form.Item name="profile" label="Profile Pic">
                                <StyledDropzone {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <p>
                                    {filename !== "" ? <>{filename}</> : <> choose file</>}
                                  </p>
                                </StyledDropzone>
                              </Form.Item>
                            </Col>
                          </Row>
                          <div className="text-end">
                            <StyledButton type="primary" onClick={() => Reset()}>
                              Reset
                            </StyledButton>
                            <StyledButton
                              loading={showLoader}
                              type="primary"
                              htmlType="submit"
                            >
                              {showLoader ? "Update" : "Update"}
                            </StyledButton>
                          </div>
                        </Form>
                      </StyledModal>
                    )}
                  </div>
                </>
              )}

              {activeTab === "1" && (
                <>
                  <div className="styled-card mb-5">
                    {roleList.length === 0 && (
                      <StyledButton className="mt-2" onClick={() => setOpenModal(true)}>
                        Add Role
                      </StyledButton>
                    )}
                    {roleList?.length > 0 &&
                      <Checkbox
                        className="mt-3"
                        checked={selectAllRoleChecked}
                        onChange={handleSelectAllRole}
                      >
                        Select All
                      </Checkbox>
                    }
                    <SelectTable
                      step={step}
                      setStep={setStep}
                      columns={rolecolumns}
                      tabledata={roleList}
                      handleClickRole={handlesubmit}
                      handleReset={handleResetAllRole}
                      selectedRoleId={selectedRoleId}
                    />
                    <FormModal
                      isModalVisible={openModal}
                      closeModal={() => setOpenModal(false)}
                      Name="Role"
                    />
                  </div>
                </>
              )}

              {activeTab === "2" && (
                <>
                  <div className="styled-card mb-5">
                    <StyledModal>
                      <Form
                        className=""
                        form={form}
                        name="addEmployeeEducationForm"
                        layout="vertical"
                        initialValues={{
                          institutename: "",
                          degree: "",
                          specialization: "",
                        }}
                        onFinish={() => { handlesubmit() }}
                      >
                        <Row gutter={20}>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              name="institutename"
                              label="Institute Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Institute Name",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Institute Name"
                                maxLength={50}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="degree"
                              label="Degree/Diploma"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the Degree",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="degree"
                                maxLength={50}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="specialization"
                              label="Specialization"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the Specialization",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Specialization"
                                maxLength={50}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              label="Year of Completion"
                              name="yearofcompletion"
                            >
                              <DatePicker
                                className="w-100"
                                onChange={() => { onFinish(); }}
                                value={null}
                                format="YYYY-MM-DD"
                                placeholder="Select start date"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="text-end">
                          <StyledButton type="primary" onClick={() => Reset()}>
                            Reset
                          </StyledButton>
                          <StyledButton
                            loading={showLoader}
                            type="primary"
                            htmlType="submit"
                          >
                            {showLoader ? "Update" : "Update"}
                          </StyledButton>
                        </div>
                      </Form>
                    </StyledModal>
                  </div>
                </>
              )}

              {activeTab === "3" && (
                <>
                  <div className="styled-card mb-5">
                    <StyledModal>
                      <Form
                        className=""
                        form={form}
                        name="addemployeeidentityform"
                        layout="vertical"
                        initialValues={{
                          companyname: "",
                          jobtitle: "",
                          professionalsince: "",
                          hireddate: "",
                          employeesponser: "",
                          relevent: "",
                          maritalstatus: "",
                          description: ""
                        }}
                        onFinish={() => handlesubmit()}
                      >
                        <Row gutter={20}>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              name="companyname"
                              label="Company Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Company name",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Company name"
                                maxLength={50}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              name="jobtitle"
                              label="Job Title"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter job title",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Job Title"
                                maxLength={50}
                                onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              label="Professional Since"
                              name="professionalsince"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "Please enter date of birth",
                                },
                              ]}
                            >
                              <DatePicker
                                className="w-100"
                                onChange={onFinish}
                                placeholder="Select Date of Birth"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              label="Hired Date"
                              name="hireddate"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "Please enter date of birth",
                                },
                              ]}
                            >
                              <DatePicker
                                className="w-100"
                                onChange={onFinish}
                                placeholder="Select Date of Birth"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="employeesponser"
                              label="Employee Sponser"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "Please Select",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                getPopupContainer={() => containerRef.current}
                                onChange={onFinish}
                              >
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="employeestatus"
                              label="Status"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "Please Select",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                getPopupContainer={() => containerRef.current}
                                onChange={(value) => { setEmployeeStatusId(value === "active" ? "1" : "0"); onFinish() }}
                              >
                                <Option value="active">Active</Option>
                                <Option value="in active">In active</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              name="relevent"
                              label="Relevent"
                              rules={[
                                {
                                  required: true,
                                  pattern: NON_EMPTY_REGEX,
                                  message: "Please Select",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select"
                                getPopupContainer={() => containerRef.current}
                                onChange={onFinish}
                              >
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              name="description"
                              label="Description"
                            >
                              <Input.TextArea
                                onChange={onFinish}
                                placeholder="description"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="text-end">
                          <StyledButton type="primary" onClick={() => Reset()}>
                            Reset
                          </StyledButton>
                          <StyledButton
                            loading={showLoader}
                            type="primary"
                            htmlType="submit"
                          >
                            {showLoader ? "Update" : "Update"}
                          </StyledButton>
                        </div>
                      </Form>
                    </StyledModal>
                  </div>
                </>
              )}
            </TabPane>
          ))}
        </Tabs>
      </StyledModal>
    </>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  allDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  roleList: getRoleList(state),
  bandList: getBandList(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(EditEmployee);
