import React, { useState } from 'react'
import { Row, Col, Container } from "react-bootstrap"
import EachFormSection from 'reusableComponent/formBuilder/EachFormSection';
import FormBasicDetail from 'reusableComponent/formBuilder/formBasicDetails';
// import AnswerForm from "../reusableComponent/formBuilder/AnsForm";

const AddAppraisal = () => {
    const [formDetailshow, setFormDetailshow] = useState(true);
    const [fullFormDetail, setFullFormDetail] = useState([]);
    const [formbasicDetail, setFormBasicDetail] = useState({
        title: '',
        description: ''
    });
    const [savedForm, setSavedForm] = useState(false);
    // const [answerResponse, setAnswerResponse] = React.useState([]);


    return (
        <Row className="page-title">
            <Col sm={12} md={12}>
                <h4 className='common_title'>Add new appraisal form</h4>
            </Col>

            <section className='form_builder main_container'>
                <Row className='d-flex justify-content-center'>
                    <Col md={10} lg={7} sm={12}>
                        <Container className='bg_light my-3 rounded-2'>
                            <FormBasicDetail
                                setFormDetailshow={setFormDetailshow}
                                formDetailshow={formDetailshow}
                                setFormBasicDetail={setFormBasicDetail}
                                setFullFormDetail={setFullFormDetail}
                                formbasicDetail={formbasicDetail}
                            />
                        </Container>

                        <div className='my-3'>
                            <EachFormSection
                                formbasicDetail={formbasicDetail}
                                fullFormDetail={fullFormDetail}
                                setFullFormDetail={setFullFormDetail}
                                setSavedForm={setSavedForm}
                            />
                        </div>


                    </Col>
                </Row>

            </section>
        </Row>
    )
}

export default AddAppraisal;