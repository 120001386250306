export const getAddEditPolicyJson = (data) => {
    const addPolicyForm = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [12, 12, 12],
          fields: [    
            [
              {
                id: 1,
                label: "Policy Title",
                type: "text",
                name: "policyTitle",
                Placeholder: "Title",
                value: data["policyTitle"],
                validationRules: [
                  {
                    required: true,
                    message: `Please enter policy title`,
                  },
                ],
              },
            ],
            [
              {
                id: 2,
                label: "Policy Details",
                type: "editor",
                name: "policyDetails",
                Placeholder: "details",
                value: data["policyDetails"],
              },
            ],
         
            [
              {
                id: 3,
                label: "Cancel",
                type: "button",
                position: "end",
                textColor: "white",
                event: "click",
              },
              {
                id: 4,
                label: "Save",
                type: "button",
                position: "start",
                textColor: "white",
                event: "submit",
              },
            ],
          ],
        },
      ],
    }
  
    return {
      addPolicyForm,
    }
  }
  