import React from 'react';
import { Modal, Table } from 'antd';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { dateInDetail } from 'utils/Date';

const StyledModal = styled(Modal)`
  width: 600px !important;
  
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;
const StyleLocation = styled.div`
  a{
    font-size:12px;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;

function LocationBasedAttendanceList({
  displayModal,
  toggleModal,
  title,
  list,
  reportType
}) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      // width: '30%',
      // align: 'center'
    },
    // {
    //     title: 'Email',
    //     dataIndex: 'EmailId',
    //     key: 'EmailId',
    //     // width: '30%',
    // },
    {
      title: 'Department',
      dataIndex: 'departmentName',
      key: 'departmentName',
      // width: '50%',
      // align: 'center'
    },
    {
      title: 'Check-In Time',
      dataIndex: 'checkinTime',
      key: 'checkinTime',
      // width: '50%',
      align: 'center',
      render: (checkintime) => {
        return checkintime.slice(0, 5);;
      }
    }
  ];

  if (reportType === 'Others') {
    columns.push({
      title: <div>Location</div>,
      dataIndex: 'location',
      key: 'loginLatitude',
      className : 'normal-column',
      align: 'center',
      // width: '50%',
      render: (_, record) => {
        const url1 ='http://maps.google.com/maps/?q=' + record.loginLatitude + "," + record.loginLongitude + ''
        return (
          <div>
            {
              record.loginLatitude !== '' && record.loginLongitude !== ''
              ? (
                <>
                 <StyleLocation className='location'><a href={url1} target='_blank'>{record.loginLatitude}, {record.loginLongitude}</a></StyleLocation>
                </>
              )
              : '-'
            }
          </div>
        )
      }
    });
  } 

  return (
    <>
      <StyledModal
        title={title}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
        destroyOnClose
      >
        <StyledTable
          dataSource={list}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' :  'non-white'}
          rowKey='name'
          pagination={false} 
        />
      </StyledModal>
    </>
  );
}

export default LocationBasedAttendanceList;
