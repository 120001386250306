import React from 'react';
import { Row, Col, Avatar } from 'antd';
import { useHistory } from "react-router-dom";

import styled from '@emotion/styled';
import { map, size, startCase, uniqBy, filter, get } from 'lodash'
import Icon from 'atoms/Icon';

// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getDepartmentUserList,
  getUserList,
  getUserInfo
} from 'redux/selectors';
import { DEVICE } from 'common/Device';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
  @media ${DEVICE.tablet}  {
    margin-top: 20px !important;
  }
`;

const StyledNameContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  .name-title {
    background-color: #f0f2f5;
    padding: 12px;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .view-all {
    text-align: center;
    padding: 10px 5px;
    color: #26A4FF;
    font-weight: bold;
    cursor: pointer;
  }
`;

const StyledContactRow = styled(Row)`
  padding: 5px;
  &.grey {
    background: #F6F6F6;
  }
  .avatar {
    padding-left: 10px;
  }
  .emp-name {
    padding-top: 4px;
  }
  .message {
    text-align: right;
    padding: 3px;
    padding-right: 10px;
    svg {
      vertical-align: bottom;
      cursor: pointer;
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  background: #26A4FF;
`;

function AllEmployees({
  userRoleId,
  userList,
  departmentUserList,
  userInfo
}) {
  let history = useHistory();
  let users = uniqBy((userRoleId == 1 || userRoleId == 6) ? userList : departmentUserList, 'userid');
  users = filter(users, (v) => v.userid !== get(userInfo, 'userid', ''))
  return (
    <>
      <StyledTitle>
        {(userRoleId === 1 || userRoleId === 6) ? 'ALL EMPLOYEES' : 'DEPARTMENT'}
      </StyledTitle>
      <StyledNameContainer>
        <div className='name-title'>Names:</div>
        {
          map(users.slice(0, 8), ({ clientid, userid, fullname, profileurl }, index) => (
            <StyledContactRow key={index} className={index % 2 ? '' : 'grey'}>
              <Col span={6} className='avatar'>
                {profileurl === null || profileurl == "null" || profileurl === undefined || profileurl === "" ?
                  <StyledAvatar>{startCase(fullname.charAt(0))}</StyledAvatar>
                  : <StyledAvatar src={profileurl} />
                }
              </Col>
              <Col span={12} className='emp-name'>{fullname}</Col>
              <Col span={6} className='message'>
                <Icon name='chat' onClick={() => history.push(`/chat/${clientid}/${userid}`)} />
              </Col>
            </StyledContactRow>
          ))
        }
        {
          size(users) > 8 && <div className='view-all pt-1' onClick={() => history.push(`/team`)}>View all</div>
        }
      </StyledNameContainer>
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  userList: getUserList(state),
  departmentUserList: getDepartmentUserList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(AllEmployees);
