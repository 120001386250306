import React, { useState, useEffect, useRef } from 'react'
import Wrapper from './style'
import { Input, Card, Row, Avatar, Spin, Col } from 'antd';
import RoundIcon from 'atoms/RoundIcon';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Typist from 'react-typist';
import styled from 'styled-components';
import COLORS from 'common/Colors';
import { CALL_AI_API } from 'common/API';
import { connect } from 'react-redux';
import { getAIChatHistory, getCurrentAIMessageDetails, getUserInfo } from 'redux/selectors';
import { bindActionCreators } from 'redux';
import { setAIChatHistory, setCurrentAIMessageDetails } from 'redux/actions';
import { Spinner } from 'react-bootstrap';


const { TextArea } = Input;

const StyledAvatar = styled(Avatar)`
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
`;

const cardItems = [
    {
        user: "Give me a attendance report"
    },
    {
        user: "Apply Annual Leave"
    },
    {
        user: "Approve Leave"
    },
    {
        user: "Find this month public leave"
    },
    {
        user: "Give me a absent report"
    },
    {
        user: "Give me a Leave report"
    },
]



function AITymeplus({
    userInfo,
    saveChatHistory,
    chatHistory,
    currentAIMessageDetail,
    saveCurrentAIMessageDetail
}) {
    const iconStyle = { color: "white", fontSize: "18px" }
    const [inputValue, setInputValue] = useState("");
    const [isTypist, setIsTypist] = useState(false);
    const [messageList, setMessageList] = useState(chatHistory)
    const [showMsgBox, setShowMsgBox] = useState(chatHistory?.length !== 0)
    const chatContainerRef = useRef();

    const checkRunStatus = async (msg_id = "", run_id = "") => {
        try {
            const { run_status, message } = await CALL_AI_API("runstatus", "post", {
                run_id: run_id ? run_id : currentAIMessageDetail?.run_id,
                thread_id: userInfo?.threadid,
                message_id: msg_id ? msg_id : currentAIMessageDetail?.message_id
            });
            if (run_status == "completed") {
                const newKeyValue = { assistant: message?.contentValue };
                setMessageList(prevList => prevList?.map((item, index) =>
                    index === prevList.length - 1 ? { ...item, ...newKeyValue } : item
                ));
                return;
            }
            if (run_status === "queued" || run_status === "in_progress") {
                setTimeout(async () => {
                    await checkRunStatus(
                        msg_id ? msg_id : currentAIMessageDetail?.message_id,
                        run_id ? run_id : currentAIMessageDetail?.run_id
                    );
                }, 2000); 
            }
        } catch (error) {
            console.error("[checkRunStatus] --> Error", error);
        }
    };

    const sendMessageToAI = async (value = "") => {
        setIsTypist(true)
        try {
            if (inputValue.trim() !== "" || value !== "") {
                let question = inputValue ? inputValue : value
                setMessageList(prevList => [...prevList, { user: question }]);
                setInputValue("");
                const { message_id, run_id } = await CALL_AI_API("sendmessage", "post", {
                    thread_id: userInfo?.threadid,
                    content: inputValue
                });
                saveCurrentAIMessageDetail({ message_id: message_id, run_id: run_id })
                checkRunStatus(message_id, run_id);
            }
        } catch (error) {
            console.error("[sendMessageToAI] Error sending message to AI:", error);
        }
    };

    const handleCardClick = async (value) => {
        await sendMessageToAI(value)
    }

    const getThreadHistory = async () => {
        try {
            if (chatHistory?.length === 0) {
                const { all_chat_history } = await CALL_AI_API("allchathistory", "post", {
                    thread_id: userInfo.threadid,
                });
                const data = all_chat_history.reverse()
                const result = [];

                for (let i = 0; i < data.length; i += 2) {
                    const userMessage = data[i];
                    const assistantMessage = data[i + 1];
                    const userContent = userMessage?.content;
                    const assistantContent = assistantMessage?.content;
                    result.push({ user: userContent, assistant: assistantContent });
                }
                setMessageList(result)
                saveChatHistory(result)
                setShowMsgBox(true)
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;

            }
        } catch (error) {
            console.error("[getThreadHistory] --> Error", error);
        }
    }

    const antdHeader = document.querySelector('.ant-layout-header');
    useEffect(() => {
        if (antdHeader) {
            const headerHeight = window.innerHeight - document.querySelector('.ant-layout-header').getBoundingClientRect().height - 100
            document.documentElement.style.setProperty('--antd-header-height', `${headerHeight}px`);
        }
    }, [antdHeader]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
        saveChatHistory(messageList)
    }, [messageList]);

    useEffect(() => {
        getThreadHistory()
        if (Object.keys(currentAIMessageDetail).length !== 0 && chatHistory?.length > 0) {
            const lastMessage = chatHistory[chatHistory?.length - 1]
            if (!lastMessage?.hasOwnProperty('assistant')) {
                checkRunStatus(currentAIMessageDetail?.message_id, currentAIMessageDetail?.run_id)
            }
        }
    }, [])

    return (
        <Wrapper >
            <h6>Tymeplus AI 1.0</h6>

            {!showMsgBox && (
                <div className='chat_bot'>
                    <Spin />
                    <h6>Fetching...</h6>
                </div>
            )}
            {showMsgBox && (
                <>
                    <div className='content-container' ref={chatContainerRef}>
                        {messageList.length === 0 && (
                            <div className='content'>
                                <h4>How can I help you today?</h4>
                                <div className='d-flex justify-content-center flex-wrap gap-3 items'>
                                    {cardItems?.map((item, index) => (
                                        <Card key={index} className='cards' onClick={() => { handleCardClick(item.user) }}>
                                            <p>{item.user}</p>
                                        </Card>
                                    ))}
                                </div>
                            </div>
                        )}
                        {messageList.length > 0 && (
                            <div className='conversation'>
                                {messageList?.map((item, index) => (
                                    <div key={index} className='mt-1'>
                                        <Row gutter={16}>
                                            <Col sm={4} xs={4} md={2} lg={1}>
                                                <Avatar>U</Avatar>
                                            </Col>
                                            <Col sm={18} xs={18} md={20} lg={20}>
                                                <strong>You</strong>
                                                <p>{item?.user}</p>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col sm={4} xs={4} md={2} lg={1}>
                                                <StyledAvatar>T</StyledAvatar>
                                            </Col>
                                            <Col sm={18} xs={18} md={20} lg={20}>
                                                <strong>Replied by Tymeplus AI </strong>
                                                {item?.assistant && isTypist && index === messageList?.length - 1 && (
                                                    <Typist className='typist' avgTypingDelay={20} cursor={{ show: false }}>
                                                        {item.assistant}
                                                    </Typist>
                                                )}
                                                {!item?.hasOwnProperty('assistant') && index === messageList?.length - 1 && (
                                                    <div className='typist'>
                                                        <Spinner animation="grow" variant="success" size='sm' />
                                                    </div>
                                                )}
                                                {item?.assistant && !isTypist && (
                                                    <p className='typist'>
                                                        {item.assistant}
                                                    </p>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <Row className='text_container'>
                        <div className='text_area'>
                            <div className='input_area '>
                                <TextArea
                                    placeholder="Message Tymeplus AI "
                                    autoSize={{ minRows: 1, maxRows: 3 }}
                                    className='input_box'
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onPressEnter={(e) => {
                                        if (e.shiftKey) {
                                          return;
                                        }
                                        e.preventDefault();
                                        sendMessageToAI();
                                      }}
                                />
                                <RoundIcon onClick={sendMessageToAI}> <ArrowUpwardIcon style={iconStyle} /> </RoundIcon>
                            </div>
                        </div>
                    </Row>
                </>
            )}
        </Wrapper>
    )
}

const mapStateToProps = (state) => ({
    userInfo: getUserInfo(state),
    chatHistory: getAIChatHistory(state),
    currentAIMessageDetail: getCurrentAIMessageDetails(state)
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveChatHistory: setAIChatHistory,
            saveCurrentAIMessageDetail: setCurrentAIMessageDetails
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(AITymeplus);
