import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import LeaveDonutChart from "./LeaveDonutChart";
import styled from "@emotion/styled";
import { LEAVE_ICON,  } from "common/Constants";
import { CALL_API } from "common/API";
import { dateInDetail, getKenyanDateTime } from "utils/Date";
import {
  getUserRoleId,
  getClientid,
  getLeaveCategoryList,
} from "redux/selectors";
import { connect } from "react-redux";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

function UserLeaveReport({ ClientId, leaveCategoryList }) {
  const [loading, setLoading] = useState(false);
  const [leaveData, setLeaveData] = useState([]);

  const getCheckinData = async () => {
    setLoading(true);
    const response = await CALL_API(
      `employee-leave-details/${ClientId}`,
      "post",
      {
        year: dateInDetail(getKenyanDateTime()).year,
      }
    );

    setLeaveData(response);
    setLoading(false);
  };

  useEffect(() => {
    getCheckinData();
  }, [ClientId]);

  return (
    <>
      <StyledTitle>LEAVE REPORT</StyledTitle>
      <Row gutter={16}>
        {leaveCategoryList.map((data) => (
          <Col
            md={24}
            sm={24}
            xs={24}
            xl={12}
            key={data.leave_category_short_name}
          >
            <Spin spinning={loading}>
              <LeaveDonutChart
                iconName={LEAVE_ICON[7]}
                title={data.leave_category_name.toUpperCase()}
                data={
                  leaveData?.find(
                    (item) => item[data?.leave_category_short_name]
                  )?.[data?.leave_category_short_name] ?? []
                }
              />
            </Spin>
          </Col>
        ))}
      </Row>
    </>
  );
}
const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
});

export default connect(mapStateToProps, null)(UserLeaveReport);
