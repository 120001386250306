import React, { useEffect, useState } from "react";
import { Column, Bar } from "@ant-design/plots";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Spin } from "antd";

// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getClientid,
  getLeaveCategoryList,
  getIsCheckedIn
} from "redux/selectors";
import DepartmentAttendanceReport from "./DepartmentAttendanceReport";
import AttendanceStaffList from "./AttendanceStaffList";
import { CALL_API } from "common/API";
import { concat, includes, isNaN, map, max, size, filter } from "lodash";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import { bottomNavigationActionClasses } from "@mui/material";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
  padding-bottom: 35px;
  padding-top: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function AttendanceBarChart({
  userRoleId,
  selectedDepartmentId,
  ClientId,
  leaveCategoryList,
  isCheckedIn
}) {
  const [
    displayDepartmentAttendanceReportModal,
    toggleDepartmentAttendanceReportModal,
  ] = useState(false);
  const [displayAttendanceStaffModal, toggleDisplayAttendanceStaffModal] =
    useState(false);
  const [reportType, setReportType] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportList, setReportList] = useState([]);
  const [reportColor, setReportColor] = useState("");
  const [attendanceReportTitle, setAttendanceReportTitle] = useState("");
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [absentList, setAbsentList] = useState([]);
  const [lateListData, setLateListData] = useState([]);
  const [onTimeList, setOnTimeList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [reportData, setReportData] = useState([
    {
      name: "onTime",
      type: "Present",
      numbers: 0,
      list: [],
    },
    {
      name: "Absent",
      type: "Absent",
      numbers: 0,
      list: [],
    },
    // {
    //   name:'Post cut Off',
    //   type: 'Post cut Off',
    //   numbers: 0,
    //   list: [],
    // },
    {
      name: "Late",
      type: "Present",
      numbers: 0,
      list: [],
    },
    {
      name: "Leave",
      type: "leave",
      numbers: 0,
      list: [],
    },
    // {
    //   name:'Others',
    //   type: 'Present',
    //   numbers: 0,
    //   list: [],
    // }
  ]);
  const config = {
    isStack: true,
    dodgePadding: 0.2,
    data: reportData,
    height: 400,
    xField: "numbers",
    yField: "type",
    seriesField: "name",
    intervalPadding: 10,
    legend: false,
    color: ({ name }) => {
      if (name === "Absent") {
        return "orange";
      } else if (name === "onTime") {
        return "#0565f5";
      } else if (name === "Late") {
        return "#5d9af5";
      } else if (name === "Leave") {
        return "#bd34eb";
      } else {
        return "#f5e0ff";
      }
    },
    theme: {
      maxColumnWidth: 50,
      minColumnWidth: 50,
    },
    // legend:{
    //   layout: 'vertical',
    //   position: 'right'
    // },
    label: {
      position: "middle",
      content: "",
    },
    columnStyle: {
      // stroke: COLORS.BLACK,
      // lineWidth: 1,
      cursor: "pointer",
    },
    xAxis: {
      max: isNaN(max(map(reportData, "numbers")))
        ? 100
        : max(map(reportData, "numbers")) + 15,
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  const annualLeaveCategoryId = leaveCategoryList.find(
    (item) => item.leave_category_short_name === "annualleave"
  );
  const sickLeaveCategoryId = leaveCategoryList.find(
    (item) => item.leave_category_short_name === "sickleave"
  );

  useEffect(() => {
    if (
      (userRoleId !== 1 && selectedDepartmentId !== 0) ||
      userRoleId === 1 ||
      userRoleId === 6
    ) {
      getReportData();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId !== 6) {
      getReportData();
    }
  }, [selectedDepartmentId]);

  const getReportData = async () => {
    toggleReportLoader(true);
    if (ClientId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(100);
      const { todayAttendenceDetails } = await CALL_API(
        `attendance-service/${ClientId}`,
        "post",
        {
          departmentId:
            userRoleId != 1 && userRoleId != 6 ? selectedDepartmentId : [""],
        }
      );
      let absentList = [];
      let ontimeList = [];
      let lateList = [];
      let leaveList = [];
      todayAttendenceDetails.forEach((value) => {
        absentList = concat(absentList, value.absent);
        ontimeList = concat(ontimeList, value.onTime);
        lateList = concat(lateList, value.late);
        leaveList = concat(leaveList, value.leaveCount);
      });

      let dupeReportData = [...reportData];
      // let otherList = []
      // let newList = []
      // let lateListFilter = []
      // // let otherList1 =filter(leaves, (e) => e.locationName === 'Other' )
      // // console.log("list other", otherList1)
      // ontimeList.map((list) => {
      //   // console.log("list", list.locationName)
      //   if(list.locationName === 'Other'){
      //     otherList.push(list)
      //   }
      //   else{
      //     newList.push(list)
      //   }
      // })
      // lateList.map((list) => {
      //   // console.log("list", list.locationName)
      //   if(list.locationName === 'Other'){
      //     otherList.push(list)
      //   }
      //   else{
      //     lateListFilter.push(list)
      //   }
      // })

      // console.log("other",otherList)
      // onTime.map()
      dupeReportData[0].numbers = size(ontimeList);
      dupeReportData[1].numbers = size(absentList);
      dupeReportData[2].numbers = size(lateList);
      dupeReportData[3].numbers = size(leaveList);

      // dupeReportData[3].numbers = size(leave)
      dupeReportData[0].list = ontimeList;
      dupeReportData[1].list = absentList;
      dupeReportData[2].list = lateList;
      dupeReportData[3].list = leaveList
      // const leaveList = leaves
      // let sickCount = 0;
      // let sickLeaveList = [];
      // let annualCount = 0;
      // let annualLeaveList = [];
      // let otherCount = 0;
      // let otherLeaveList = [];
      // leaveList.map((list) => {
      //   if (list.leave_category_id === sickLeaveCategoryId?.leave_category_id) {
      //     sickCount++;
      //     sickLeaveList.push(list);
      //   } else if (
      //     list.leave_category_id === annualLeaveCategoryId?.leave_category_id
      //   ) {
      //     annualCount++;
      //     annualLeaveList.push(list);
      //   } else {
      //     otherCount++;
      //     otherLeaveList.push(list);
      //   }
      // });
      // dupeReportData[5].numbers = otherCount;
      // dupeReportData[5].list = otherLeaveList;
      // dupeReportData[4].numbers = sickCount;
      // dupeReportData[4].list = sickLeaveList;
      // dupeReportData[3].numbers = annualCount;
      // dupeReportData[3].list = annualLeaveList;
      setReportData(dupeReportData);
      toggleReportLoader(false);
      // dupeReportData[6].numbers = size(otherList)
      // dupeReportData[6].list = otherList
      // const groupedData = data.reduce((result, item) => {
      //   (result[item.name] = result[item.name] || []).push(item);
      //   return result;
      // }, {});

      // const groupByAbsentDeptId = groupedData?.undefined[0][selectedDepartmentId]?.absent?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByPresentDeptId = groupedData?.undefined[0][selectedDepartmentId]?.late?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByOnTimeDeptId = groupedData?.undefined[0][selectedDepartmentId]?.onTime?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByLateDeptId = groupedData?.undefined[0][selectedDepartmentId]?.leaves?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByAbsentDeptId = absentList.map((e) => e.departmentId == hrSelectedDept)
      // console.log("---->", groupByAbsentDeptId)

      setAbsentList(absentList);
      setLateListData(lateList);
      setOnTimeList(ontimeList);
      setLeaveList(leaveList);
    }
  }; 

  useEffect(()=>{
    getReportData();
  },[isCheckedIn]);

  return (
    <>
      <StyledTitle>
        ATTENDANCE
      </StyledTitle>
      <StyledChartContainer>
        <div className="name-title">Today's attendance view:</div>
        <Spin spinning={displayReportLoader}>
          <Bar
            {...config}
            onReady={(plot) => {
              plot.on("plot:click", (evt) => {
                if (evt.data && evt.data.data !== null) {
                  const {
                    data: {
                      data: { type, list, name },
                      color,
                    },
                  } = evt;
                  setReportList(list);
                  setReportType(type);
                  setReportName(name);
                  if (userRoleId !== "1") {
                    // setReportColor(color);
                    // toggleDepartmentAttendanceReportModal(true);
                    if (type === "Absent") {
                      setAttendanceReportTitle(`${type} staff`);
                    } else {
                      setAttendanceReportTitle(name === "Leave" ? `${type}` : `${type}- ${name}`);
                    }
                    toggleDisplayAttendanceStaffModal(true);
                  } 
                  // else if (includes(["2", "3"], userRoleId)) {
                  //   if (type === "Absent") {
                  //     setAttendanceReportTitle(`${type} staff`);
                  //   } else {
                  //     setAttendanceReportTitle(`${type}- ${name}`);
                  //   }
                  //   toggleDisplayAttendanceStaffModal(true);
                  // }
                }
              });
            }}
          />
        </Spin>
      </StyledChartContainer>
      <DepartmentAttendanceReport
        displayModal={displayDepartmentAttendanceReportModal}
        toggleModal={() => toggleDepartmentAttendanceReportModal(false)}
        reportType={reportType}
        reportColor={reportColor}
        list={reportList}
        name={reportName}
        lateListData={lateListData}
        onTimeList={onTimeList}
        leaveList={leaveList}
        absentList={absentList}
      />
      <AttendanceStaffList
        displayModal={displayAttendanceStaffModal}
        toggleModal={() => toggleDisplayAttendanceStaffModal(false)}
        title={attendanceReportTitle}
        reportType={reportType}
        list={reportList}
        name={reportName}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  isCheckedIn: getIsCheckedIn(state),
});

export default connect(mapStateToProps, null)(AttendanceBarChart);
