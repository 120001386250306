import styled from "@emotion/styled";
import COLORS from "common/Colors";
import React from "react";
import IconButton from '@mui/material/IconButton';


const StyledCircle = styled(IconButton)`
  background: ${COLORS.PRIMARY};
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background: ${COLORS.PRIMARY}; 
  }
`;

const RoundIcon = ({ children, onClick }) => {
  return (
    <StyledCircle className="styled-circle" onClick={onClick}>
      {children}
    </StyledCircle>
  );
};

export default RoundIcon;