import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Modal,
  message,
  Button,
  Switch,
  Checkbox,
} from "antd";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import {
  getUserRoleId,
  getClientid,
  getAllDepartmentList,
  getLocations,
  getMenuList,
} from "redux/selectors";
import { connect } from "react-redux";
import EditBreak from "organisms/EditBreak";
import Confirm from "../../src/assets/Confirm.svg";
import { STATUS_CODE } from "common/Constants";
import StepsProgress from "organisms/StepsProgress";
import SelectTable from "molecules/SelectTable";
import BasicInformation from "molecules/BasicInformation";
import { setBreakTypesList } from "redux/actions";
import { bindActionCreators } from "redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "organisms/FormModal";
import InReview from "molecules/InReview";

const StyledButton = styled(Button)`
  width: 150px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;
const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 24px 0px 30px 0px;
  }
  .modal-body {
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x {
    color: #151515;
  }
  .modal-body-content {
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    padding: 10px;
    height: 0px !important;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const Break = ({
  userRoleId,
  ClientId,
  departmentList,
  setBreakTypes,
  locationList,
  menuList,
}) => {
  const [displayBreakLoader, setDisplayBreakLoader] = useState(false);
  const [breakList, setBreakList] = useState([]);
  const [displayAddBreakModal, toggleAddBreakModal] = useState(false);
  const [displayEditBreakModal, toggleEditBreakModal] = useState(false);
  const [breakDetails, setBreakDetails] = useState({});
  const [deactivateBreak, toggledeactivateBreak] = useState(false);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [confirmdeactivateBreak, toggleconfirmdeactivateBreak] =
    useState(false);
  const [breakid, setBreakid] = useState();

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const getBreakList = async () => {
    try {
      setDisplayBreakLoader(true);
      const response = await CALL_API(`owner-break/${ClientId}`, "get", {});
      setBreakList(response);
      setDisplayBreakLoader(false);
    } catch (error) {
      console.log("[getDepartmentList], Error-->", error);
    }
  };

  const ViewBreak = (BreakObject, isViewOnly = false) => {
    setBreakid(BreakObject.breakId);
    toggleEditBreakModal(true);
    setBreakDetails({
      ...BreakObject,
      isViewOnly,
    });
  };
  const deletedBreak = () => {
    toggledeactivateBreak(true);
  };
  const deleteBreak = async (Break_id) => {
    togglebtnLoader(true);
    const { code } = await CALL_API(
      `owner-break/${ClientId}/${Break_id}`,
      "delete",
      {
        statusid: "1",
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      togglebtnLoader(false);
      toggledeactivateBreak(false);
      toggleconfirmdeactivateBreak(true);
      toggleEditBreakModal(false);
      getBreakList();
    }
  };

  useEffect(() => {
    getBreakList();
  }, [setBreakList]);

  const columns = [
    {
      title: "Break Name",
      dataIndex: "breakName",
      key: "breakName",
      width: "15%",
    },
    {
      title: "No of Department",
      dataIndex: "no_of_department",
      key: "no_of_department",
      width: "15%",
    },
    {
      title: "No of Location",
      dataIndex: "no_of_location",
      key: "no_of_location",
      width: "15%",
    },
  ];

  // -----add break----
  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Department" },
    { id: 3, title: "Location" },
    { id: 4, title: "In Review" },
  ];

  const [step, setStep] = useState(0);
  const [selectDepartmentId, setSelectDepartmentId] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [create, setCreate] = useState(false);
  const [values, setValues] = useState("");
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [loading, setLoading] = useState(false)
  const [selectAllDepartmentChecked, setSelectAllDepartmentChecked] =
    useState(false);

  useEffect(() => {
    setSelectAllChecked(selectedLocationId.length === locationList.length);
    setSelectAllDepartmentChecked(
      selectDepartmentId.length === departmentList.length
    );
  }, [selectedLocationId, locationList, selectDepartmentId, departmentList]);

  const departmentcolumns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id, record) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllDepartmentChecked ||
              selectDepartmentId.includes(department_id)
            }
            onChange={(checked) => getDepartmentId(department_id, checked)}
          />
        );
      },
    },
  ];

  const getDepartmentId = (department_id, checked) => {
    const newDepartmentId = checked
      ? [...selectDepartmentId, department_id]
      : selectDepartmentId.filter((item) => item !== department_id);
    setSelectDepartmentId(newDepartmentId);
  };

  const handleSelectAllDepartment = (e) => {
    const checked = e.target.checked;
    setSelectAllDepartmentChecked(checked);
    const allDepartmentIds = departmentList.map((item) => item.department_id);
    setSelectDepartmentId(checked ? allDepartmentIds : []);
  };

  const locatincolumns = [
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllChecked || selectedLocationId.includes(location_id)
            }
            onChange={(checked) => getLocationId(location_id, checked)}
          />
        );
      },
    },
  ];

  const getLocationId = (location_id, checked) => {
    const newLocationId = checked
      ? [...selectedLocationId, location_id]
      : selectedLocationId.filter((item) => item !== location_id);
    setSelectedLocationId(newLocationId);
  };

  const handleSelectAllLocation = (e) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    const allLocationIds = locationList.map((item) => item.location_id);
    setSelectedLocationId(checked ? allLocationIds : []);
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, breakList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        setBreakTypes(breakList);
      }
    }
  };

  const onFinish = async () => {
    setLoading(true)
    const { name } = values;
    const { code } = await CALL_API(`owner-break/${ClientId}`, "post", {
      locationid: selectedLocationId,
      breakname: name,
      departmentid: selectDepartmentId,
    });
    if (code === STATUS_CODE.SUCCESS) {
      fetchMasterData(true);
      message.success("Successfully Added");
      setValues({})
      setSelectDepartmentId([])
      setSelectedLocationId([])
      setLoading(false)
      getBreakList();
      setCreate(false);
      setStep(0);
      toggleAddBreakModal(false);
    }
  };

  useEffect(() => {
    if (create) {
      onFinish();
    }
  }, [create]);

  const headerstyle = { fontSize: "20px", fontWeight: "bold" };

  return (
    <>
      {!displayAddBreakModal && !displayEditBreakModal && (
        <>
          <Row className="page-title">
            <Col span={12} style={headerstyle}>
              List of Breaks
            </Col>
            {Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "addbreak" && subItem?.permission.create
                )
            ) && (
                <Col span={12} className="align-right">
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                    onClick={() => toggleAddBreakModal(true)}
                  >
                    + Add Breaks
                  </StyledButton>
                </Col>
              )}
          </Row>

          <Spin spinning={displayBreakLoader}>
            <StyledTable
              dataSource={breakList}
              columns={columns}
              rowKey="break_id"
              onRow={(record) => {
                return {
                  onClick: () => {
                    toggleEditBreakModal(true);
                    ViewBreak(record);
                  },
                };
              }}
              pagination={false}
            />
          </Spin>
        </>
      )}

      {displayAddBreakModal && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => toggleAddBreakModal(false)}
          >
            <b className="">Break</b> <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className="text-info pb-0 pt-1">Add Break</b>
          <StepsProgress stepdata={stepdata} title="Add Break" step={step} />
        </>
      )}
      {displayAddBreakModal && (
        <div className="styled-card">
          {displayAddBreakModal && step === 0 && (
            <BasicInformation
              step={step}
              setStep={setStep}
              setValues={setValues}
              title={"break"}
              values={values}
            />
          )}

          {displayAddBreakModal && step === 1 && (
            <>
              {departmentList?.length > 0 && (
                <Checkbox
                  className="mt-3"
                  checked={selectAllDepartmentChecked}
                  onChange={handleSelectAllDepartment}
                >
                  Select All
                </Checkbox>
              )}

              <SelectTable
                step={step}
                setStep={setStep}
                columns={departmentcolumns}
                tabledata={departmentList}
                setOpenModal={() => setOpenDepartmentModal(true)}
                Name="Add Department"
              />
              <FormModal
                isModalVisible={openDepartmentModal}
                closeModal={() => setOpenDepartmentModal(false)}
                Name="Department"
              />
            </>
          )}

          {displayAddBreakModal && step === 2 && (
            <>
              {locationList?.length > 0 && (
                <Checkbox
                  className="mt-3"
                  checked={selectAllChecked}
                  onChange={handleSelectAllLocation}
                >
                  Select All
                </Checkbox>
              )}

              <SelectTable
                step={step}
                setStep={setStep}
                columns={locatincolumns}
                tabledata={locationList}
                setOpenModal={() => setOpenLocationModal(true)}
                Name="Add Location"
              />
              <FormModal
                isModalVisible={openLocationModal}
                closeModal={() => setOpenLocationModal(false)}
                Name="Location"
              />
            </>
          )}
          {step === 3 && (
            <InReview
              step={step}
              setStep={setStep}
              BasicInformation={values}
              department={selectDepartmentId}
              location={selectedLocationId}
              create={onFinish}
              loading={loading}
            />
          )}
        </div>
      )}
      {displayEditBreakModal && (
        <EditBreak
          isModalVisible={displayEditBreakModal}
          closeModal={() => toggleEditBreakModal(false)}
          getBreakList={getBreakList}
          BreakDetails={breakDetails}
          deleteBreak={deletedBreak}
          viewOnly={
            Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "addbreak" && subItem?.permission.edit
                )
            )
              ? false
              : true
          }
        />
      )}

      <StyleddeactivateModal
        title="Are you sure you want to deactivate this Break?"
        visible={deactivateBreak}
        width={500}
        onCancel={() => toggledeactivateBreak(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head">
          <Col>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => toggledeactivateBreak(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col style={{ marginLeft: "5%" }}>
            <StyledButton
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={() => deleteBreak(breakid)}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>
      <StyledModal
        visible={confirmdeactivateBreak}
        width={500}
        onCancel={() => toggleconfirmdeactivateBreak(false)}
        footer={[]}
        centered
      >
        <Row className="modal-body">
          <Col>
            <img src={Confirm} height={45} width={45} />
          </Col>
        </Row>
        <Row className="modal-body-content">
          <Col>
            <p>This Break has been deactivated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};
const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  departmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  menuList: getMenuList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBreakTypes: setBreakTypesList,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Break);
