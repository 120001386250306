
import React from 'react'
import TuneIcon from '@mui/icons-material/Tune';
import { Select } from "antd";

const SearchInput = ({ showFilterContent, placeholder, btnSearchStyle }) => {
    return (
        <>
            <TuneIcon /> Filter by

            <Select
                showSearch
                placeholder={placeholder}
                style={btnSearchStyle}
                className='border-0 mx-2'
            // name=""
            // optionFilterProp=""
            // filterOption={(input, option) =>
            //   option.leave_category_name
            //     .toLowerCase()
            //     .includes(input.toLowerCase())
            // }
            // filterSort={(optionA, optionB) => {
            //   optionA.leave_category_name
            //     .toLowerCase()
            //     .localeCompare(optionB.leave_category_name.toLowerCase());
            // }}
            // options={leaveCategoryList}
            // fieldNames={{
            //   label: "leave_category_name",
            //   value: "leave_category_id",
            // }}
            // disabled={isViewOnly}
            // onChange={()=>validateDateRange()}
            // getPopupContainer={() => containerRef.current}
            />
        </>
    )
}

export default SearchInput
