import React from "react";
import styled from "styled-components";
import UITable from "reusableComponent/UITable/UITable";
import Icon from "atoms/Icon";
import { Link } from 'react-router-dom';

const DashboardWrap = styled.div`
  img {
    object-fit: cover;
    width: 100%;
  }
  .styled-col {
    background-color: #ffffff;
    /* background-color: yellow; */
    border-radius: 15px;
    border: 1px solid transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .styled-add-button {
    background: transparent;
    border: 1px solid #2d53da;
    color: #2d53da;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  .styled-icon {
    width: 40px !important;
    height: 40px !important;
  }
`;

const UICard = ({
  tabledata,
  tablecolumns,
  name,
  total,
  iconsname,
  btnicon,
  path
}) => {


  return (
    <DashboardWrap>
      <div className="styled-col p-3">
        <div className="d-flex align-items-center justify-content-between">
          <b>{name}</b>
          {name !== "Shift" && (
            <Link to={path} className="mt-1 p-1 styled-add-button">
            <Icon name={btnicon} /> Add
          </Link>
          )}
        </div>
        <h4 className="text-center">
          {iconsname && <Icon className="styled-icon" name={iconsname} />}{" "}
          {total}
        </h4>
        {name !== "Shift" && <hr className="mx-2"></hr>}
        <UITable data={tabledata} columns={tablecolumns} name={name}/>
      </div>
    </DashboardWrap>
  );
};
export default UICard;
