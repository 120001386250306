import React, { useState, useEffect } from "react";
import { Form, message, Switch, Checkbox, Row, Col } from "antd";
import { connect } from "react-redux";
import { setBreakTypesList, setLeaveCategoryList } from "redux/actions";
import {
  getLocations,
  getClientid,
  getAllDepartmentList,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import EditSelectTable from "molecules/EditSelectTable";
import TabsList from "molecules/TabsList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "organisms/FormModal";
import { getEditBandJson } from "./Editjson";
import moment from "moment";

const Editband = ({
  isModalVisible,
  closeModal,
  ClientId,
  bandDetails,
  departmentList,
  fetchData,
}) => {
  const [showLoader, toggleLoader] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [viewOnly, setViewOnly] = useState(true);
  const [submitdata, setSubmitData] = useState(false);
  const { EditBandForm } = getEditBandJson(data);
  const [step, setStep] = useState(0);
  const [selectDepartmentId, setSelectDepartmentId] = useState([]);
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [selectAllDepartmentsChecked, setSelectAllDepartmentsChecked] =
    useState(false);
  useEffect(() => {
    form.setFieldsValue({
      bandtype: bandDetails.bandname,
      salaryFrom: bandDetails.salaryfrom,
      salaryTo: bandDetails.salaryto,
    });
    setSelectDepartmentId(bandDetails.departmentId);
    setData({
      bandname: bandDetails.bandName,
      salaryfrom: bandDetails.salaryFrom,
      salaryto: bandDetails.salaryTo,
      experienceto: moment(bandDetails.experienceTo),
      experiencefrom: moment(bandDetails.experienceFrom),
      bandid: bandDetails.bandId,
    });
  }, [bandDetails]);

  const handleFormChange = (e) => {
    setSubmitData(true);
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmitForm = (e) => {
    setStep(1);
  };

  const departmentcolumns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id, record) => {
        const check = selectDepartmentId?.includes(department_id)
          ? true
          : false;
        return (
          <Switch
            checked={check}
            size="small"
            onChange={(checked) => getDepartmentId(department_id, checked)}
          />
        );
      },
    },
  ];
  const onFinish = async () => {
    toggleLoader(true);
    const { bandname, experiencefrom, experienceto, salaryfrom, salaryto } =
      data;
    const { code } = await CALL_API(
      `band/${ClientId}/${data.bandid}`,
      "patch",
      {
        bandname: bandname,
        departmentid: selectDepartmentId,
        salaryfrom: salaryfrom,
        salaryto: salaryto,
        experiencefrom: experiencefrom,
        experienceto: experienceto,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      toggleLoader(false);
      message.success("Successfully Updated");
      fetchData();
    }
  };
  useEffect(() => {
    if (submitdata) {
      onFinish();
    }
  }, [selectDepartmentId, data]);

  const getDepartmentId = (department_id, checked) => {
    const newDepartmentId = checked
      ? [...selectDepartmentId, department_id]
      : selectDepartmentId.filter((item) => item !== department_id);
    setSelectDepartmentId(newDepartmentId);
    setSubmitData(true);
  };
  const handleSelectAllDepartmentsChange = (e) => {
    const checked = e.target.checked;
    setSelectAllDepartmentsChecked(checked);
    const allDepartmentIds = departmentList.map((item) => item.department_id);
    setSelectDepartmentId(checked ? allDepartmentIds : []);
    setSubmitData(true);
  };
  const items = [
    {
      id: 1,
      label: "Basic",
      component: (
        <>
          {!viewOnly ? (
            <>
              <div className="styled-card">
                <Row className="mb-1">
                  <Col>
                    <b>Band Name</b> : {data.bandname}
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <b>Salary From</b> : {data.salaryfrom}
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <b>salary To</b> : {data.salaryto}
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <b>Experience From</b> : {data.experiencefrom}
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <b>Experience To</b> : {data.experienceto}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="styled-card">
              <GridBuilder
                formStructureJson={EditBandForm}
                handleChange={handleFormChange}
                formSubmit={handleSubmitForm}
                initialValues={data}
              />
            </div>
          )}
        </>
      ),
    },
    {
      id: 2,
      label: "Department",
      component: (
        <>
          {viewOnly && (
            <div className="mb-3 d-flex justify-content-between">
              {departmentList?.length > 0 && (
                <Checkbox
                  className=""
                  checked={selectAllDepartmentsChecked}
                  onChange={handleSelectAllDepartmentsChange}
                >
                  Select All
                </Checkbox>
              )}
            </div>
          )}
          <EditSelectTable
            columns={departmentcolumns}
            tabledata={departmentList}
            setOpenModal={() => setOpenDepartmentModal(true)}
            Name="Add Department"
          />
          <FormModal
            isModalVisible={openDepartmentModal}
            closeModal={() => setOpenDepartmentModal(false)}
            Name="Department"
          />
        </>
      ),
    },
  ];

  return (
    <>
      {isModalVisible && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => {
              closeModal(false);
            }}
          >
            <b className="">Band</b> <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className="text-info pb-0 pt-1">{data.bandname}</b>
          <TabsList items={items} form={form} />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  locationList: getLocations(state),
  departmentList: getAllDepartmentList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBreakTypes: setBreakTypesList,
      saveLeaveCategoryList: setLeaveCategoryList,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps, null)(Editband);
