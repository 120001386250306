import { Row, Col } from "react-bootstrap";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Button, Spin, Table } from "antd";
import { Link } from "react-router-dom";
import UISearch from "../reusableComponent/UISearch/SearchInput";
import { message } from "antd";
import { CALL_API } from "common/API";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
    border: none;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 5px 10px;
    height: 0px !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #f4f4f4;
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;

const CurrentAppraisal = ({
  appraisal,
  displayLocationLoader,
  setPageView,
  fetchDataBYId,
  setAppraisal,
  myappraisal,
  fetchResponse,
  myappraisalData,
  setSelectedItem,
}) => {
  const btnStyle = { borderRadius: "5px", margin: "3px 0" };
  const btnSearch = {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    border: "none",
    margin: "0 5px",
    minWidth: "60%",
  };

  const deleteAppraisal = async (id, index) => {
    let obj = {
      deleteStatus: true,
    };
    const { code } = await CALL_API(`appraisalform/${id}`, "patch", obj);

    if (code === "SUCCESS") {
      const updatedData = [...appraisal];
      updatedData.splice(index, 1);
      setAppraisal(updatedData);
      message.success("Deleted successfully");
    }
  };

  const appraisalcolumns = [
    {
      title: "Form Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
      onCell: (record) => ({
        onClick: () => {
          setPageView("appraisalView");
          fetchDataBYId(record.appraisalFormId);
        },
      }),
      className: "cursor-pointer",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "left",
      render: (location_id, record, index) => {
        return (
          <>
            <Button
              onClick={() => {
                setPageView("response");
                fetchResponse(record.appraisalFormId);
                fetchDataBYId(record.appraisalFormId);
              }}
              type="link"
              className="fw-bold"
            >
              View response
            </Button>
            <Button
              onClick={() => deleteAppraisal(record.appraisalFormId, index)}
              type="link"
              className="fw-bold"
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  const findAppraisalName = (appraisalFormId) => {
    const data = appraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return data ? data.title : "N/A";
  };

  const findAppraisal = (appraisalFormId) => {
    const data = appraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    setSelectedItem(data);
  };

  const myappraisalcolumns = [
    {
      title: "Title",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      className: "cursor-pointer",
      render: (appraisalFormId) => findAppraisalName(appraisalFormId),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      className: "cursor-pointer",
      render: (record) => {
        return <p>In complete</p>;
      },
    },
    {
      title: "Due date",
      dataIndex: "appraisalTo",
      key: "appraisalTo",
      width: "10%",
      className: "cursor-pointer",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "left",
      render: (location_id, record, index) => {
        return (
          <>
            <Button
              onClick={() => {
                console.log('record', record)
                setPageView("Attend");
                findAppraisal(record?.appraisalFormId);
              }}
              type="link"
              className="fw-bold"
            >
              Attend
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6}>
          <UISearch
            showFilterContent={true}
            placeholder="Search"
            btnSearchStyle={btnSearch}
          />
        </Col>
        {!myappraisal && (
          <Col sm={12} md={6} lg={6}>
            <div className="text-end">
              <Link to="/add-appraisal">
                <Button style={btnStyle} type="primary">
                  + Add new form
                </Button>
              </Link>
            </div>
          </Col>
        )}
      </Row>
      {myappraisal ? (
        <StyledTable
          className="mb-5"
          dataSource={myappraisalData}
          columns={myappraisalcolumns}
          rowKey="department_id"
          pagination={false}
        />
      ) : (
        <Spin spinning={displayLocationLoader}>
          <StyledTable
            className="mb-5"
            dataSource={appraisal}
            columns={appraisalcolumns}
            rowKey="department_id"
            pagination={false}
          />
        </Spin>
      )}
    </div>
  );
};

export default CurrentAppraisal;
