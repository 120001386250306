import React from 'react';
import { Table as AntTable } from 'antd';
import styled from "@emotion/styled";
import COLORS from 'common/Colors';

const StyledTable = styled(AntTable)`
  padding-top: 10px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }

  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .ant-table-row:hover {
    border: 2px solid ${COLORS.PRIMARY}; 
  }
`;

const Table = (props) => {
    return (
        <StyledTable {...props} />
    )
};

export default Table;
