import { Button, Table, Tabs, Avatar, Badge, Card, message, Modal, Slider } from "antd";
import AvatarEditor from "react-avatar-editor"
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TabPane from "antd/lib/tabs/TabPane";
import COLORS from "common/Colors";
import { Col, Row } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { startCase } from "lodash";
import moment from "moment";
import { CALL_API } from "common/API";
import { LEAVE_ICON, LEAVE_INFO, STATUS_CODE } from "common/Constants";
import { setUserList } from "redux/actions";
import { bindActionCreators } from "redux";
import MainStyledDropzone from "../pages/Dropzone"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  getUserList,
  getClientid,
  getAllDepartmentList,
  getLocations,
  getRoleList,
} from "redux/selectors";
import LeaveTrackerChart from "./LeaveTracker";
import { CameraTwoTone } from '@ant-design/icons';

const ProfileDetails = ({
  userList,
  ClientId,
  roleList,
  updateUserList,
  departmentList,
  locationList
}) => {
  const history = useHistory();
  const { id } = useParams();

  const [filteruser, setFilterUser] = useState({})
  const [filterrole, setFilterRole] = useState([])

  useEffect(() => {
    const filteruser = userList?.find((item) => item?.userid === id);
    const filterrole = roleList?.find((item) =>
      filteruser?.roleid.includes(item?.roleId)
    );
    setFilterUser(filteruser)
    setFilterRole(filterrole)
  }, [userList])

  const [activeTab, setActiveTab] = useState("0");
  const [btnLoader, togglebtnLoader] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomin, setZoomin] = useState(1);
  const [rotatein, setRotatein] = useState(0);
  const [baseImage, setBaseImage] = useState();
  const [uniqueId, setUniqueId] = useState(generateUniqueId());

  const [annualLeaveData, setAnnualLeaveData] = useState([
    { type: LEAVE_INFO.AVAILABLE, value: 0, color: COLORS.LEAVE.ANNUAL_AVAILABLE },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.ANNUAL_CONSUMED },
  ]);

  const objectUrl = React.useMemo(() => selectedImage ? URL.createObjectURL(selectedImage) : null, [selectedImage])

  const editorRef = useRef(null);
  const
    settingNewCropImage = async () => {
      if (editorRef.current) {
        const canvas = editorRef.current.getImage();
        const croppedImage = canvas.toDataURL();
        setBaseImage(croppedImage);
        return croppedImage
      }
    };

  const tabTitles = [
    "Employee Details",
    "Identity Information",
    "Leave tracker",
    "Education Details",
    "Work Experience",
  ];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const wrokExperenceList = [
    {
      Companyname: filteruser?.companyname || "-",
      jobTitle: filteruser?.jobtitle || "-",
      fromDate: filteruser?.professionalsince || "-",
      jobDescription: filteruser?.jobdescription || "-",
      relevnt: filteruser?.relevant || "-",
    },
  ];
  const wrokExperenceColumns = [
    {
      title: "Company name",
      dataIndex: "Companyname",
      key: "Companyname"
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "From Date",
      dataIndex: "fromDate",
      key: "fromDate",
    },
    {
      title: "Relevnt",
      dataIndex: "relevnt",
      key: "relevnt",
      align: "center",
    },
  ];
  const LeaveApprovalData = [
    {
      date: "09-May-2015",
      leaveType: "Annual Leave",
      duration: "3 Days",
      description: "Going on a family holiday",
      action: ""
    }
  ]
  const LeaveApprovalColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          <StyledProfileDetails>
            <div className="leave_approval_actions">
              <Button>Approval</Button>
              <Button>Decline</Button>
            </div>
          </StyledProfileDetails>
        </>
      ),
    },
  ];
  const LeaveHistoryData = [
    {
      date: "09-May-2015",
      leaveType: "Annual Leave",
      duration: "3 Days",
      description: "Going on a family holiday",
    }
  ]
  const LeaveHistoryColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];
  const EducationList = [
    {
      instituteName: filteruser?.institutename || "-",
      degreeDiploma: filteruser?.degree || "-",
      specialization: filteruser?.specialization || "-",
      yearofCompletion: filteruser?.yearofcompletion || "-",
    }
  ]
  const EducationColumns = [
    {
      title: "Institute Name",
      dataIndex: "instituteName",
      key: "instituteName",
    },
    {
      title: "Degree/Diploma",
      dataIndex: "degreeDiploma",
      key: "degreeDiploma",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Year of Completion",
      dataIndex: "yearofCompletion",
      key: "yearofCompletion",
      align: "center",
    },
  ];

  const IdentityInfoList = [
    {
      type: "UAN",
      details: "100904319456",
      attachment: "",
    },
    {
      type: "PAN",
      details: "BLB19456",
      attachment: "Pan.jpeg",
    },
  ];

  const IdentityInfoColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      render: (attachment) => (attachment !== "" ? attachment : "-"),
    },
  ];

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
    }
  };
  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };
  const deleteEmployee = async (employee_id) => {
    togglebtnLoader(true);
    await CALL_API(`add-users/${ClientId}`, "delete", {
      user_id: id,
      status_id: 0,
    });
    togglebtnLoader(false);
    fetchMasterData();
    message.success('This Employee has been terminated')
    history.goBack();
  };
  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  let userId = id;
  let splitUserId = userId.split("@");
  let userIdWithoutDomain = splitUserId[0];
  let updatedUserId = userIdWithoutDomain + uniqueId;

  const obj = {
    userid: updatedUserId,
    filetype: selectedImage?.type || '',
    content: baseImage,
    filename: uniqueId,
    emailid: id
  };

  const handleUploadProfile = async () => {
    let x = await settingNewCropImage();
    try {
      setUniqueId(generateUniqueId());
      const obj = {
        userid: updatedUserId,
        filetype: selectedImage.type,
        content: x,
        filename: uniqueId,
        emailid: id,
      };
      const { code } = await CALL_API(
        `profile-upload/${ClientId}`,
        "post",
        obj
      );

      if (code === "SUCCESS") {
        setShowImageModal(false);
        fetchMasterData();
        setBaseImage(null);
      }
    } catch (error) {
      console.error("Error in handleUploadProfile:", error);
    }
  };


  const WorkInfoDetails = [
    {
      data: "User Name",
      value: filteruser?.fullname || "-",
    },
    {
      data: "Primary Industry",
      value: filteruser?.companyname || "-",
    },
    {
      data: "Secondary Industry",
      value: "Manufacturing & Distribution",
    },
    {
      data: "Professional Since",
      value: moment(filteruser?.professionalsince).format("YYYY") || "-",
    },
    {
      data: "Hired Date",
      value: moment(filteruser?.hireddate).format("DD-MMMM-YYYY") || "-"
    },
    {
      data: "Status",
      value: filteruser?.statusid ? "Active" : "In Active" || "-",
    },
    {
      data: "Qualification",
      value: filteruser?.degree || "-",
    },
    {
      data: "Employee Sponsor",
      value: filteruser?.employeesponser || "-",
    },
  ]
  const personalDet = [
    {
      data: "Date Of Birth",
      value: filteruser?.dateofbirth || "-",
    },
    {
      data: "Marital Status",
      value: filteruser?.maritalstatus || "-",
    },
    {
      data: "Age",
      value: filteruser?.age || "-",
    },
    {
      data: "Nationality",
      value: filteruser?.nationality || "-",
    },
    {
      data: "Gender",
      value: filteruser?.gender || "-",
    },
    {
      data: "Present Address",
      value: filteruser?.presentaddress || "-",
    },
    {
      data: "Permanent Address",
      value: filteruser?.permanentaddress || "-",
    },
  ]
  const handleDrop = acceptedFiles => {
    const file = acceptedFiles[0];
    setSelectedImage(file);
  };
  const CropPopModal = () => {
    setShowImageModal(true)

  }
  const CloseImageModal = async () => {
    setShowImageModal(false)
    setZoomin(1);
    setRotatein(0);
  }
  const resetSelectedImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <StyledProfileDetails>
        <label
          className="bg-transparent border-0 mb-3 text-left cursor-pointer"
          onClick={() => history.goBack()}
        >
          <b className="">Employee</b>{" "}
          <ArrowForwardIosIcon className="px-1 Icon_style" />
        </label>{" "}
        <b className="text-info pb-0 pt-1">{filteruser?.fullname}</b>
        <Row className="Broker_profile_flex">
          <Col xl={8}>
            <Col className="styled_col">

              {filteruser?.profileurl === null ? (
                <>
                  <Badge
                    count={
                      <CameraTwoTone
                        onClick={CropPopModal}
                        className="camera_select_icon"
                      />
                    }
                  >
                    <StyledAvatar size="default">
                      {startCase(filteruser.fullname).charAt(0)}
                    </StyledAvatar>
                  </Badge>
                </>
              ) : (
                <>
                  <Badge
                    count={
                      <CameraTwoTone
                        onClick={CropPopModal}
                        className="camera_select_icon"
                      />
                    }
                  >
                    <StyledAvatar
                      src={filteruser?.profileurl}
                      size="default"
                      className="StyledImage"
                    ></StyledAvatar>
                  </Badge>
                </>
              )}
              <div className="w-100 bg-red d-flex justify-content-between">
                <div><div className="px-3">
                  <div className="">
                    <p className="m-0 broker_name">{filteruser?.fullname || "-"}</p>
                  </div>
                  <div className="user_details">
                    <p>Role: </p>
                    <p> {filterrole?.roleName || "-"}</p>
                  </div>
                  <div className="contact_details">
                    <p className="contact_det_header">
                      Ph: <span className="contact_det_content">{filteruser?.phoneno || "-"}</span>
                    </p>
                  </div>
                  <p className="contact_det_header">
                    Email: <span className="contact_det_content">{id || "-"}</span>
                  </p>
                </div></div>
                <div><Button className="reactive_button" onClick={deleteEmployee}>Terminate</Button></div>

              </div>
            </Col>
          </Col>
          <Col xl={4} className="credit_box_container">
            <div className="credits_box">
              <p className="text-center employee_bond_text">Employee ID</p>
              <h2 className="activity_content">{filteruser?.employeeid}</h2>

            </div>
          </Col>
        </Row>
        <Tabs
          defaultActiveKey={activeTab}
          className="mt-3"
          onChange={handleTabChange}
        >
          {tabTitles.map((title, index) => (
            <TabPane tab={title} key={index.toString()}>
              {activeTab === "0" && (
                <>
                  <Row className="personal_details_box">
                    {personalDet.map((data, index) => {
                      return (
                        <Col xs={12} lg={6} key={index}>
                          <StyledRow className="my-2 employee_work_info">
                            <Col className="my-1 text-nowrap">
                              <b>{data.data}: </b>
                            </Col>
                            <Col className="my-1 employess_detail_values">
                              {data.value}
                            </Col>
                          </StyledRow>
                        </Col>
                      );
                    })}
                  </Row>
                  <br />
                  <p className="employee_detail_headings">Work Information:</p>
                  <Row className="employee_work_info_box">
                    {WorkInfoDetails.map((data, index) => {
                      return (
                        <Col xs={12} lg={6} key={index}>
                          <StyledRow className="my-2 employee_work_info">
                            <Col className="my-1 text-nowrap">
                              <b>{data.data}: </b>
                            </Col>
                            <Col className="my-1 employess_detail_values">
                              {data.value}
                            </Col>
                          </StyledRow>
                        </Col>
                      );
                    })}

                  </Row>
                </>
              )}
              {activeTab === "1" && (
                <Row className="personal_details_box">
                  <StyledTable
                    className="mb-5"
                    dataSource={IdentityInfoList}
                    columns={IdentityInfoColumns}
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "" : "non-white"
                    }
                    rowKey="role_id"
                    pagination={
                      IdentityInfoList.length > 20 ? paginationOptions : false
                    }
                  />
                </Row>
              )}
              {activeTab === "2" && (
                <>
                  <div className="leave_tracker_chart">
                    <div className="text-center leave_tracker_container">
                      <LeaveTrackerChart
                        iconName={LEAVE_ICON[7]}
                        title="ANNUAL LEAVE"
                        data={annualLeaveData}
                      />
                    </div>
                    <div className="text-center leave_tracker_container">
                      <LeaveTrackerChart
                        iconName={LEAVE_ICON[7]}
                        title="PERSONAL LEAVE"
                        data={annualLeaveData}
                      />
                    </div>
                  </div>
                  <br />
                  <p className="leave_title_content">Leave Approvals:</p>
                  <Row className="personal_details_box">
                    <StyledTable
                      className="mb-5"
                      dataSource={LeaveApprovalData}
                      columns={LeaveApprovalColumns}
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "" : "non-white"
                      }
                      rowKey="role_id"
                      pagination={
                        LeaveApprovalData.length > 20
                          ? paginationOptions
                          : false
                      }
                    />
                  </Row>
                  <br />
                  <p className="leave_title_content">Leave History:</p>
                  <Row className="personal_details_box">
                    <StyledTable
                      className="mb-5"
                      dataSource={LeaveHistoryData}
                      columns={LeaveHistoryColumns}
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "" : "non-white"
                      }
                      rowKey="role_id"
                      pagination={
                        LeaveHistoryData.length > 20 ? paginationOptions : false
                      }
                    />
                  </Row>
                </>
              )}
              {activeTab === "3" && (
                <>
                  <Row className="personal_details_box">
                    <StyledTable
                      className="mb-5"
                      dataSource={EducationList}
                      columns={EducationColumns}
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "" : "non-white"
                      }
                      rowKey="role_id"
                      pagination={
                        EducationList.length > 20 ? paginationOptions : false
                      }
                    />
                  </Row>
                </>
              )}
              {activeTab === "4" && (
                <Row>
                  <Col xs={24}>
                    <StyledTable
                      className="mb-5"
                      dataSource={wrokExperenceList}
                      columns={wrokExperenceColumns}
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "" : "non-white"
                      }
                      rowKey="role_id"
                      pagination={
                        wrokExperenceList.length > 20
                          ? paginationOptions
                          : false
                      }
                    />
                  </Col>
                </Row>
              )}
              {activeTab === "5" && (
                <div className="text-center">
                  <h2>Page under Constraction</h2>
                </div>
              )}
            </TabPane>
          ))}
        </Tabs>
      </StyledProfileDetails>
      <Modal
        title="Profile Pic"
        style={{ display: "flex", justifyContent: "center", borderRadius: '10px' }}
        open={showImageModal}
        onOk={() => { handleUploadProfile() }}
        okText="Set"
        okButtonProps={{ disabled: !selectedImage }}
        onCancel={() => {
          CloseImageModal();
          resetSelectedImage();
        }}
      >
        {selectedImage ? (
          <div>
            <AvatarEditor
              ref={editorRef}
              image={objectUrl}
              width={250}
              height={250}
              border={50}
              color={[255, 255, 255, 0.6]}
              scale={zoomin}
              rotate={rotatein}
              borderRadius={250}
            />
            <div className="editor_div">
              <label>Zoom:</label>
              <Slider defaultValue={zoomin} onChange={setZoomin} min={1}
                max={20} />
            </div>
            <div className="editor_div">
              <label>Rotate:</label>
              <Slider defaultValue={rotatein} onChange={setRotatein} min={0}
                max={360} />
            </div>
            <MainStyledDropzone onDrop={handleDrop} />
          </div>
        ) : (
          <MainStyledDropzone onDrop={handleDrop} />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  ClientId: getClientid(state),
  departmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  roleList: getRoleList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );
const StyledProfileDetails = styled.div`
.Icon_style{
  font-size: "20px";
}
.StyledImage {
  background-color: white;
  vertical-align: middle;
  }
  .ant-badge .ant-scroll-number-custom-component {
    transform: translate(0%, 0%) !important ;
    bottom: 0px !important;
    top: 65px;
  }
  .styled_col{
    display: flex;
    align-items: center;
    @media(max-width: 428px) {
      flex-direction: column;
    }
  }
  .camera_select_icon{
    font-size: 22px;
  }
  .leave_approval_actions{
    display: flex;
    gap: 10px;
    Button{
      width: 85px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal bold 16px/20px Circular Std;
      letter-spacing: 0px;
      color: #FFFFFF;
      background: #26A4FF 0% 0% no-repeat padding-box;
      border-radius: 5px;
    }
  }
  .leave_tracker_container {
    width: 300px;
    /* height: 140px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
  }
  .leave_tracker_chart{
    display: flex;
    gap: 1rem;
  }
  .css-1ps7utq {
    margin-bottom: 0 !important;
  }
  .employee_work_info_box{
    width: 100%;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000015;
  }
  .employess_detail_values{
    text-wrap: nowrap;
  }
  .info_identity_container{
    width: 100%;
    margin: auto;
  }
  .personal_details_box{
    width: 100%;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000015;
  }
  .employee_work_info{
    border-bottom: 1px solid #F8F8F8;
  }
  .employess_detail_summary{
    display: flex;
    flex-direction: column;
  }
  .employee_detail_headings{
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .leave_title_content{
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .Broker_profile_flex {
    background: transparent linear-gradient(82deg, #e6fcff 0%, #26a4ff 100%) 0%
      0% no-repeat padding-box;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
  }
  .Broker_profile_box {
    width: max-content;
    flex: unset;
    display: flex;
    align-items: end;
    gap: 0.6rem;
    padding-left: 0;
  }
  .broker_profile_img {
    width: 150px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
  }
  .profile_verified_img {
    width: 20px;
  }
  .broker_name {
    text-align: left;
    font: normal normal bold 24px/30px Circular Std;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .user_details {
    display: flex;
    width: max-content;
    gap: 5px;
    text-align: left;
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
    p {
      margin-bottom: 0.5rem;
    }
  }
  .contact_details {
    display: flex;
    gap: 0.6rem;
    text-wrap: nowrap;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #ffffff;
    p {
      margin-bottom: 0rem;
    }
  }
  .Engaged {
    font: normal normal medium 14px/20px Circular Std;
    letter-spacing: 0.07px;
    color: #3b3938;
    opacity: 1;
    margin-top: 5px;
  }
  .activity_content {
    text-align: center;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #3b3938;
    opacity: 1;
  }
  .employee_bond_text {
    text-align: center;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #3b3938;
    opacity: 1;
  }
  .contact_details {
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    color: #ffffff;
  }
  .contact_det_header {
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .contact_det_content {
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .ant-tabs-nav {
    background: #e6f5ff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    border-radius: 5px;
    padding-left: 20px;
  }
  .broker_score_hr {
    margin: 0rem;
    background: #00000029;
    color: #00000029;
    opacity: 1;
  }
  .vr {
    display: inline-block;
    align-self: stretch;
    width: 2px;
    height: 26px !important;
    background: #000000;
    color: #000000;
    opacity: 1;
  }
  .credit_box_container {
    display: flex;
    justify-content: end;
    gap: 0.8rem;
    padding-right: 0;
    .credits_box {
      .user_score_title {
        width: 100%;
        text-align: center;
        font: normal normal normal 16px/20px Circular Std;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.8;
        margin-bottom: 0rem;
        .user_score_status {
          font: normal normal bold 24px/30px Circular Std;
          letter-spacing: 0px;
          color: #3da000;
          opacity: 1;
        }
        .user_working_hour {
          font: normal normal bold 24px/20px Circular Std;
          letter-spacing: 0px;
          color: #000000;
          small {
            font: normal normal normal 16px/20px Circular Std;
            letter-spacing: 0px;
            color: #000000;
          }
        }
      }
    }
  }
  .credits_box {
    display: flex;
    flex: 1 0 200px;
    height: 150px;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
    background: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    text-align: left;
    h2 {
      font: normal normal bold 36px/46px Circular Std;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 0rem;
    }
    .active_status {
      font: normal normal normal 16px/20px Circular Std;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.8;
    }
  }
  .reactive_button {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #000000;
    font: normal normal medium 16px/20px Circular Std;
    letter-spacing: 0.08px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }

  @media (min-width: 320px) and (max-width: 767px){
    .employess_detail_values {
      text-wrap: wrap;
    }
  }
`;

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }
   .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ant-table-cell {
    height: 50px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;
const StyledAvatar = styled(Avatar)`
  font-size: 3rem;
  height: 100px;
  width: 100px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  .ant-avatar-string {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  .my-1 {
    display: flex;
    word-wrap: break-word;
  }
`;
const StyledModal = styled(Modal)`
 
`;
const StyledCard = styled(Card)`
  margin-bottom: 20px;
  .main_row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 912px) {
    .second_half_info {
      margin-top: -25px;
    }
  }
`;
const StyledStaffNameContainer = styled.div`
  text-align: center;
  .staff-name {
    font-weight: bold;
    font-size: 1rem;
    padding-top: 10px;
  }
  .location {
    padding-top: 5px;
    svg {
      vertical-align: sub;
      margin-right: 5px;
    }
  }
`;
export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
