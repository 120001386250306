export const getSendEmployeeForm = (data, allDepartment) => {
  const SendEmployeeForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Appraisal time period:",
              type: "daterange",
              name: "timePeriod",
              Placeholder: ['From', 'To'],
              value: data["timePeriod"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter Appraisal time period`,
                },
              ],
            },
            {
              id: 2,
              label: "Department:",
              type: "selectwithvalue",
              name: "department",
              Placeholder: "Department",
              value: data["department"],
              options: allDepartment.flatMap((data) => ({
                label: data.department_name,
                value: data.department_id,
              })),
              validationRules: [
                {
                  required: true,
                  message: `Please select department`,
                },
              ]
            },
          ],
          [
            {
              id: 2,
              label: "Select Type:",
              type: "select",
              name: "type",
              Placeholder: "Type",
              value: data["type"],
              validationRules: [
                {
                  required: true,
                  message: `Please select type`,
                },
              ],
              options: ["Yearly", "Half yearly", "Quater yearly"],
            },
          ],
          [
            {
              id: 2,
              label: "Send to employee",
              type: "button",
              position: "end",
              textColor: "white",
              event: "submit",
            },
           
          ],
        ],
      },
    ],
  };

  return {
    SendEmployeeForm,
  };
};
