import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  message,
} from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getAllDepartmentList,
  getBreakTypeList,
  getShiftTimeMaster,
  getClientid,
} from "redux/selectors";

import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  REPORT_DURATION_TYPES,
  STATUS_CODE,
} from "common/Constants";

import {
  addDaysinDate,
  dateInDetail,
  differenceBetweenTwoTime,
  generateHourMinutesFromMinutes,
  getKenyanDateTime,
  getMonthDateRange,
  getMySqlDate,
  momentDate,
  addMonthinDate,
} from "utils/Date";
import COLORS from "common/Colors";
import { includes, reduce, concat, find, get } from "lodash";

const StyledTable = styled(Table)`
  padding-top: 20px;
   .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
  border-radius: 5px;
`;
function BreakReport({
  userRoleId,
  breakTypeList,
  allDepartmentList,
  selectedDepartmentId,
  ClientId,
}) {
  const [leaveList, setLeaveList] = useState([]);
  const [CSVLeaveList, setCSVLeaveList] = useState([]);
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const [filterList, setFilterList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const containerRef = useRef(null);
  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
    }
    setMonthList(month_list);
  };

  const STAFF_LIST_ALL = leaveList.map((val) => {
    return { user_id: val.userId, name: val.fullName };
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map((a) => JSON.stringify(a)))].map(
    (a) => JSON.parse(a)
  );

  const filterbreaklist = leaveList.filter((val) => {
    if (form.getFieldValue("staff_name") === "") {
      return val;
    } else if (val?.userId === form.getFieldValue("staff_name")) {
      return val;
    }
  });

  const CSVHeaders = [
    { label: "Name", key: "fullName" },
    { label: "Department", key: "departmentName" },
    { label: "Date", key: "breakStartDate" },
    { label: "Start time", key: "breakStartDateTime" },
    { label: "End time", key: "breakEndDateTime" },
    { label: "Duration", key: "duration" },
    { label: "Break Type", key: "breakType" },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      width: "12%",
    },
    {
      title: "Department",
      dataIndex: "departmentName",
      key: "departmentName",
      width: "10%",
    },
    {
      title: "Date",
      dataIndex: "breakStartDate",
      key: "breakStartDate",
      width: "7%",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Day",
      dataIndex: "breakStartDate",
      key: "breakStartDate",
      width: "10%",
      render: (date) => {
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      },
    },
    {
      title: "Start time",
      dataIndex: "breakStartDateTime",
      key: "breakStartDateTime",
      width: "5%",
      render: (breakStartDateTime) => {
        const { time24 } = dateInDetail(breakStartDateTime);
        return `${time24}`;
      },
    },
    {
      title: "End time",
      dataIndex: "breakEndDateTime",
      key: "breakEndDateTime",
      width: "5%",
      render: (breakEndDateTime) => {
        const { time24 } = dateInDetail(breakEndDateTime);
        return `${time24}`;
      },
    },
    {
      title: "Duration (in hrs)",
      dataIndex: "duration",
      key: "duration",
      width: "10%",
      render: (_, record) => {
        const diff = differenceBetweenTwoTime(
          record.breakStartDateTime,
          record.breakEndDateTime
        );
        return generateHourMinutesFromMinutes(diff);
      },
    },
    {
      title: "Break Type",
      dataIndex: "breakType",
      key: "breakType",
      width: "10%",
    },
  ];

  useEffect(() => {
    list();
    getBreakReportData();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("duration_type"),
    form.getFieldValue("start_date"),
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("department"),
    form.getFieldValue("break_type"),
  ]);

  const getBreakReportData = async () => {
    setLeaveList([]);
    const {
      start_date,
      end_date,
      duration_type,
      month,
      break_type,
    } = form.getFieldsValue();
    setDisplayTableLoader(true);
    const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
      dateInDetail(getKenyanDateTime()).year,
      get(find(MONTH_LIST, { value: month }), "index", 0)
    );
    const { code, breakList = [] } = await CALL_API(
      `break-report/${ClientId}`,
      "post",
      {
        department: selectedDepartmentId,
        break_type: break_type ? break_type : "",
        start_date: getMySqlDate(
          duration_type === 2 ? startDateOfMonth : start_date
        ),
        end_date: getMySqlDate(
          duration_type === 2 ? lastDateOfMonth : end_date
        ),
      }
    );
    setDisplayTableLoader(false);
    if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
      setLeaveList(breakList.reverse());
    }
  };

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const prepareCSV = async (event, done) => {
    if (filterbreaklist.length > 0) {
      setCSVLeaveList(
        reduce(
          filterbreaklist,
          function (result, BreakReport) {
            const {
              date_th: startDate_th,
              shortMonthName: startMonthName,
              year: end_year,
            } = dateInDetail(BreakReport.breakStartDate);
            const diff = differenceBetweenTwoTime(
              BreakReport.breakStartDateTime,
              BreakReport.breakEndDateTime
            );
            const hours = Math.floor(diff / 60);
            const minutes = diff - 60 * hours;
            result.push({
              'Name': BreakReport.fullName,
              "Department": BreakReport.departmentName,
              'Date': `${startDate_th} ${startMonthName} ${end_year}`,
              "Day": `${moment(BreakReport.breakStartDate).format("dddd")}`,
              "Start time": dateInDetail(BreakReport.breakStartDateTime).time24,
              "End time": dateInDetail(BreakReport.breakEndDateTime).time24,
              "Duration": `${hours}:${minutes}`,
              "Break Type": BreakReport.breakType,
            });
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  return (
    <>
      <Row className="page-title">
        <Col
          xs={24}
          sm={19}
          md={19}
          className="page-title-head"
          ref={containerRef}
        >
          <Space>
            <StyledSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                staff_name: "",
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
                department: "",
                break_type: "",
              }}
              onFinish={getBreakReportData}
            >
              <Form.Item
                name="duration_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  options={REPORT_DURATION_TYPES}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>

              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Form.Item
                        name="start_date"
                        rules={[
                          () => ({
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="Start date"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date"
                        rules={[
                          () => ({
                            // first parameter is needed
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="End date"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Form.Item
                      name="month"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select..."
                        options={monthList}
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="staff_name"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select Employee"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={concat(
                    {
                      user_id: "",
                      name: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "name",
                    value: "user_id",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>

              {(userRoleId === 1 || userRoleId === 6) && (
                <Form.Item name="department">
                  <Select
                    placeholder="Select Department"
                    options={concat(
                      {
                        department_id: "",
                        department_name: "All Departments",
                      },
                      allDepartmentList
                    )}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              )}

              <Form.Item name="break_type">
                <Select
                  showSearch
                  placeholder="Search break type"
                  name="break_type"
                  optionFilterProp="breakName"
                  filterOption={(input, option) =>
                    option.breakName.includes(input)
                  }
                  options={concat(
                    {
                      breakId: "",
                      breakName: "All Break Types",
                    },
                    breakTypeList
                  )}
                  fieldNames={{
                    label: "breakName",
                    value: "breakId",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </StyledSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className="align-right">
          <StyledButton onClick={prepareCSV} disabled={leaveList.length === 0}>
            Export CSV file
          </StyledButton>
          <CSVLink
            header={CSVHeaders}
            data={CSVLeaveList}
            filename={"Break-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={filterbreaklist}
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="user_leave_id"
          pagination={filterbreaklist.length > 20 ? paginationOptions : false}
        />
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  allDepartmentList: getAllDepartmentList(state),
  breakTypeList: getBreakTypeList(state),
  shiftTimeMaster: getShiftTimeMaster(state),
  ClientId: getClientid(state),
});

export default connect(mapStateToProps, null)(BreakReport);
