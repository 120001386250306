import React, { useEffect, useLayoutEffect, useState } from "react";
import { Layout, Row } from "antd";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Modal, Result, Button } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import styled from "styled-components";
// Components
import SideBar from "molecules/SideMenu";
import Header from "molecules/Header";
import Container from "pages/Container";
import { Products, STATUS_CODE } from "common/Constants";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setIsUserLoggedIn,
  setSystemDate,
  setUsernName,
  setLoadingDataFirstTime,
  setBreakTypesList,
  setDepartmentList,
  setLeaveCategoryList,
  setLocationList,
  setPunchActions,
  setUserRole,
  setDepartmentId,
  setRoleList,
  setCheckedIn,
  setCheckInLocationId,
  setIsOnBreak,
  setUserList,
  setStatusList,
  setAllDepartmentList,
  setPunchId,
  setBreakId,
  toggleProcessingModal,
  setShiftTimeMaster,
  setUserProfile,
  clearStore,
  setProfilePic,
  setMenuList,
  setClientid,
  setCurrentPlan,
  setTrailPeriod,
  setUserMenuList,
  setCheckInDepartmentId,
  setBandList
} from "redux/actions";
import {
  getIsUserLoggedIn,
  getUserName,
  getSysteDate,
  getUserRoleId,
  getTrailPeriod,
} from "redux/selectors";
import ProcessingModal from "molecules/ProcessingModal";
import { CALL_API } from "common/API";
import { getServerDateToMySql } from "utils/Date";
import PrivacyAndPolicy from "pages/PrivacyAndPolicy";
import  jwtDecode  from "jwt-decode";
import SwitchAppFooter from "SwitchAppFooter";

var createHost = require("cross-domain-storage/host");

const StyledAppDiv = styled.div`
  height: 100%;
`;
const PlanWrap = styled(Row)`
  background-color: #d32f2f;
  height: 30px;
  padding: 5px;
  box-shadow: 5px 5px 10px #00000029;
  cursor: pointer;
  .title {
    font-weight: bold;
    color: #fff;
    /* margin-bottom: 5%; */
  }
  .subtitle {
    font-weight: bold;
    color: #ffff00;
  }
  .black-title {
    color: #000000;
  }
`;

const App = ({
  activePage,
  isUserLoggedIn,
  setUserLoggedIn,
  saveSystemDate,
  setBreakTypes,
  saveDepartmentList,
  saveAllDepartmentList,
  saveLeaveCategoryList,
  saveLocationList,
  savePunchActions,
  saveUsernName,
  toggleLoadingDataFirstTime,
  saveDepartmentId,
  saveRoleList,
  updateCheckInLocationId,
  updateIsOnBreak,
  updateCheckedIn,
  updateUserList,
  saveStatusList,
  savePunchId,
  saveBreakId,
  updateProcessingModal,
  saveShiftTimeMaster,
  saveUserProfile,
  removeStoreData,
  saveProfilePic,
  saveUserRole,
  saveMenuList,
  saveBandList,
  saveClientid,
  saveTrailPeriod,
  saveCurrentPlan,
  userRoleId,
  TrailPeriod,
  saveUserMenuList,
  updateCheckInDepartmentId,
}) => {
  createHost([
    {
      origin: "http://localhost:3000",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "http://localhost:3001",
      allowedMethods: ["get", "set", "remove"],
    },
    // {
    //   origin: "https://www.tymeplus.waila.co",
    //   allowedMethods: ["get", "set", "remove"],
    // },
    {
      origin: "https://www.tractask.waila.co",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "https://www.crm.waila.co",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "http://localhost:3001/",
      allowedMethods: ["get", "set", "remove"],
    },
  ]);

  const [pageTitle, setPageTitle] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [intervalID, setIntervalID] = useState(0);
  const [open, setOpen] = useState(false);
  const [showplan, setshowplan] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setCollapsed(!collapsed);
  };

  const isLoggedIn = (clientid) => {
    setUserLoggedIn(true);
    toggleLoadingDataFirstTime(true);
    fetchMasterData(true, clientid);
  };

  const isLoggedOut = () => {
    setUserLoggedIn(true);
    toggleLoadingDataFirstTime(true);
  };

  const logOut = () => {
    setTimeout(() => {
      isLoggedOut();
      window.location.href = "https://www.dev.tymeplus.co";
    }, 500);
  };

  const updatePageTitle = (title) => {
    setPageTitle(title);
  };

  let defaultKey = "";

  useEffect(() => {
    if (isUserLoggedIn) {
      const intId = setInterval(() => {
        saveSystemDate(startTimeCounter());
      }, 1000);
      setIntervalID(intId);
      setUserLoggedIn(true);

      getPreview();
    } else {
      clearInterval(intervalID);
    }
  }, [isUserLoggedIn]);

  useLayoutEffect(() => {
    clearInterval(intervalID);
    updateProcessingModal(false);
    if (window.innerWidth < 567) {
      toggleDrawer();
    }
  }, []);

  // redirectToNewDomain is used For local development

  // const redirectToNewDomain = () => {
  //   const newDomain = window.localStorage.getItem("client");
  //   window.location.href = newDomain;
  // };

  // localtoken is used For local development

  // const localtoken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IldBVFNLSUciLCJ1c2VySWQiOiJkZW1vc3RhZmZAZ21haWwuY29tIiwidXNlck5hbWUiOiJEZW1vIFN0YWZmIiwiaWF0IjoxNzA3NDYwOTQ0LCJleHAiOjE3MDc0NjgxNDR9.OfjuMTAsJBzZQfyjhFsffLUt-lcw23bqW8lmjHjt0z0";

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get("client");


    const token = urlParams.get("token");
    if (token) {
      window.localStorage.removeItem('token');
      removeStoreData();
      window.localStorage.setItem("token", token);
      window.localStorage.setItem("client", referrer);
    }
    const clientid = jwtDecode(window.localStorage.getItem("token"))?.clientId;
    window.history.pushState("", "", "/");

    if (clientid && token) {
      isLoggedIn(clientid);
    }
    // window.localStorage.setItem("token", localtoken);
    // const clientid = jwtDecode(window.localStorage.getItem("token"))?.clientId;
    // isLoggedIn(clientid);
    // if(!window.localStorage.getItem("token")){
    //   redirectToNewDomain();
    // }
  }, []);

  const startTimeCounter = () => {
    const d = new Date();
    const ist = new Date(
      d.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return getServerDateToMySql(ist);
  };

  const fetchMasterData = async (fetch_master_data, clientId) => {
    const {
      code,
      name,
      userid,
      role_id,
      breakList,
      roleList,
      departmentList,
      leaveCategories,
      locations,
      punchActions,
      location_id,
      punch_action_id,
      userList,
      statusList,
      shiftTimeMaster,
      break_id = "",
      department_id,
      location_name,
      joining_date,
      username,
      email_id,
      job_title,
      department,
      employer_id,
      payroll,
      staff_department_id,
      menuList,
      bandList,
      clientid,
      trailperiodendon,
      currentPlan,
      userMenuList,
      threadid,
    } = await CALL_API(`master-data/${clientId}`, "post", {
      fetch_master_data,
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        setBreakTypes(breakList);
        saveMenuList(menuList);
        saveBandList(bandList);
        saveUserMenuList(userMenuList);
        saveDepartmentList(roleList[0]?.department);
        saveAllDepartmentList(departmentList);
        saveDepartmentId([roleList[0]?.department[0]]);
        saveLeaveCategoryList(leaveCategories);
        saveLocationList(locations);
        saveTrailPeriod(trailperiodendon);
        saveCurrentPlan(currentPlan);
        saveClientid(clientid);
        savePunchActions(punchActions);
        saveUsernName(name);
        saveRoleList(roleList);
        updateUserList(userList);
        saveStatusList(statusList);
        saveShiftTimeMaster(shiftTimeMaster);
        saveUserProfile({
          location_name,
          joining_date,
          username,
          email_id,
          job_title,
          department,
          payroll,
          employer_id,
          userid,
          staff_department_id,
          threadid,
          role_id
        });
      }

      updateCheckInLocationId(location_id?.toString());
      updateCheckInDepartmentId(department_id?.toString());
      updateIsOnBreak(break_id ? true : false);
      saveBreakId(break_id);
      savePunchId(punch_action_id);
      saveUserRole(role_id[0]);
      updateCheckedIn(punch_action_id ? true : false);
      toggleLoadingDataFirstTime(false);
      if (punch_action_id) {
        window.localStorage.setItem("login_reminder", "true");
      }
    } else {
      setSessionTimeout(true)
    }
  };

  const getPreview = async () => {
    const token = window.localStorage.getItem("token");

    const clientId = jwtDecode(window.localStorage.getItem("token"))?.clientId;

    const { code, url } = await CALL_API(
      `profile-upload/${clientId}`,
      "get",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      saveProfilePic(url);
    }
  };

  return (
    <StyledAppDiv>
      {isUserLoggedIn && (
        <>
          <Router>
            <Layout isapp={`${navigator.userAgent === "application"}`}>
              <SideBar
                open={open}
                onClose={onClose}
                setPageTitle={updatePageTitle}
                defaultKey={defaultKey}
                activePage={activePage}
                collapsed={collapsed}
                toggleCollapse={toggleDrawer}
              ></SideBar>
              <Layout>
                <Header
                  showDrawer={showDrawer}
                  pageTitle={pageTitle}
                  setLoggedIn={isLoggedIn}
                  setPageTitle={updatePageTitle}
                  toggleCollapse={toggleDrawer}
                  collapsed={collapsed}
                  showplan={showplan}
                  setshowplan={setshowplan}
                  isLoggedOut={isLoggedOut}
                ></Header>
                {userRoleId === 1 && TrailPeriod && (
                  <PlanWrap onClick={() => setshowplan(true)}>
                    <marquee
                      className="title"
                      width="100%"
                      direction="left"
                      height="100px"
                    >
                      Your Free Trail Period Ends on{" "}
                      <span className="subtitle">{TrailPeriod}</span> Please{" "}
                      <span className="black-title">Upgrade</span> Your Plan
                    </marquee>
                  </PlanWrap>
                )}

                <Container />
                {/* <SwitchAppFooter products={Products} /> */}
              </Layout>
            </Layout>
          </Router>
        </>
      )}

      <Router>
        <Route path="/privacy-policy">
          <PrivacyAndPolicy />
        </Route>
      </Router>

      <ProcessingModal />

      <Modal
        visible={sessionTimeout}
        maskClosable={false}
        title={null}
        footer={null}
        closable={false}
      >
        <Result
          icon={<FieldTimeOutlined />}
          title="Your session has expired!"
          extra={
            <Button onClick={() => logOut()} type="primary">
              Login
            </Button>
          }
        />
      </Modal>
    </StyledAppDiv>
  );
};

const mapStateToProps = (state) => ({
  isUserLoggedIn: getIsUserLoggedIn(state),
  userName: getUserName(state),
  systeDate: getSysteDate(state),
  userRoleId: getUserRoleId(state),
  TrailPeriod: getTrailPeriod(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUserLoggedIn: setIsUserLoggedIn,
      saveSystemDate: setSystemDate,
      saveUsernName: setUsernName,
      setBreakTypes: setBreakTypesList,
      saveDepartmentList: setDepartmentList,
      saveAllDepartmentList: setAllDepartmentList,
      saveLeaveCategoryList: setLeaveCategoryList,
      saveLocationList: setLocationList,
      savePunchActions: setPunchActions,
      saveUserRole: setUserRole,
      toggleLoadingDataFirstTime: setLoadingDataFirstTime,
      saveDepartmentId: setDepartmentId,
      saveRoleList: setRoleList,
      updateCheckedIn: setCheckedIn,
      updateCheckInLocationId: setCheckInLocationId,
      updateIsOnBreak: setIsOnBreak,
      updateUserList: setUserList,
      saveStatusList: setStatusList,
      savePunchId: setPunchId,
      saveBreakId: setBreakId,
      updateProcessingModal: toggleProcessingModal,
      saveShiftTimeMaster: setShiftTimeMaster,
      saveUserProfile: setUserProfile,
      removeStoreData: clearStore,
      saveProfilePic: setProfilePic,
      saveMenuList: setMenuList,
      saveBandList: setBandList,
      saveUserMenuList: setUserMenuList,
      saveClientid: setClientid,
      saveCurrentPlan: setCurrentPlan,
      saveTrailPeriod: setTrailPeriod,
      updateCheckInDepartmentId: setCheckInDepartmentId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
