import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";
const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding-left: 10px;
    background: #e6f5ff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    font-weight: bold;
  }
  .ant-tabs-tab {
    &:hover {
      color: #000000;
    }
  }
  .ant-tabs-ink-bar {
    background: #26a4ff;
    font-weight: bold;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #26a4ff;
    text-shadow: 0 0 0.25px currentcolor;
  }
`;

const TabsList = ({ items, handleTabSelect }) => {
    return (
        <StyledTabs
            defaultActiveKey={0}
            onChange={handleTabSelect}
            items={items?.map((val) => {
                return {
                    label: val?.label,
                    key: val?.id,
                    children: val?.component,
                };
            })}
        />
    );
};
export default TabsList;
