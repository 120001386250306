import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Spin } from "antd";
import Icon from "atoms/Icon";
import UICard from "reusableComponent/UICard/UICard";
import { CALL_API } from "common/API";
import { getClientid, getUserList } from "redux/selectors";
import { connect } from "react-redux";
import moment from "moment";
import { STATUS_CODE } from "common/Constants";
import { Link } from "react-router-dom";

const DashboardWrap = styled.div`
  margin-left: 10px;
  width: 80vw;
  height: 80vh;
  img {
    object-fit: cover;
    width: 100%;
  }
  .styled-col {
    height: 250px;
    background-color: #ffffff;
    /* background-color: yellow; */
    border-radius: 15px;
    border: 1px solid transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .styled-add-button {
    background: transparent;
    border: 1px solid #2d53da;
    color: #2d53da;
    font-size: 14px;
    border-radius: 5px;
    
  }
  .styled-icon {
    width: 40px !important;
    height: 40px !important;
  }
`;

const Ownerdashboard = ({ ClientId, userList }) => {
  const [displayloader, setDisplayLoader] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [BreakData, setBreakData] = useState([]);
  const [holidayData, setHolidayData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [locationData, setLocationData] = useState([]);

  const currentYear = moment().year();

  const getfilterMaleList = userList?.filter(
    (item) => item?.gender.toLowerCase() === "male" && item?.statusid === "1"
  );
  const getfilterFemaleList = userList?.filter(
    (item) => item?.gender.toLowerCase() === "female" && item?.statusid === "1"
  );

  const getShiftList = async () => {
    try {
      setDisplayLoader(true);
      const response = await CALL_API(`user-shift/${ClientId}`, "get", {});
      setShiftData(response.reverse());
      setDisplayLoader(false);
    } catch (error) {
      console.log("[getShiftList] Error-->", error);
    }
  };

  const getLeaveList = async () => {
    try {
      setDisplayLoader(true);
      const { response } = await CALL_API(`owner-leave/${ClientId}`, "get", {});
      setLeaveData(response.reverse());
      setDisplayLoader(false);
    } catch (error) {
      console.log("[getDepartmentList], Error-->", error);
    }
  };

  const getBreakList = async () => {
    try {
      setDisplayLoader(true);
      const response = await CALL_API(`owner-break/${ClientId}`, "get", {});
      setBreakData(response.reverse());
      setDisplayLoader(false);
    } catch (error) {
      console.log("[getDepartmentList], Error-->", error);
    }
  };

  const getHolidayList = async () => {
    try {
      setDisplayLoader(true);
      const response = await CALL_API(`holiday-list/${ClientId}`, "post", {
        start_date: moment()
          .year(currentYear)
          .startOf("year")
          .format("yyyy-MM-DD"),
        end_date: moment().year(currentYear).endOf("year").format("yyyy-MM-DD"),
      });
      setHolidayData(response.reverse());
      setDisplayLoader(false);
    } catch (error) {
      console.log("[getHolidayList], Error-->", error);
    }
  };

  const getDepartmentList = async () => {
    try {
      setDisplayLoader(true);
      const { response } = await CALL_API(
        `owner-department/${ClientId}`,
        "get",
        {}
      );
      setDepartmentData(response.reverse());
      setDisplayLoader(false);
    } catch (error) {
      console.log("[getDepartmentList], Error-->", error);
    }
  };

  const getRoleList = async () => {
    setDisplayLoader(true);
    const { code, response } = await CALL_API(
      `owner-roles/${ClientId}`,
      "get",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      setRoleData(response.reverse());
      setDisplayLoader(false);
    }
  };

  const getLocationList = async () => {
    try {
      setDisplayLoader(true);
      const { response } = await CALL_API(
        `owner-location/${ClientId}`,
        "get",
        {}
      );
      setLocationData(response.reverse());
      setDisplayLoader(false);
    } catch (error) {
      console.log("[getLocationList], Error-->", error);
    }
  };

  const Rolecolumns = [
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "M/F",
      dataIndex: "male",
      key: "male",
      render: (_, record) => {
        if (
          (record?.male && record?.female) ||
          (record?.male === "" && record?.female === "")
        ) {
          return `${record?.male} / ${record?.female}`;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "male",
      key: "male",
      render: (_, record) => {
        if (
          (record?.male && record?.female) ||
          (record?.male === "" && record?.female === "")
        ) {
          return record?.male + record?.female;
        } else {
          return "-";
        }
      },
      align: "center",
    },
  ];

  const Departmentcolumns = [
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
    },
    {
      title: "M/F",
      dataIndex: "male",
      key: "male",
      render: (_, record) => {
        if (
          (record?.male && record?.female) ||
          (record?.male === "" && record?.female === "")
        ) {
          return `${record?.male} / ${record?.female}`;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "male",
      key: "male",
      render: (_, record) => {
        if (
          (record?.male && record?.female) ||
          (record?.male === "" && record?.female === "")
        ) {
          return record?.male + record?.female;
        } else {
          return "-";
        }
      },
      align: "center",
    },
  ];

  const locationcolumns = [
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "M/F",
      dataIndex: "male",
      key: "male",
      render: (_, record) => {
        if (
          (record?.male && record?.female) ||
          (record?.male === "" && record?.female === "")
        ) {
          return `${record?.male} / ${record?.female}`;
        } else {
          return "-";
        }
      },
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "male",
      key: "male",
      render: (_, record) => {
        if (
          (record?.male && record?.female) ||
          (record?.male === "" && record?.female === "")
        ) {
          return record?.male + record?.female;
        } else {
          return "-";
        }
      },
      align: "center",
    },
  ];

  const shiftcolumns = [
    {
      title: "People",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Starts",
      dataIndex: "shiftstarttime",
      key: "shiftstarttime",
      align: "center",
    },
    {
      title: "Ends",
      dataIndex: "shiftendtime",
      key: "shiftendtime",
      align: "center",
    },
  ];

  const holidaycolumns = [
    {
      title: "Name",
      dataIndex: "holiday_name",
      key: "holiday_name",
    },
    {
      title: "Starts",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
    },
    {
      title: "Ends",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
    },
  ];

  const leavecolumns = [
    {
      title: "Category",
      dataIndex: "leaveCategoryName",
      key: "leaveCategoryName",
    },
    {
      title: "Days",
      dataIndex: "numberOfLeaves",
      key: "numberOfLeaves",
      align: "center",
    },
    {
      title: "Allocation",
      dataIndex: "allocation",
      key: "allocation",
      align: "center",
    },
  ];

  const breakcolumns = [
    {
      title: "Break",
      dataIndex: "breakName",
      key: "breakName",
    },
    {
      title: "Department",
      dataIndex: "no_of_department",
      key: "no_of_department",
      align: "center",
    },
    {
      title: "Location",
      dataIndex: "no_of_location",
      key: "no_of_location",
      align: "center",
    },
  ];

  useEffect(() => {
    getShiftList();
    getLeaveList();
    getBreakList();
    getHolidayList();
    getDepartmentList();
    getRoleList();
    getLocationList();
  }, []);

  return (
    <DashboardWrap>
      <Row>
        <Col xl={4} className="p-2">
          <Spin spinning={displayloader}>
            <div className="styled-col p-3">
              <b>People</b>
              <div className="text-center">
                <Icon className="m-1 styled-icon" name="dashboardpeople" />
                <h4 className="text-center">
                  {getfilterMaleList?.length + getfilterFemaleList?.length}
                </h4>
                <Row className="pt-1">
                  <Col sm={14} className="text-end px-1">
                    Male
                  </Col>
                  <Col sm={10} className="text-start px-1">
                    {getfilterMaleList?.length}
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col sm={14} className="text-end px-1">
                    Female
                  </Col>
                  <Col sm={10} className="text-start px-1">
                    {getfilterFemaleList?.length}
                  </Col>
                </Row>
                <Link to="/employee" className="mt-1 p-1 styled-add-button">
                  <Icon name="peoplebtn" /> Add
                </Link>
              </div>
            </div>
          </Spin>
        </Col>

        <Col xl={10} className="p-2">
          <Spin spinning={displayloader}>
            <UICard
              tabledata={roleData}
              tablecolumns={Rolecolumns}
              name="Role"
              iconsname="dashboardrole"
              btnicon="rolebtn"
              total={roleData.length}
              path={"roles"}
            />
          </Spin>
        </Col>

        <Col xl={10} className="p-2">
          <Spin spinning={displayloader}>
            <UICard
              tabledata={departmentData}
              tablecolumns={Departmentcolumns}
              name="Departments"
              iconsname="dashboarddepartment"
              btnicon="departmentbtn"
              total={departmentData.length}
              path={"department"}
            />
          </Spin>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="p-2">
          <Spin spinning={displayloader}>
            <UICard
              tabledata={locationData}
              tablecolumns={locationcolumns}
              name="Locations"
              iconsname="dashboardlocation"
              btnicon="locationbtn"
              total={locationData.length}
              path={"location"}
            />
          </Spin>
        </Col>

        <Col xl={12} className="p-2">
          <Spin spinning={displayloader}>
            <UICard
              tabledata={shiftData}
              tablecolumns={shiftcolumns}
              name="Shift"
            />
          </Spin>
        </Col>
      </Row>

      <Row className="pb-3">
        <Col xl={8} className="p-2">
          <Spin spinning={displayloader}>
            <UICard
              tabledata={leaveData}
              tablecolumns={leavecolumns}
              name="Leave"
              iconsname="dashboardleave"
              btnicon="leavebtn"
              total={leaveData.length}
              path={"leave-categories"}
            />
          </Spin>
        </Col>

        <Col xl={8} className="p-2">
          <Spin spinning={displayloader}>
            <UICard
              tabledata={BreakData}
              tablecolumns={breakcolumns}
              name="Break"
              iconsname="dashboardbreak"
              btnicon="breakbtn"
              total={BreakData.length}
              path={"break"}
            />
          </Spin>
        </Col>

        <Col xl={8} className="p-2">
          <Spin spinning={displayloader}>
            <UICard
              tabledata={holidayData}
              tablecolumns={holidaycolumns}
              name="Holiday"
              iconsname="dashboardholiday"
              btnicon="holidaybtn"
              total={holidayData.length}
              path={"holiday"}
            />
          </Spin>
        </Col>
      </Row>
    </DashboardWrap>
  );
};
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  userList: getUserList(state),
});
export default connect(mapStateToProps)(Ownerdashboard);
