import React from "react";
import { Button, Table } from "antd";
import styled from "@emotion/styled";
import { getRoleList } from "redux/selectors";
import COLORS from "common/Colors";
import { connect } from "react-redux";

const StyledButton = styled(Button)`
  background: #26A4FF;
  border-radius: 5px;
  color: #FFFFFF;
  width: 100px;
  font-weight: bold;
`;
const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #F0F0F0;
  }
   .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 5px 10px;
    height: 0px !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #F4F4F4;
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #F9F9F9;
  }
  .normal-column {
    white-space: normal;
  }
`;
const StyledButtons = styled(Button)`
  background: #26A4FF;
  border-radius: 5px;
  color: #FFFFFF;
  width: 150px;
  font-weight: bold;
`;
const SelectTable = ({
  step,
  setStep,
  columns,
  tabledata,
  submit,
  setCreate,
  setOpenModal,
  Name,
  selectedRoleId
}) => {

  const customLocale = {
    emptyText: (<StyledButtons className="m-3" onClick={() => setOpenModal(true)}>
      {Name}
    </StyledButtons>),
  };

  return (
    <>
      <StyledTable
        className="mb-3"
        dataSource={tabledata}
        columns={columns}
        rowKey="role_id"
        pagination={false}
        locale={customLocale}
      />
      <div className="text-end w-100">
        <StyledButton onClick={() => setStep(step - 1)} className="mx-2">
          Back
        </StyledButton>
        {submit ? (
          <StyledButton onClick={() => setCreate(true)}>Create</StyledButton>
        ) : (
          <StyledButton
            disabled={selectedRoleId?.length === 0}
            onClick={() => setStep(step + 1)}
          >
            Next
          </StyledButton>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  roleList: getRoleList(state),
});
export default connect(mapStateToProps, null)(SelectTable);