import React, { useState } from 'react'
import { getAddEditPolicyJson } from './JsonFormat';
import GridBuilder from 'UIBuilderEngine/GridBuilder';
import { CALL_API } from 'common/API';
import { message } from 'antd';


function AddEditPolicy({value="", ClientId, userInfo, goBack, getPolicyData}) {
  
  const [data, setData] = useState(value)
  const [loading, setLoading] = useState(false)

  const { addPolicyForm } = getAddEditPolicyJson(data)

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleClick = () => {
    goBack();
  }

  const handleSubmitForm = async(form) => {
    setLoading(true)
    if (value){
      await CALL_API(`hrpolicy/${ClientId}?policyId=${value?.policyId}`, "patch", {
        policyTitle: form?.policyTitle,
        policyDetails: form?.policyDetails,
        createdUserId: userInfo?.employer_id,
        lastUpdated: new Date()
      });
      getPolicyData()
      goBack()
      message.success("Successfully Updated");
      setLoading(false)
      return
    }
    await CALL_API(`hrpolicy/${ClientId}`, "post", {
      policyTitle: form?.policyTitle,
      policyDetails: form?.policyDetails,
      createdUserId: userInfo?.employer_id,
      lastUpdated: new Date()
    });
    goBack()
    getPolicyData()
    setLoading(false)
    message.success("Successfully Added");
    return
  };

  return (
    <div className='p-2'>
      <GridBuilder
        formStructureJson={addPolicyForm}
        handleChange={handleFormChange}
        formSubmit={handleSubmitForm}
        initialValues={value}
        loading={loading}
        handleClick={handleClick}
      />
    </div>
  )
}

export default AddEditPolicy