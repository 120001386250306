import React from "react";

import ManagerDashboard from "./ManagerDashboard";
import HrDashboard from "./HrDashboard";
import MyDashboard from "./MyDashboard";
import TabsList from "molecules/TabsList";

// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getClientid,
  getRoleList,
  getUserRole,
  getMenuList,
} from "redux/selectors";

function Dashboard({ userRoleId, menuList }) {
  const Menulist = menuList[userRoleId] || Object.values(menuList)[0] || menuList;

  let tabMenus = Menulist && Menulist.filter(
    (item) =>
      item.key === "dashboard" ||
      item.key === "managerdashboard" ||
      item.key === "hrdashboard"
  );

  let newArray = [];
  newArray.push(...tabMenus);

  const items =
    newArray &&
    newArray
      ?.filter((data) => data.access === true)
      .map((data, index) => ({
        id: index,
        label: data.subtitle,
        component:
          data.path === "dashboard" ? (
            <MyDashboard />
          ) : data.path === "managerdashboard" ? (
            <ManagerDashboard />
          ) : data.path === "hrdashboard" ? (
            <HrDashboard />
          ) : (
            <MyDashboard />
          ),
      }));

  return (
    <>
      <TabsList items={items} />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  userRoleType: getUserRole(state),
  menuList: getMenuList(state),
});

export default connect(mapStateToProps, null)(Dashboard);
