import React from 'react';
import { Row, Col } from 'antd';
import { Pie } from '@ant-design/plots';
import styled from '@emotion/styled';
import RoundIcon from 'atoms/RoundIcon';
import Icon from 'atoms/Icon';
import { last, map, get, first } from 'lodash';

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
  .styled-circle {
    height: 30px;
    width: 30px;
    svg {
      margin: 4px;
    }
  }
  .css-1ps7utq {
    margin-bottom: 0 !important;
  }
  .css-1180qxj{
    margin-bottom: 0 !important;
  }
  .css-1ig93pz{
    margin-bottom: 0 !important;
  }
  .report-title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 0.8rem;
    padding-top: 3px;
    padding-left:10px;
    text-transform: uppercase;
  }
  .report-title1 {
    font-weight: bold;
    font-size: 0.7rem;
    padding-top: 3px;
    /* padding-left:10px; */
  }
  .legends {
    padding-top: 2rem;
    padding-left: 5px;
    text-align: left;
    div {
      padding-bottom: 5px;
    }
  }
  .leave_tracker_header{
    display: flex;
    justify-content: space-between;
  }
`;

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  padding: 0px 8px;
  margin-right: 10px;
`

function LeaveTrackerChart({
    iconName,
    title,
    data,
    staffLeave
}) {
    const config = {
        width: 120,
        height: 120,
        appendPadding: 6,
        data,
        angleField: 'value',
        colorField: 'type',
        color: map(data, 'color', []),
        radius: 1,
        innerRadius: 0.8,
        tooltip: false,
        legend: false,
        label: false,
        statistic: {
            title: false,
            content: {
                style: {
                    // whiteSpace: 'pre-wrap',
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                    fontSize: 13
                },
                content: `${get(last(data), 'value', '')}/${get(first(data), 'value', '')}`,
            },
        },
    };
    return (
        <StyledChartContainer>
            <Row>
                {/* <Col span={4}>
                    <RoundIcon>
                        {iconName && <Icon name={iconName} />}
                    </RoundIcon>
                </Col> */}
                <Col span={20} className='report-title'>
                    <div className='leave_tracker_header'>
                        <p>{title}</p>
                        <p>Year:2023</p>
                    </div>
                </Col>
                <Col span={12}>
                    <Pie {...config} />
                </Col>
                <Col span={12} className='legends'>
                    <div>
                        <StyledColorBox color={get(last(data), 'color')}></StyledColorBox>
                        <span>
                            {get(last(data), 'type', '')} : {data[1].value == null ? "null" : get(last(data), 'value', '')}
                        </span>
                    </div>
                    <div>
                        <StyledColorBox color={get(first(data), 'color')}></StyledColorBox>
                        <span>
                            {get(first(data), 'type', '')} : {data[0].value == null ? "null" : get(first(data), 'value', '')}
                        </span>
                    </div>
                    {(staffLeave == "Annual Leave" || staffLeave == "Department's Annual Leave Balance") && (
                        <div className='report-title1'>
                            Last Year Leave Balance : <span style={{ color: 'blue', fontWeight: 'normal' }}>{get(first(data), 'lastYearLeaveBalance', '')}</span>
                        </div>
                    )}
                </Col>
            </Row>
        </StyledChartContainer>
    );
}

export default LeaveTrackerChart;
