import React from "react";
import { Layout, Menu, Skeleton, Badge, Drawer } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { size, times, map, intersection, get, filter, concat } from "lodash";
import userlogo from "../assets/tymeplus.png";
import _map from "lodash/map";
import logo from "../assets/owner.svg";
import COLORS from "common/Colors";
import Icon from "../atoms/Icon";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getLoadingDataFirstTime,
  getUnreadChatMessageList,
  getDepartmentUserList,
  getUserInfo,
  getUserList,
  getMenuList,
  getClientid,
  getRoleList,
  getUserRole
} from "redux/selectors";
import { DEVICE } from "common/Device";

const { Item, SubMenu } = Menu;

const StyledLogo = styled.div`
  height: 40px;
  width: 40px;
  margin: 5px 15px 20px;
  text-align: center;
  background: #e6f5ff 0% 0% no-repeat padding-box;
  img {
    margin-top: 5px;
  }
  @media ${DEVICE.tablet} {
    height: 25px;
    margin-bottom: 15px;
    img {
      height: 25px !important;
    }
  }
`;

const StyledMenuItem = styled(Item)`
  vertical-align: middle;
  border-radius: 10px;

  &:after {
    border-right: 3px solid
      ${(props) => (props.selected ? `#26A4FF` : `${COLORS.WHITE}`)} !important;
  }
  &.ant-menu-item-selected {
    background-color: ${(props) =>
    props.selected ? `#26A4FF` : "inherit"} !important;
    .anticon,
    a,
    svg {
      color: ${(props) =>
    props.selected ? `${COLORS.WHITE}` : `${COLORS.BLACK}`};
      fill: ${(props) =>
    props.selected ? `${COLORS.WHITE}` : `${COLORS.BLACK}`};
    }
  }
`;

const StyledMenuSkeletonContainer = styled.div`
  text-align: center;
  padding: 10px 20px;
  .ant-skeleton {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const Container = styled.div`
.ant-layout-sider-children{
  background: #E6F5FF 0% 0% no-repeat padding-box;
  margin: 0;
}
.ant-menu{
  font-size: 14px;
  background: #E6F5FF 0% 0% no-repeat padding-box;
  /* margin: 0px 10px 0px 10px; */
}
  overflow-y: auto;
  overflow-x: hidden;
  .sidemenu {
    display: none;
  }
  .hidemenu {
    display: flex;
    width: max-content;
    height: 100%;
    padding: 15px;
    background: #e6f5ff 0% 0% no-repeat padding-box;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-weight: bold;
      padding-left: 5%;
    }
  }
  .hidemenu1 {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-weight: bold;
      padding-left: 5%;
      margin-top: 10%;
    }
  }
  @media ${DEVICE.tablet} {
    .sidemenu {
      width: 150px;
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 110px;
      transition: 350ms;
      z-index: 20;
    }
    .hidemenu {
      display: none;
      padding: 10px;
    }
  }
`;

const StyledBadge = styled(Badge)`
  left: 10px;
  top: 4px;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .ant-badge-dot {
    width: 10px;
    height: 10px;
    animation: blinker 1s linear infinite;
  }
`;

const { Sider } = Layout;
const SideBar = ({
  defaultKey,
  activePage,
  collapsed,
  setPageTitle,
  userRoleId,
  isLoadingDataFirstTime,
  unreadChatMessageList,
  open,
  onClose,
  userInfo,
  userList,
  menuList,
  clientId,
}) => {


  let Menulist = menuList[userRoleId] || Object.values(menuList)[0] || menuList;

  Menulist = Array.isArray(Menulist) ? Menulist : [];

  const filtermenulist = (Menulist && Menulist.filter(
    (data) =>
      data.key !== "managerdashboard" &&
      data.key !== "hrdashboard" &&
      data.key !== "teamcalendar" &&
      data.key !== "allcalendar"
  )) || [];


  const menuSkeleton = (key) => (
    <Skeleton.Input block={true} active={true} size="small" key={key} />
  );
  const location = useLocation();

  const unreadDot = () => {
    var hrUser = filter(userList, { roleid: 1 });

    if (userRoleId == 2) {
      let result = map(
        concat(
          filter(userList, {
            departmentid: get(userInfo, "staff_department_id", ""),
          }),
          hrUser
        ),
        (e) => e.userid
      );
      result = intersection(result, unreadChatMessageList);
      return size(result) ? true : false;
    }
    return size(unreadChatMessageList) ? true : false;
  };

  const logoStyle = {
    height: collapsed ? "25px" : "45px",
    marginTop: collapsed ? "15px" : "5px",
  };

  const containerStyle = {
    height: "100vh",
    background: "#E6F5FF",
  };

  const drawerStyle = {
    padding: "0px",
  };
  const clientStyle = collapsed ? { fontSize: "8px" } : { fontSize: "12px" };

  return (
    <Container style={containerStyle}>
      <div className="hidemenu">
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <Link to={`dashboard`} onClick={() => setPageTitle("")}>
            <StyledLogo>
              <img
                style={logoStyle}
                src={userRoleId == 1 ? logo : userlogo}
                alt="Tymeplus"
              />
            </StyledLogo>
          </Link>
          {isLoadingDataFirstTime && (
            <StyledMenuSkeletonContainer>
              {times(10, (ind) => menuSkeleton(ind))}
            </StyledMenuSkeletonContainer>
          )}

          {!isLoadingDataFirstTime && (
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={[activePage]}
              defaultOpenKeys={[defaultKey]}
            >
              {_map(
                filtermenulist?.filter(
                  (menu) => menu?.access && menu.icon !== ""
                ),
                (menu) => {
                  return menu?.subMenu && menu?.subMenu?.length > 0 ? (
                    <SubMenu
                      key={menu?.key}
                      title={menu?.title}
                      icon={menu?.icon && <Icon name={menu?.icon} />}
                    >
                      {_map(
                        menu?.subMenu.filter((submenu) => submenu?.access),
                        (submenu) => {
                          return submenu?.subMenu &&
                            submenu?.subMenu?.length > 0 ? (
                            <SubMenu
                              key={submenu?.key}
                              icon={
                                submenu?.icon && <Icon name={submenu?.icon} />
                              }
                              title={submenu?.title}
                            >
                              {_map(
                                submenu?.subMenu.filter(
                                  (submenu) => submenu?.access
                                ),
                                (submenu) => {
                                  return (
                                    <StyledMenuItem
                                      collapsed={collapsed ? "true" : "false"}
                                      key={submenu?.key}
                                      icon={
                                        submenu?.icon && (
                                          <Icon name={submenu?.icon} />
                                        )
                                      }
                                      onClick={() =>
                                        setPageTitle(submenu?.title)
                                      }
                                      selected={
                                        location?.pathname.substr(1) == ""
                                          ? false
                                          : true
                                      }
                                    >
                                      <Link to={`/${submenu.path}`}>
                                        {submenu?.title}
                                      </Link>
                                      <StyledBadge
                                        dot={
                                          menu?.path === "team" && unreadDot()
                                        }
                                      />
                                    </StyledMenuItem>
                                  );
                                }
                              )}
                            </SubMenu>
                          ) : (
                            <StyledMenuItem
                              collapsed={collapsed ? "true" : "false"}
                              key={submenu?.key}
                              icon={
                                submenu?.icon && <Icon name={submenu?.icon} />
                              }
                              onClick={() => setPageTitle(submenu?.title)}
                              selected={
                                location?.pathname.substr(1) == ""
                                  ? false
                                  : true
                              }
                            >
                              <Link to={`/${submenu?.path}`}>
                                {submenu?.title}
                              </Link>
                              <StyledBadge
                                dot={menu?.path === "team" && unreadDot()}
                              />
                            </StyledMenuItem>
                          );
                        }
                      )}
                    </SubMenu>
                  ) : (
                    <StyledMenuItem
                      key={menu?.key}
                      icon={menu?.icon && <Icon name={menu?.icon} />}
                      onClick={() => setPageTitle(menu?.title)}
                      selected={
                        location?.pathname.substr(1) == "" ? false : true
                      }
                    >
                      <Link to={`/${menu?.path}`}>
                        {menu?.key === "mycalendar" ? "Calender" : menu?.key === "dashboard" ? "Dashboard" : menu?.title}
                      </Link>
                      <StyledBadge dot={menu?.path === "team" && unreadDot()} />
                    </StyledMenuItem>
                  );
                }
              )}
            </Menu>
          )}
        </Sider>
        <p style={clientStyle}>Client ID : {clientId}</p>
      </div>
      <div>
        <Drawer
          bodyStyle={drawerStyle}
          width={240}
          title={
            <>
              <Link to={`dashboard`}>
                <img
                  src={userRoleId == 1 ? logo : userlogo}
                  width={120}
                  alt="Tymeplus"
                />
              </Link>
            </>
          }
          placement="left"
          onClose={onClose}
          open={open}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[activePage]}
            defaultOpenKeys={[defaultKey]}
          >
            {_map(
              filtermenulist?.filter((menu) => menu?.access && menu.icon !== ""),
              (menu) => {
                return menu?.subMenu && menu?.subMenu?.length > 0 ? (
                  <SubMenu
                    key={menu?.key}
                    icon={menu?.icon && <Icon name={menu?.icon} />}
                    title={menu?.title}
                  >
                    {_map(
                      menu?.subMenu.filter((submenu) => submenu?.access),
                      (submenu) => {
                        return submenu?.subMenu &&
                          submenu?.subMenu?.length > 0 ? (
                          <SubMenu
                            key={submenu?.key}
                            icon={
                              submenu?.icon && <Icon name={submenu?.icon} />
                            }
                            title={submenu?.title}
                          >
                            {_map(
                              submenu?.subMenu.filter(
                                (submenu) => submenu?.access
                              ),
                              (submenu) => {
                                return (
                                  <StyledMenuItem
                                    collapsed={collapsed ? "true" : "false"}
                                    key={submenu?.key}
                                    icon={
                                      submenu?.icon && (
                                        <Icon name={submenu?.icon} />
                                      )
                                    }
                                    onClick={() => {
                                      setPageTitle(submenu?.title);
                                      onClose();
                                    }}
                                    selected={
                                      location.pathname.substr(1) == ""
                                        ? false
                                        : true
                                    }
                                  >
                                    <Link to={`/${submenu.path}`}>
                                      {submenu?.title}
                                    </Link>
                                    <StyledBadge
                                      dot={menu?.path === "team" && unreadDot()}
                                    />
                                  </StyledMenuItem>
                                );
                              }
                            )}
                          </SubMenu>
                        ) : (
                          <StyledMenuItem
                            collapsed={collapsed ? "true" : "false"}
                            key={submenu?.key}
                            icon={
                              submenu?.icon && <Icon name={submenu?.icon} />
                            }
                            onClick={() => {
                              setPageTitle(submenu?.title);
                              onClose();
                            }}
                            selected={
                              location?.pathname.substr(1) == "" ? false : true
                            }
                          >
                            <Link to={`/${submenu?.path}`}>
                              {submenu?.title}
                            </Link>
                            <StyledBadge
                              dot={menu?.path === "team" && unreadDot()}
                            />
                          </StyledMenuItem>
                        );
                      }
                    )}
                  </SubMenu>
                ) : (
                  <>

                    <StyledMenuItem
                      key={menu?.key}
                      icon={menu?.icon && <Icon name={menu?.icon} />}
                      onClick={() => {
                        setPageTitle(menu?.title);
                        onClose();
                      }}
                      selected={
                        location?.pathname.substr(1) === "" ? false : true
                      }
                    >
                      <Link to={`/${menu?.path}`}>{menu?.title}</Link>
                      <StyledBadge
                        dot={
                          menu?.path === "team" && size(unreadChatMessageList)
                            ? true
                            : false
                        }
                      />
                    </StyledMenuItem>

                  </>
                );
              }
            )}
          </Menu>
        </Drawer>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
  departmentUserList: getDepartmentUserList(state),
  userInfo: getUserInfo(state),
  userList: getUserList(state),
  menuList: getMenuList(state),
  clientId: getClientid(state),
  roleList: getRoleList(state),
  UserRoleId: getUserRoleId(state),
  roleId: getUserRoleId(state),
  role: getUserRole(state)
});

export default connect(mapStateToProps, null)(SideBar);
