import React from "react";
import Icon from "atoms/Icon";
import { ReadyState } from "react-use-websocket";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import crm from 'assets/productlogo/crm.svg'
import tymeplus from 'assets/productlogo/tymeplus.svg';
import tractask from 'assets/productlogo/tractask.svg';
import myaccount from 'assets/productlogo/myaccount.svg'


const ORIGIN = window.location.origin;


const LIVE_SOCKET = `wss://vpvx968kr2.execute-api.ap-southeast-1.amazonaws.com/Prod/`;
const TEST_SOCKET = `wss://vpvx968kr2.execute-api.ap-southeast-1.amazonaws.com/Prod/`;
const DEV_SOCKET = `wss://vpvx968kr2.execute-api.ap-southeast-1.amazonaws.com/Prod/`;
export const SERVICE_URL = `https://cgkrpxojv6.execute-api.ap-southeast-1.amazonaws.com/Prod/`;
export const AI_BASE_URL = `https://mvff9fjrsc.execute-api.ap-south-1.amazonaws.com/dev/`;



export const SOCKET_URL =
  ORIGIN.indexOf("localhost") > -1
    ? TEST_SOCKET
    : ORIGIN.indexOf("test") > -1
      ? TEST_SOCKET
      : LIVE_SOCKET;


export const Products = [
  {
    name: "Tymeplus",
    url: "",
    logo: tymeplus,
    access: true
  },
  {
    name: "TracTask",
    url: `https://www.tractask.waila.co/?token=${window.localStorage.getItem('token')}`,
    logo: tractask,
    access: true
  },
  {
    name: "Marketing Tool",
    url: `https://www.crm.waila.co/?token=${window.localStorage.getItem('token')}`,
    logo: crm,
    access: true
  },
  {
    name: "My Account",
    url: `https://www.myaccount.waila.co/?token=${window.localStorage.getItem('token')}`,
    logo: myaccount,
    access: true
  },

]

export const NON_EMPTY_REGEX = /^(?!\s*$).+/;

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const NON_EMPTY_SINGLE_NUMBER = /\b[0-9]\b/;

export const NON_EMPTY_NUMBER = /^\d+$/;

export const NON_CHARACTER = /^[0-9\.\-\+]+$/;

//Validate symbol except space
export const VALIDATE_SYMBOL = /^[^<>*|/\\~#{}()[\],$^%&+=_@'?.!"]+$/g;

export const PUNCH_ACTIONS = {
  PUNCH_IN: 1,
  PUNCH_OUT: 2,
  BREAK_START: 3,
  BREAK_END: 4,
};

export const STATUS = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  ERROR: "ERROR",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
};

const dashboardMenu = {
  key: "dashboard",
  icon: <Icon name="dashboard" />,
  path: "dashboard",
  title: "Dashboard",
};

const adminDashboardMenu = {
  key: "dashboard",
  icon: <Icon name="dashboard" />,
  title: "Dashboard",
  subMenu: [
    {
      key: "dashboard",
      path: "dashboard",
      title: "Dashboard",
      icon: <Icon name="subdashboard" />,
    },
    {
      key: "trends",
      path: "trends",
      title: "Trends",
      icon: <Icon name="trends" />,
    },
    {
      key: "demographics",
      path: "demographics",
      title: "Demographics",
      icon: <Icon name="demographics" />,
    },
    {
      key: "productivity",
      path: "productivity",
      title: "Productivity",
      icon: <SettingsApplicationsIcon style={{ fontSize: "20px" }} />,
    },
  ],
};

const approvalMenu = {
  key: "approvals",
  icon: <Icon name="approvals" />,
  path: "approvals",
  title: "Approvals",
};

const calendarMenu = {
  key: "calendar",
  icon: <Icon name="calendar" />,
  path: "calendar",
  title: "Calendar",
};

const locationMenu = {
  key: "location",
  icon: <Icon name="location" />,
  path: "location",
  title: "Location",
};

const myCalendarMenu = {
  key: "calendar",
  icon: <Icon name="calendar" />,
  path: "calendar",
  title: "My Calendar",
};

const teamMenu = {
  key: "team",
  icon: <Icon name="team" />,
  path: "team",
  title: "Teams",
};

const myTeamMenu = {
  key: "team",
  icon: <Icon name="team" />,
  path: "team",
  title: "My Team",
};

const myDocumentMenu = {
  key: "mydocuments",
  icon: <Icon name="document" />,
  path: "mydocuments",
  title: "My Documents",
};

const taskMenu = {
  key: "task",
  icon: <Icon name="task" />,
  path: "task",
  title: "Task",
};

const myTaskMenu = {
  key: "mytask",
  icon: <Icon name="task" />,
  path: "mytask",
  title: "My Task",
};

const myLeaveReport = {
  key: "leavereport",
  icon: <Icon name="leave" />,
  path: "myleavereport",
  title: "My Leave History",
};

const reportMenu = {
  key: "reports",
  icon: <Icon name="report" />,
  title: "Reports",
  subMenu: [
    {
      key: "attendance-report",
      title: "Attendance",
      icon: <Icon name="attendance" />,
      subMenu: [
        {
          key: "attendance-report-daily",
          path: "attendance-report",
          title: "Daily",
          icon: <DoneAllIcon style={{ fontSize: "20px" }} />,
        },
        {
          key: "attendance-report-monthly",
          path: "attendance-report-monthly",
          title: "Monthly",
          icon: <CalendarMonthIcon style={{ fontSize: "20px" }} />,
        },
      ],
    },
    {
      key: "leave-report",
      path: "leave-report",
      title: "Leave",
      icon: <Icon name="leave" />,
    },
    {
      key: "break-report",
      path: "break-report",
      title: "Break",
      icon: <Icon name="break" />,
    },
    {
      key: "overtime-report",
      path: "overtime-report",
      title: "Overtime",
      icon: <Icon name="overtime" />,
    },
  ],
};

const ExceptionReport = {
  key: "Exception Reports",
  icon: <Icon name="exceptionreport" />,
  title: "Exception Reports",
  subMenu: [
    {
      key: "Late check-in",
      path: "Late-check-in",
      title: "Late check-in",
      icon: <Icon name="latecheckin" />,
    },
    {
      key: "Early check-out",
      path: "Early-check-out",
      title: "Early check-out",
      icon: <Icon name="earlycheckout" />,
    },
    {
      key: "Absent report",
      path: "Absent-report",
      title: "Absent report",
      icon: <Icon name="absentreport" />,
    },
  ],
};

const employeeMenu = {
  key: "employee",
  icon: <Icon name="employee" />,
  path: "employee",
  title: "Employee",
};

const departmentMenu = {
  key: "department",
  icon: <Icon name="department" />,
  path: "department",
  title: "Department",
};

const roleMenu = {
  key: "role",
  icon: <Icon name="role" />,
  path: "roles",
  title: "Role",
};
const shiftMenu = {
  key: "shift",
  icon: <Icon name="shift" />,
  path: "shift",
  title: "Shift",
};

export const HR_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  reportMenu,
  ExceptionReport,
];

export const CEO_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  reportMenu,
  ExceptionReport,
];

export const HOD_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  taskMenu,
  reportMenu,
  ExceptionReport,
];

export const STAFF_MENU = [
  dashboardMenu,
  myCalendarMenu,
  myTeamMenu,
  myDocumentMenu,
  myTaskMenu,
  myLeaveReport,
];

export const ADMIN = [
  adminDashboardMenu,
  departmentMenu,
  locationMenu,
  employeeMenu,
  roleMenu,
  shiftMenu,
];

export const MENU = {
  1: HR_MENU,
  2: HOD_MENU,
  3: HOD_MENU,
  4: STAFF_MENU,
  5: ADMIN,
  6: CEO_MENU,
};

export const STATUS_CODE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INVALID_PAYLOAD: "INVALID_PAYLOAD",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  AUTHENTICATION_SUCCESSFUL: "AUTHENTICATION_SUCCESSFUL",
  USER_DISABLED: "USER_DISABLED",
  VALID_SESSION: "VALID_SESSION",
  SAME_PASSWORD: "SAME_PASSWORD",
  INVALID_CODE: "INVALID_CODE",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
  INVALID_SESSION: "INVALID_SESSION",
  PASSWORD_UPDATED: "PASSWORD_UPDATED",
  RECORD_EXIST: "RECORD_EXIST",
  OUTSIDE_ALLOWED_PROXIMITY: "OUTSIDE_ALLOWED_PROXIMITY",
  PUNCH_IN_SUCCESSFUL: "PUNCH_IN_SUCCESSFUL",
  PUNCH_OUT_SUCCESSFUL: "PUNCH_OUT_SUCCESSFUL",
  BREAK_STARTED: "BREAK_STARTED",
  BREAK_ENDED: "BREAK_ENDED",
  SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
  TASK_SAVED: "TASK_SAVED",
  TASK_DELETED: "TASK_DELETED",
  LEAVE_EXCEED: "LEAVE_EXCEED",
  ANNUAL_LEAVE_AVAILABLE: "ANNUAL_LEAVE_AVAILABLE",
  SICK_LEAVE_AVAILABLE: "SICK_LEAVE_AVAILABLE",
  REASON: "SEND_REASON",
};

export const LEAVE_INFO = {
  AVAILABLE: "Available",
  CONSUMED: "Consumed",
};

export const USER_REPORT = {
  ONTIME: "OnTime",
  LATE: "Late",
  LEAVE: "Leave",
  EARLYEXIT: "Early Exit",
  ABSENT: "Absent",
};

export const LEAVE_ICON = {
  7: "calendar2",
  1: "medical_box",
  2: "medical_box",
  3: "medical_box",
  5: "pregnant_woman",
  6: "baby_carrier",
};

export const REPORT_DURATION_TYPES = [
  { value: 2, label: "Monthly Report" },
  { value: 1, label: "Custom Date" },
];

export const REPORT_DURATION_TYPES1 = [{ value: 2, label: "Monthly Report" }];

export const APPROVAL_FILTER_TYPES = [
  { value: 4, label: "All" },
  { value: 1, label: "Custom Date" },
  { value: 2, label: "Monthly" },
];

export const CHECKIN_REPORT_FILTER_TYPES = [
  { value: 1, label: "Custom Date" },
  { value: 2, label: "Monthly" },
];
export const MONTH_LIST = [
  { index: 1, value: "January", label: "January" },
  { index: 2, value: "February", label: "February" },
  { index: 3, value: "March", label: "March" },
  { index: 4, value: "April", label: "April" },
  { index: 5, value: "May", label: "May" },
  { index: 6, value: "June", label: "June" },
  { index: 7, value: "July", label: "July" },
  { index: 8, value: "August", label: "August" },
  { index: 9, value: "September", label: "September" },
  { index: 10, value: "October", label: "October" },
  { index: 11, value: "November", label: "November" },
  { index: 12, value: "December", label: "December" },
];

export const WEB_SOCKET_CONNECTION_STATUS = {
  [ReadyState.CONNECTING]: "Connecting",
  [ReadyState.OPEN]: "Open",
  [ReadyState.CLOSING]: "Closing",
  [ReadyState.CLOSED]: "Closed",
  [ReadyState.UNINSTANTIATED]: "Uninstantiated",
};

export const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Cote D'ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Eswatini",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (ROC)",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]
