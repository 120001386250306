export const getEditRoleJson = (data) => {
  const EditRoleForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Role Name",
              type: "text",
              name: "roleName",
              Placeholder: "Role Name",
              value: data["roleName"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Role Name`,
                },
              ],
            },
          ],
        ],
      },
    ],
  };

  return {
    EditRoleForm,
  };
};
