import React from "react";
import { Form, Select, Input, DatePicker, Checkbox, Radio, Spin } from "antd";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Button from "atoms/Button";
import EditorConvertToHTML from "atoms/EditorConvertToHTML";

const { RangePicker } = DatePicker;

function UIElement({
  label,
  type,
  options,
  name,
  position,
  background,
  textColor,
  disabled,
  row,
  Placeholder,
  maxLength,
  handleChange,
  required,
  value,
  content,
  readOnly,
  loading,
  event,
  handleClick,
  validationRules,
  handleForgotPassword,
  onKeyDown,
  suffixUrl,
  domainStatus,
  urlLoader,
  link,
  form,
  handleSelectChange,
}) {
  const labelCol = { span: 24 }; // Label takes full width
  const wrapperCol = { span: 24 }; // Input takes full width

  const btnStyle = {
    background: background,
    color: textColor,
    minWidth: "200px",
  };
  const divStyle = {
    background: background,
    color: textColor,
  };
  return (
    <div>
      {type === "label" && <p>{label}</p>}
      {readOnly && (
        <Form.Item label={label}>
          {value ? <strong>{value}</strong> : <Spin />}
        </Form.Item>
      )}
      {type === "select" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Select
            placeholder={Placeholder}
            options={options.map((item) => ({
              value: item,
              label: item,
            }))}
            showSearch={true}
            value={value}
            onChange={(e) => handleSelectChange(e, name)}
          />
        </Form.Item>
      )}
      {type === "selectwithvalue" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Select
            placeholder={Placeholder}
            options={options.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
            showSearch={true}
            value={value}
            onChange={(e) => handleSelectChange(e, name)}
          />
        </Form.Item>
      )}
      {(type === "text" ||
        type === "email" ||
        type === "number" ||
        type === "file") &&
        !readOnly && (
          <Form.Item
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            name={name}
            rules={validationRules}
          >
            <Input
              disabled={disabled}
              placeholder={Placeholder}
              type={type}
              name={name}
              maxLength={maxLength}
              onChange={handleChange}
              value={value}
              onKeyDown={(e) => {
                const value = onKeyDown?.test(e.key);
                if (!value && e.key !== "Backspace" && onKeyDown) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        )}
      {type === "textarea" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Input.TextArea
            disabled={disabled}
            placeholder={Placeholder}
            rows={row ? row : 3}
            name={name}
            onChange={handleChange}
            value={value}
            onKeyDown={(e) => {
              const value = onKeyDown?.test(e.key);
              if (!value && e.key !== "Backspace" && onKeyDown) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )}
      {type === "password" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Input.Password
            disabled={disabled}
            placeholder={Placeholder}
            name={name}
            onChange={handleChange}
            value={value}
            onKeyDown={(e) => {
              const value = onKeyDown?.test(e.key);
              if (!value && e.key !== "Backspace" && onKeyDown) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )}
      {type === "url" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
          validateStatus={domainStatus}
        >
          <Input
            placeholder={Placeholder}
            name={name}
            addonBefore={
              urlLoader ? (
                <LoadingOutlined spin />
              ) : domainStatus === "error" ? (
                <ErrorIcon />
              ) : domainStatus === "success" ? (
                <CheckCircleIcon />
              ) : (
                <ErrorOutlineIcon />
              )
            }
            addonAfter={suffixUrl}
            onChange={handleChange}
            status={domainStatus}
            onKeyDown={(e) => {
              const value = onKeyDown?.test(e.key);
              if (!value && e.key !== "Backspace" && onKeyDown) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )}
      {type === "date" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <DatePicker
            style={{ width: "100%" }}
            disabled={disabled}
            placeholder={Placeholder}
            onChange={(date, dateString) =>
              handleChange({ target: { value: date, name: name } })
            }
            value={value ? moment(value, "YYYY-MM-DD") : null}
          />
        </Form.Item>
      )}
      {type === "daterange" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <RangePicker
            style={{ width: "100%" }}
            disabled={disabled}
            placeholder={Placeholder}
            onChange={(dates, dateStrings) =>
              handleChange({ target: { value: dateStrings, name: name } })
            }
            value={
              value
                ? [
                    moment(value[0], "YYYY-MM-DD"),
                    moment(value[1], "YYYY-MM-DD"),
                  ]
                : null
            }
            format="YYYY-MM-DD"
          />
        </Form.Item>
      )}
      {type === "checkbox" && !readOnly && (
        <Form.Item
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          valuePropName="checked"
          rules={validationRules}
        >
          <Checkbox disabled={disabled} onChange={handleChange}>
            {link ? (
              <Link to={link} target="_blank" rel="noopener noreferrer">
                {label}
              </Link>
            ) : (
              label
            )}
          </Checkbox>
        </Form.Item>
      )}
      {type === "radio" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Radio.Group onChange={handleChange} value={value} name={name}>
            {options?.map((option, index) => (
              <Radio key={index} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      )}

      {type === "button" && !readOnly && event === "submit" && (
        <div className={position}>
          <Button
            htmlType="submit"
            type="primary"
            disabled={disabled || loading || domainStatus === "error"}
            style={btnStyle}
          >
            {loading ? (
              <Spinner animation="border" size="sm" /> // Display a spinner when loading
            ) : (
              label
            )}
          </Button>
        </div>
      )}

      {type === "button" && !readOnly && event === "click" && (
        <div className={position}>
          <Button
            disabled={disabled}
            onClick={() => handleClick()}
            type="primary"
            style={btnStyle}
          >
            {label}
          </Button>
        </div>
      )}

      {type === "div" && !readOnly && (
        <div style={divStyle} className={position}>
          <a onClick={event === "click" ? () => handleForgotPassword() : ""}>
            {content}
          </a>
        </div>
      )}

      {(type === "" || type === undefined) && (
        <Form.Item label={label}></Form.Item>
      )}

      {type === "editor" && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <EditorConvertToHTML
            onChange={(val) => {
              form.setFieldsValue({ [name]: val });
            }}
            content={value}
          />
        </Form.Item>
      )}
    </div>
  );
}

export default UIElement;
