import React, { useEffect, useState } from "react";
import { Row, Col, Button, Table } from "antd";
import COLORS from "common/Colors";
import styled from "@emotion/styled";
import Addband from "../Band/AddBand";
import { CALL_API } from "common/API";
import { connect } from "react-redux";
import { getAllDepartmentList, getClientid } from "redux/selectors";
import Editband from "./Editband";

const StyledButton = styled(Button)`
  width: 150px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;
const StyledTable = styled(Table)`
  padding-top: 20px;

  table {
    table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    padding: 10px;
    height: 0px !important;
  }
  .ant-table-thead > tr > th {
    background-color: #f2f2f2;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .edit_img {
    width: 22px;
    height: 22px;
  }
  .ant-table-row .employee-count-container {
    display: flex;
    align-items: center;
  }
  .ant-table-row .employee-count-container span {
    margin-right: 8px;
  }
  .ant-table-row .employee-count-container p {
    margin: 0;
    text-decoration: underline;
    color: #26a4ff;
  }
`;

const Band = ({ clientId }) => {
  const [totaldata, setTotalData] = useState([]);
  const [showBandForm, setShowBandForm] = useState(false);
  const [band, setToggleBand] = useState(false);
  const [bandDetails, setBandDetails] = useState({});
  const handleAddNewClick = () => {
    setShowBandForm(true);
  };
  const columns = [
    {
      title: "Band Type",
      dataIndex: "bandtype",
      key: "bandtype",
      width: "15%",
    },
    {
      title: "Salary Range",
      dataIndex: "salary_range",
      key: "salary_range",
      width: "15%",
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
      width: "15%",
    },
    {
      title: "Employee count",
      dataIndex: "noOfEmployee",
      key: "noOfEmployee",
      width: "15%",
      render: (employeeCount, record) => (
        <div className="employee-count-container">
          <span>{employeeCount}</span>
        </div>
      ),
    },
  ];
  const fetchData = async () => {
    try {
      const res = await CALL_API(`band/${clientId}`, "get");
      const transformedData = res.map((item) => {
        const experienceFrom = new Date(item.experienceFrom);
        const experienceTo = new Date(item.experienceTo);
        const yearsDifference =
          experienceTo.getUTCFullYear() - experienceFrom.getUTCFullYear();
        const formattedExperience =
          yearsDifference >= 1
            ? `${yearsDifference}-${yearsDifference + 1} years`
            : "-";
        return {
          ...item,
          bandtype: item.bandName,
          salary_range: `$${item.salaryFrom} - $${item.salaryTo}`,
          band_id: item.bandId,
          experience: formattedExperience,
        };
      });
      setTotalData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const ViewBand = (bandObject, isViewOnly = false) => {
    setToggleBand(true);
    setBandDetails({
      ...bandObject,
      isViewOnly,
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {!band && (
        <>
          {showBandForm ? (
            <Addband
              onClose={() => setShowBandForm(false)}
              fetchData={fetchData}
              totaldata={totaldata}
              setTotalData={setTotalData}
              showBandForm={showBandForm}
            />
          ) : (
            <>
              <Row className="page-title">
                <Col
                  className="head_col"
                  span={12}
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    display: "flex",
                  }}
                >
                  {/* /-----------------------Department selector---(add if needed)-----------------------/ */}
                  {/* <div style={{ paddingLeft: "30px" }}>
                  <StyledSelect
                    className="selectbox"
                    placeholder="Accountant"
                    // defaultValue={""}
                    displayEmpty
                    value={selectedDepartment}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="" disabled>
                      Accountant
                    </MenuItem>
                    {staticOptions.map((option) => (
                      <MenuItem
                        key={option.department_id}
                        value={option.department_id}
                      >
                        {option.department_name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </div> */}
                  {/* /----------------------------------------------------------------------------/ */}
                </Col>
                <Col
                  span={12}
                  className="align-right"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                    onClick={handleAddNewClick}
                  >
                    Add New
                  </StyledButton>
                </Col>
              </Row>
              <StyledTable
                columns={columns}
                dataSource={totaldata}
                rowKey="band_id"
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      ViewBand(record);
                    },
                  };
                }}
                pagination={false}
              />
            </>
          )}
        </>
      )}
      {band && (
        <Editband
          isModalVisible={band}
          closeModal={() => setToggleBand(false)}
          bandDetails={bandDetails}
          fetchData={fetchData}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  departmentList: getAllDepartmentList(state),
  clientId: getClientid(state),
});
export default connect(mapStateToProps)(Band);
