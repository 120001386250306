import React, { useEffect, useState } from "react";
import { Row, Col, Menu, Card, Avatar, Tooltip, Dropdown } from "antd";
import { connect } from "react-redux";
import {
  getUserName,
  getUserRoleId,
  getUnreadChatMessageList,
} from "redux/selectors";
import styled from "@emotion/styled";
import { startCase } from "lodash";
import COLORS from "common/Colors";
import ViewMore from "organisms/ViewMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0px;
  }
  .first_row_styl {
    display: flex;
  }
  .threedot {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  .StyledImage {
    background-color: transparent;
    vertical-align: middle;
  }
  .employee-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 12px 16px 12px;
    background-color: white;
    border: 1px solid #000000;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .chat-container {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: ${COLORS.PRIMARY};
      translate: 50% -50%;
      .chat-icon {
        translate: 20px 55px;
      }
    }
    .icon {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      font-size: 16px;
      font-weight: 600;
      margin-top: 4px;
    }
    .department {
      font-size: 14px;
      margin-top: 8px;
      place-self: flex-start;

      .bold {
        font-weight: 600;
      }
    }
    .position {
      font-size: 14px;
      font-weight: 400;
      place-self: flex-start;

      .bold {
        font-weight: 600;
      }
    }
  }
`;
const StyledAvatar = styled(Avatar)`
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  font-size: 3rem;
  height: 140px;
  width: 140px;
  text-align: center;
  margin-top: 10px;
  .ant-avatar-string {
    margin-top: 3rem;
  }
`;
const StyledViewMenu = styled.div`
  width: 100px;
  cursor: pointer;
`;

function EmployeeCard({
  id,
  name,
  Department,
  employeeid,
  ViewEmployee,
  data,
  deletedEmployee,
  profile,
  employeeType,
  rolename,
}) {
  const [updateProfile, setUpdatedProfile] = useState("");
  const menu = (
    <StyledViewMenu>
      <Menu>
        <Menu.Item
          key="edit"
          onClick={({ key, domEvent }) => {
            if (key === "edit") {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              ViewEmployee(data);
            }
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="terminate"
          onClick={({ domEvent }) => {
            domEvent.preventDefault();
            domEvent.stopPropagation();
            deletedEmployee(id);
          }}
        >
          Terminate
        </Menu.Item>
      </Menu>
    </StyledViewMenu>
  );
  useEffect(() => {
    setUpdatedProfile(profile);
  }, [updateProfile]);
  return (
    <StyledCard>
      <div className="employee-card">
        <Row>
          {employeeType === "1" && (
            <Col className="threedot">
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
              >
                <MoreVertIcon />
              </Dropdown>
            </Col>
          )}
        </Row>
        {profile === null ||
          profile === "" ||
          profile === undefined ||
          profile == "null" ? (
          <StyledAvatar size="default">
            {startCase(name?.charAt(0))}
          </StyledAvatar>
        ) : (
          <StyledAvatar
            src={profile}
            className="StyledImage"
            size="default"
          ></StyledAvatar>
        )}
        <div className="name">
          {" "}
          <Tooltip title={name}>{name}</Tooltip>
        </div>
        <div className="position">
          <span className="bold">Employee ID</span>: {employeeid || "-"}
        </div>
        <div className="position">
          <span className="bold">Job Title</span>:{" "}
          {data?.jobtitle}
        </div>
        <div className="position">
          <span className="bold">Email Id</span>:{" "}
          <>{<ViewMore value={data?.userid} />}</>
        </div>
      </div>
    </StyledCard>
  );
}
const mapStateToProps = (state) => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
});

export default connect(mapStateToProps, null)(EmployeeCard);
