import React, { useState, useEffect, useRef } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

const DrawerContainer = styled.div`
  background: #e6f5ff;
  min-height: 250px; /* Adjust the height as needed */
  width: 50%;
  position: fixed;
  z-index: 1900;
  left: 25%;
  bottom: 0px;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  animation: ${(props) =>
    props.showSwitchApp
      ? "slideIn 0.5s ease-in-out"
      : "slideOut 0.5s ease-in-out"};
  transform-origin: bottom;
  @keyframes slideIn {
    from {
      transform: scaleY(0); /* Start from fully collapsed */
    }
    to {
      transform: scaleY(1); /* Expand to full height */
    }
  }

  @keyframes slideOut {
    from {
      transform: scaleY(1); /* Start from full height */
    }
    to {
      transform: scaleY(0); /* Collapse to fully hidden */
    }
  }

  .products {
    display: flex;
    padding-top: 5%;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 5%;
    flex-wrap: wrap;
    .items {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 5px;
      transition: opacity 0.5s ease-in-out;
      cursor: pointer;

      &.aos-animate {
        opacity: 1;
      }

      .logo {
        width: 80px;
        height: 80px;
        border-radius: 20px;
        /* background: #26A4FF;  */
        img {
          margin-top: 15px;
          width: 80%;
          height: 90%;
          object-fit: cover;
        }
      }
      h6 {
        margin-top: 0px;
        font-size: 12px;
      }
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    left: 0;
  }
`;

const StyledDiv = styled.div`
  width: 50%;
  z-index: 2000;
  position: fixed;
  bottom: 0px;
  left: 25%;
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  /* background:white;  */
  background: #e6f5ff;
  border-radius: 20px 20px 0px 0px;
  border: 2px solid rgba(255, 255, 255, 0.907);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  .bottom-nav-inner {
    margin-top: 20px;
    background: white;
    height: 40px;
    width: 100%;
    z-index: 2000;
    bottom: 0;
    display: flex;
    justify-content: center;
    .slider {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0px;
      background-color: #e6f5ff;
      transition: 1s;
      border-radius: 50%;
    }

    .slider::before {
      content: "";
      position: absolute;
      top: 38%;
      left: -18px;
      width: 21px;
      height: 20px;
      background: white;
      border-top-right-radius: 21px;
      box-shadow: 0px -10px 0 0 #e6f5ff;
    }

    .slider::after {
      content: "";
      position: absolute;
      top: 38%;
      right: -18px;
      width: 21px;
      height: 21px;
      /* background: red; */
      border-top-left-radius: 21px;
      box-shadow: 0px -10px 0 0 #e6f5ff;
    }
    li {
      margin-top: -10px;
      a {
        z-index: 2000;
        width: 75px;
        height: 25px;
        color: #822b61;
        text-align: center;
        font-size: 20px;
        display: block;
        transition: 1s;
        position: relative;

        i {
          width: 100%;
          position: absolute;
          left: 0;
          transition-delay: 0.3s;
          transition: 1s cubic-bezier(0.49, -0.35, 0.77, 1.44);
        }

        &.active-icon {
          i {
            top: -100%;
            transition-delay: 0.3s;
            transition: 1s cubic-bezier(0.49, -0.35, 0.77, 1.44);
          }

          .icon-btn {
            top: -100%;
            z-index: 2000;
            transition-delay: 0.3s;
            transition: 1s cubic-bezier(0.49, -0.35, 0.77, 1.44);
            /* background: orange; */
            border-radius: 50%;
            background: #26a4ff;
            color: white;
          }
        }
      }
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    left: 0;
  }
`;

function SwitchAppFooter({ products }) {
  const [showSwitchApp, setShowSwitchApp] = useState(false);
  const containerRef = useRef();

  const handleClickOutside = (event) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target) &&
      showSwitchApp
    ) {
      setShowSwitchApp(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSwitchApp]);

  return (
    <div className="my-custom-class" ref={containerRef}>
      <StyledDiv className="bottom-nav">
        <div className="bottom-nav-inner">
          <div className="slider"></div>
          <li>
            <a className="active-icon">
              <i>
                <IconButton
                  className="icon-btn"
                  onClick={() => {
                    setShowSwitchApp((prev) => !prev);
                    console.log("calling", showSwitchApp);
                  }}
                >
                  {!showSwitchApp ? <AppsIcon /> : <CloseIcon />}
                </IconButton>
              </i>
            </a>
          </li>
        </div>
      </StyledDiv>
      {showSwitchApp && (
        <DrawerContainer showSwitchApp={showSwitchApp}>
          <div className="products">
            <Row>
              {products?.map((val, index) => {
                if (val?.access) {
                  return (
                    <Col
                      md={3}
                      key={index}
                      className="items"
                      onClick={() => {
                        val?.url && window.open(val.url, "_self");
                      }}
                    >
                      <div className="logo">
                        <img src={val?.logo} alt={`${val?.name} Logo`} />
                      </div>
                      <h6>{val?.name}</h6>
                    </Col>
                  );
                }
              })}
            </Row>
          </div>
        </DrawerContainer>
      )}
    </div>
  );
}

export default SwitchAppFooter;
