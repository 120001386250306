import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Checkbox,
  Switch,
} from "antd";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import moment from "moment";
import { NON_EMPTY_REGEX, EMAIL_REGEX, STATUS_CODE } from "common/Constants";
import SelectTable from "../molecules/SelectTable";
import InReview from "../organisms/FormReview";
import { bindActionCreators } from "redux";
import {
  getAllDepartmentList,
  getLocations,
  getClientid,
  getLeaveCategoryList,
  getRoleList,
  getUserList,
  getBandList
} from "redux/selectors";
import { setUserList } from "redux/actions";
import { get, includes } from "lodash";
import { useDropzone } from "react-dropzone";
import StepsProgress from "./StepsProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "./FormModal";

const StyledModal = styled(Form)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1b1b1b;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
  .check-box {
    font-size: 14px;
    font-weight: 500;
  }
  .add_employee_col {
    padding: 5px;
  }
  .review_data_box_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: wrap;
  }
  .review_data_box {
    width: auto;
    padding: 1rem 1.4rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000015;
  }
  .review_datas {
    display: flex;
    justify-content: space-between;
    text-align: left;
    gap: 1rem;
  }
  .review_datas_key {
    text-align: left;
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .review_datas_value {
    text-align: left;
    font: normal normal normal 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .review_title {
    border-bottom: 1.5px solid #d9d9d9;
    width: fit-content;
    text-align: left;
    font: normal normal Bold 18px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
`;
const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;
const StyledDropzone = styled.div`
  text-align: center;
  width: 100%;
  height: 40px;
  background: #e3e3e3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    padding-top: 10px;
  }
`;

function AddEmployee({
  closeModal,
  getEmployeeList,
  EmployeeList,
  ClientId,
  updateUserList,
  roleList,
  userList,
  bandList
}) {

  const [showLoader, toggleLoader] = useState(false);
  const [filename, setFileName] = useState("");
  const [form] = Form.useForm();
  const { Option } = Select;
  const containerRef = useRef(null);
  const [profileUploaded, setProfileUploaded] = useState(false);
  const [uniqueId, setUniqueId] = useState(generateUniqueId());
  const [storeId, setStoreId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);

  const [employeeStatusId, setEmployeeStatusId] = useState("1")
  const [bandId, setbandId] = useState()
  const filterDepartment = roleList?.find((item) =>
    form.getFieldValue("role_id")?.includes(item?.roleId)
  )?.department;

  useEffect(() => {
    setProfileUploaded(false);
  }, []);

  const emailList = EmployeeList?.map((user) => user.userid);
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({});

  const [reviewBasicData, setReviewBasicData] = useState({})
  const [reviewEducationData, setReviewEducationData] = useState({})
  const [reviewWorkData, setReviewWorkData] = useState({})

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
    }
  };

  const handlesubmit = async (values) => {
    try {
      toggleLoader(true);
      setUniqueId(generateUniqueId());

      const {
        user_id,
        full_name,
        password,
        gender,
        age,
        dateof_birth,
        nationality,
        start_date,
        uan,
        pan,
        address,
        phone,
        overtime,
        salary,
        bandName,
        facerecognition,
        selectedRoleId,
        instituteName,
        degree,
        specialization,
        yearofcompletion,
        companyName,
        jobTitle,
        professionalsince,
        hireddate,
        relevent,
        employeestatus,
        maritalstatus,
        employeesponser,
        description,
        role_id,
      } = formValues;

      const roleNames = roleList
        .filter((item) => selectedRoleId?.includes(item.roleId))
        .map((item) => item.roleName);

        let userId = form.getFieldValue("user_id");
        let splitUserId = userId.split("@");
        let userIdWithoutDomain = splitUserId[0];
        let updatedUserId = userIdWithoutDomain + storeId;

      const response = await CALL_API(`add-users/${ClientId}`, "post", {
        user_id: user_id,
        full_name: full_name,
        password: password,
        start_date: start_date ? moment(start_date?._d).format("YYYY-MM-DD") : "",
        dateofbirth: dateof_birth ? moment(dateof_birth?._d).format("YYYY-MM-DD") : "",
        gender: gender,
        age: age,
        maritalstatus: maritalstatus,
        nationality: nationality,
        role_id: selectedRoleId,
        bandname: bandName,
        bandid: bandId,
        role_name: roleNames ? roleNames : "",
        institutename: instituteName,
        degree: degree,
        specialization: specialization,
        yearofcompletion: formValues?.yearofcompletion ? moment(formValues?.yearofcompletion?._d).format("YYYY-MM-DD") : "",
        companyname: companyName,
        jobtitle: jobTitle,
        professionalsince: formValues?.professionalsince ? moment(formValues?.professionalsince._d).format("YYYY-MM-DD") : "",
        hireddate: formValues?.hireddate ? moment(formValues?.hireddate._d).format("YYYY-MM-DD") : "",
        relevant: relevent,
        employeesponser: employeesponser,
        statusname: employeestatus,
        jobdescription: description,
        status_id: employeeStatusId,
        staff_department_id:
          filterDepartment && filterDepartment.length > 0 ? filterDepartment[0] : "",
        overtime_status: overtime,
        salary: salary ? salary : "",
        facerecognition: facerecognition,
        uan: uan,
        pan: pan,
        address: address,
        phoneno: phone,
        profile_url: profileUploaded
          ? `https://tymeplusprofiledev.s3.ap-south-1.amazonaws.com/${ClientId}/${updatedUserId}/${storeId}.jpg`
          : null,
        presentaddress: address,
        permanentaddress: address,
      });

      toggleLoader(false);
      form.resetFields();
      setFileName("");
      closeModal(true);

      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
        }
      }
      getEmployeeList();
      fetchMasterData(true);

    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  const Reset = () => {
    form.resetFields();
    setFileName("");
  };

  const onFinish = (step) => {
    const currentFormValues = form.getFieldValue();

    const updatedFormValues = {
      ...currentFormValues,
      selectedRoleId,
    };

    setFormValues(updatedFormValues);
    setStep(step);
    if (step === 4) {

      setReviewBasicData({
        full_name: formValues?.full_name,
        user_id: formValues?.user_id,
        dateof_birth: formValues?.dateof_birth
          ? moment(formValues?.dateof_birth?._d).format("YYYY-MMMM-DD")
          : "",
        age: formValues?.age,
        password: formValues?.password
          ? "*".repeat(formValues.password.length - 2) +
          formValues.password.slice(-2)
          : "",
        gender: formValues?.gender,
        phone: formValues?.phone,
        maritalstatus: formValues?.maritalstatus,
        nationality: formValues?.nationality,
        uan: formValues?.uan,
        pan: formValues?.pan,
        address: formValues?.address,
        overtime: formValues?.overtime,
        start_date: formValues?.start_date
          ? moment(formValues?.start_date?._d).format("YYYY-MMMM-DD")
          : "",
      });


      setReviewEducationData({
        instituteName: formValues?.instituteName,
        degree: formValues?.degree,
        specialization: formValues?.specialization,
        yearofcompletion: formValues?.yearofcompletion
          ? moment(formValues?.yearofcompletion?._d).format("YYYY-MMMM-DD")
          : "",
      });
    }
  };

  useEffect(() => {

    setReviewWorkData({
      companyName: formValues?.companyName,
      jobTitle: formValues?.jobTitle,
      employeesponser: formValues?.employeesponser,
      description: formValues?.description,
      relevent: formValues?.relevent,
      professionalsince: formValues?.professionalsince
        ? moment(formValues?.professionalsince?._d).format("YYYY")
        : "",
      hireddate: formValues?.hireddate
        ? moment(formValues?.hireddate?._d).format("YYYY-MMMM-DD")
        : "",
      status: formValues?.status,
    });
  }, [step]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    if (form.getFieldValue("user_id")) {
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          if (
            includes(
              [
                "image/jpeg",
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/tif",
                "image/tiff",
                "png",
                "jpg",
                "tif",
              ],
              get(file, "type", "")
            )
          ) {
            setFileName(acceptedFiles[0].path);
            setProfileUploaded(true);
            setUniqueId(generateUniqueId());
            let userId = form.getFieldValue("user_id");
            let splitUserId = userId.split("@");
            let userIdWithoutDomain = splitUserId[0];
            let updatedUserId = userIdWithoutDomain + uniqueId;
            const { code } = await CALL_API(
              `profile-upload/${ClientId}`,
              "post",
              {
                userid: updatedUserId,
                filetype: get(file, "type", ""),
                content: e.target.result,
                filename: uniqueId,
                emailid: '',
              }
            );
            if (code === STATUS_CODE.SUCCESS) {
              setStoreId(uniqueId);
            }
          } else {
            setProfileUploaded(false);
            message.error("Please upload valid image file.");
          }
        };
        reader.readAsDataURL(file);
        return file;
      });
    }
  }, [acceptedFiles]);

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };


  // Email Id Validation
  const validateEmail = (rule, value) => {
    if (value && emailList.includes(value.trim())) {
      return Promise.reject("This Email is already registered");
    }
    return Promise.resolve();
  };

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        return (
          <Switch
            size="small"
            checked={selectAllRoleChecked || selectedRoleId.includes(roleId)}
            onChange={(checked) => getRoleId(roleId, checked)}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
  };
  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Role" },
    { id: 3, title: "Education" },
    { id: 4, title: "Work" },
    { id: 5, title: "Review" },
  ];

  return (
    <>
      <label
        className="bg-transparent border-0 mb-3 text-left cursor-pointer"
        onClick={() => closeModal(false)}
      >
        <b className="add_employee_title">Employee</b>{" "}
        <ArrowForwardIosIcon className="px-1" />
      </label>{" "}
      <b className="text-info pb-0 pt-1">Add Employee</b>
      <StepsProgress stepdata={stepdata} title="" step={step} />
      <div className="styled-card mb-5">
        {step === 0 && (
          <StyledModal>
            <Form
              className=""
              form={form}
              name="addemployeeform"
              layout="vertical"
              initialValues={{
                user_id: "",
                department_id: "",
                full_name: "",
                password: "",
                gender: "",
                age: "",
                dateof_birth: "",
                maritalstatus: "",
                nationality: "",
                uan: "",
                pan: "",
                address: "",
                phone: "",
                overtime: "",
                start_date: "",
                halfpay: false,
                bandName: "",
                facerecognition: false,
              }}
              onFinish={() => onFinish(1)}
            >
              <Row gutter={20}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="full_name"
                    label="Full Name"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please enter First name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Full name"
                      maxLength={15}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="user_id"
                    label="User ID"
                    rules={[
                      {
                        required: true,
                        pattern: EMAIL_REGEX,
                        message: "Please enter Email ID",
                      },
                    ]}
                  >
                    <Input placeholder="Email ID" maxLength={60} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        validator: (_, value) => {
                          if (value && value.length < 8) {
                            return Promise.reject(
                              "Password should be min 8 to max 15 characters."
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      autoComplete="new-password"
                      maxLength={15}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="phone" label="Phone No">
                    <Input
                      type="number"
                      placeholder="Phone No"
                      maxLength={15}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please Select gender",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select gender"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="age" label="Age">
                    <Input type="number" placeholder="Age" maxLength={15} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    label="Date of Birth"
                    name="dateof_birth"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please enter date of birth",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      placeholder="Select Date of Birth"
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="maritalstatus"
                    label="Marital Status"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please Select",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="single">Single</Option>
                      <Option value="married">Married</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="nationality"
                    label="Nationality"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please Select",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="indian">Indian</Option>
                      <Option value="african">African</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="uan" label="UAN">
                    <Input placeholder="UAN" maxLength={20} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="pan" label="PAN">
                    <Input placeholder="PAN" maxLength={20} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Address",
                      },
                    ]}
                  >
                    <Input placeholder="Address" maxLength={60} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="bandName"
                    label="Band"
                    rules={[
                      {
                        required: false,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please Select",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      getPopupContainer={() => containerRef.current}
                      onChange={(value, key) => setbandId(key?.key)}
                    >
                      {bandList?.map((data, index) => {
                        return (
                          <Option
                            key={index}
                            value={data.bandName}
                          >
                            {data.bandName}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="overtime"
                    label="Overtime Eligible"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please Select",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    label="Start Date"
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please Select Start Date",
                      },]}
                  >
                    <DatePicker
                      className="w-100"
                      format="YYYY-MM-DD"
                      placeholder="Select start date"
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="salary" label=" Salary">
                    <Input placeholder="Salary" type="number" maxLength={10} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} className="mb-2">
                  <Form.Item name="facerecognition" valuePropName="checked">
                    <Checkbox>
                      <p className="check-box">Face Recognition</p>
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="profile" label="Profile Pic">
                    <StyledDropzone {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        {filename !== "" ? <>{filename}</> : <> choose file</>}
                      </p>
                    </StyledDropzone>
                  </Form.Item>
                </Col>
              </Row>
              <div className="text-end">
                <StyledButton type="primary" onClick={() => Reset()}>
                  Reset
                </StyledButton>
                <StyledButton
                  loading={showLoader}
                  type="primary"
                  htmlType="submit"
                >
                  Next
                </StyledButton>
              </div>
            </Form>
          </StyledModal>
        )}

        {step === 1 && (
          <>
            {roleList.length === 0 && (
              <StyledButton className="mt-2" onClick={() => setOpenModal(true)}>
                Add Role
              </StyledButton>
            )}
            {roleList?.length > 0 && (
              <Checkbox
                className="mt-3"
                checked={selectAllRoleChecked}
                onChange={handleSelectAllRole}
              >
                Select All
              </Checkbox>
            )}
            <SelectTable
              step={step}
              setStep={setStep}
              columns={rolecolumns}
              tabledata={roleList}
              editRole="edit"
              selectedRoleId={selectedRoleId}
            />
            <FormModal
              isModalVisible={openModal}
              closeModal={() => setOpenModal(false)}
              Name="Role"
            />
          </>
        )}

        {step === 2 && (
          <>
            <StyledModal>
              <Form
                className=""
                form={form}
                name="addEmployeeEducationForm"
                layout="vertical"
                initialValues={{
                  instituteName: "",
                  degree: "",
                  specialization: "",
                  yearofcompletion: "",
                }}
                onFinish={() => onFinish(3)}
              >
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name="instituteName"
                      label="Institute Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Institute Name",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Institute Name"
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="degree"
                      label="Degree/Diploma"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Degree",
                        },
                      ]}
                    >
                      <Input type="text" placeholder="degree" maxLength={50} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="specialization"
                      label="Specialization"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Specialization",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Specialization"
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      label="Year of Completion"
                      name="yearofcompletion"
                    >
                      <DatePicker
                        className="w-100"
                        onChange={validateDateRange}
                        format="YYYY-MM-DD"
                        placeholder="Select start date"
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="text-end">
                  <StyledButton type="primary" onClick={() => Reset()}>
                    Reset
                  </StyledButton>
                  <StyledButton
                    loading={showLoader}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </StyledButton>
                </div>
              </Form>
            </StyledModal>
          </>
        )}

        {step === 3 && (
          <>
            <StyledModal>
              <Form
                className=""
                form={form}
                name="addemployeeidentityform"
                layout="vertical"
                initialValues={{
                  companyName: "",
                  jobTitle: "",
                  professionalsince: "",
                  hireddate: "",
                  employeesponser: "",
                  employeestatus: "",
                  relevent: "",
                  maritalstatus: "",
                  description: "",
                }}
                onFinish={() => onFinish(4)}
              >
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name="companyName"
                      label="Company Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Company name",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Company name"
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="jobTitle"
                      label="Job Title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter job title",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Job Title"
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      label="Professional Since"
                      name="professionalsince"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Please enter start date!",
                        },
                        () => ({
                          // Note : First parameter is Needed
                          validator(rule, value) {
                            const date = moment(value);
                            return date.isValid()
                              ? Promise.resolve()
                              : Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        onChange={validateDateRange}
                        placeholder="Select start date"
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      label="Hired Date"
                      name="hireddate"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Please enter start date!",
                        },
                        () => ({
                          // Note : First parameter is Needed
                          validator(rule, value) {
                            const date = moment(value);
                            return date.isValid()
                              ? Promise.resolve()
                              : Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        onChange={validateDateRange}
                        placeholder="Select start date"
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      name="employeesponser"
                      label="Employee Sponser"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Please Select",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        getPopupContainer={() => containerRef.current}
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      name="employeestatus"
                      label="Status"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Please Select",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        getPopupContainer={() => containerRef.current}
                        onChange={(value) => setEmployeeStatusId(value === "active" ? "1" : "0")}
                      >
                        <Option value="active">Active</Option>
                        <Option value="in active">In active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      name="relevent"
                      label="Relevent"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Please Select",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        getPopupContainer={() => containerRef.current}
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name="description"
                      label="Description"
                    >
                      <Input.TextArea
                        placeholder="description"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="text-end">
                  <StyledButton type="primary" onClick={() => Reset()}>
                    Reset
                  </StyledButton>
                  <StyledButton
                    loading={showLoader}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </StyledButton>
                </div>
              </Form>
            </StyledModal>
          </>
        )}

        {step === 4 && (
          <>
            <InReview
              title="Basic Information"
              setStep={setStep}
              step={step}
              BasicInformation={reviewBasicData}
              role={selectedRoleId}
              EducationInformation={reviewEducationData}
              WorkInformation={reviewWorkData}
              handleCreate={handlesubmit}
              loading={showLoader}
            />
          </>
        )}
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  allDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  roleList: getRoleList(state),
  userList: getUserList(state),
  bandList: getBandList(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(AddEmployee);
