import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form, message } from 'antd';
import Button from 'atoms/Button';
import moment from 'moment';
import styled from '@emotion/styled';
import { CSVLink } from "react-csv";
import { read, utils, writeFileXLSX } from 'xlsx';
// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
  getClientid
} from 'redux/selectors';

import { CALL_API } from 'common/API';
import { MONTH_LIST, REPORT_DURATION_TYPES, STATUS_CODE } from 'common/Constants';

import { addDaysinDate, dateInDetail, differenceBetweenTwoTime, getKenyanDateTime, generateHourMinutesFromMinutes, getMonthDateRange, getMySqlDate, momentDate } from 'utils/Date';
import COLORS from 'common/Colors';
import { concat, find, get, includes, orderBy, reduce } from 'lodash';
import ViewMore from 'organisms/ViewMore';

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-cell:last-child {
    /* Override white-space property for the last column */
    min-width:200px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom :10px;
    width: 180px;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color:  ${COLORS.PRIMARY};
  width: initial;
  background:transparent;
`;


function LateCheckIn({
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
  shiftTimeMaster,
  ClientId
}) {
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [lateCheckinList, setLateCheckinList] = useState([]);
  const [CSVcheckinList, setCSVcheckinList] = useState([]);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const [stafflateCheckinList, setStafflateCheckinList] = useState([]);
  const [staffName, setStaffName] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [monthList, setMonthList] = useState([])
  const containerRef = useRef(null);

  const curr_month = moment().month() + 1

  const list = () => {
    let month_list = []
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i])
    }
    setMonthList(month_list)
  }

  const ALL = [{ value: "*", label: "All Employees" }]
  const STAFF_LIST_ALL = lateCheckinList.map((val) => {
    return { value: val.userId, label: val.fullName }
  });


  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map(a => JSON.stringify(a)))].map(a => JSON.parse(a));

  const CSVHeaders = [
    // { label: "Employee ID" },
    { label: "Name" },
    { label: "Department" },
    { label: "Check-in Date" },
    { label: "Check-in time" },
    { label: "Check-in cutoff" },
    // { label: "Check-out time" },
    { label: "Reason" }
  ];

  const columns = [
    // {
    //   title: 'Employee ID',
    //   dataIndex: 'userId',
    //   key: 'userId',
    //   width: '5%',
    //   // align: 'center'
    // },
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '5%',
      // align: 'center'
    },
    {
      title: 'Department',
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: '5%',
      // align: 'center'
    },
    {
      title: 'Check-in Date',
      dataIndex: 'checkinDate',
      key: 'checkinDate',
      width: '5%',
      render: (checkinDate) => {
        const dateFormat = moment(checkinDate).format("DD MMM YYYY");
        return `${dateFormat}`;
      }
    },
    {
      title: 'Check-in time',
      dataIndex: 'checkinTime',
      key: 'checkinTime',
      width: '5%',
    }, 
    {
      title: 'Check-in cutoff',
      dataIndex: 'shiftCutoffTime',
      key: 'shiftCutoffTime',
      width: '5%',
    },
    // {
    //   title: 'Check-out time',
    //   dataIndex: 'end_time',
    //   key: 'end_time',
    //   width: '5%',
    //   align: 'center',
    // },

    {
      title: 'Late time',
      dataIndex: 'late',
      key: 'late',
      width: '5%',
      // align: 'center',
      render: (_, record) => {
        const diff = differenceBetweenTwoTime(
          record.shiftCutoffTime,
          record.checkinTime
        );
        return generateHourMinutesFromMinutes(diff);
      },
    },
    {
      title: 'Reason',
      dataIndex: 'punchinReason',
      key: 'punchinReason',
      width: '5%',
      // align: 'center',
      render: (reason) => {
        return (
          <>
            <ViewMore value={reason} />
          </>
        );
      }
    },

  ];



  useEffect(() => {
    list()
    getAttendanceReport();
  }, [selectedDepartmentId,ClientId, userRoleId, form.getFieldValue("duration_type"), form.getFieldValue('end_date'), form.getFieldValue('month'), form.getFieldValue('start_date'), form.getFieldValue('department')]);

  const getAttendanceReport = async () => {
    return new Promise(async (resolve, reject) => {
      setLateCheckinList([]);
      const {
        start_date,
        end_date,
        duration_type,
        month,
        department,
      } = form.getFieldsValue();
      setDisplayTableLoader(true);
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), 'index', 0)
      );
      if(ClientId){
      const {
        code,
        lateCheckinList = []
      } = await CALL_API(`late-checkin-report/${ClientId}`, 'post', {
        department: (userRoleId === 1 || userRoleId === 6) ? [department] : selectedDepartmentId,
        start_date: getMySqlDate(duration_type === 2 ? startDateOfMonth : start_date),
        end_date: getMySqlDate(duration_type === 2 ? lastDateOfMonth : end_date),
      });
      setDisplayTableLoader(false);
      if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
        setLateCheckinList(orderBy(lateCheckinList, ['checkinDate'], ['desc']));
        setStafflateCheckinList(orderBy(lateCheckinList, ['checkinDate'], ['desc']));

      }
    }
    })
  }

  const restrictPastDate = (current, element) => {
    let customDate = moment().format('YYYY-MM-DD');
    if (element === 'end_date') {
      return current < moment(form.getFieldValue('created_date'));
    }
    return current && current < moment(customDate, 'YYYY-MM-DD');
  }

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue('end_date'));
    if (endDate < date) {
      form.setFieldsValue({ 'end_date': date })
    }
  }


  const xport = React.useCallback(() => {
    const elt = document.getElementById('Table');
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "attendance-report.xlsx");
  });

  function getMonthName(monthIndex) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[monthIndex];
  }

  const prepareCSV = async (event, done) => {
    if (lateCheckinList.length > 0) {
      setCSVcheckinList(

        reduce(lateCheckinList, function (result, record) {
          const diff = differenceBetweenTwoTime(
            record.shiftCutoffTime,
            record.checkinTime
          );
          const loc = document.getElementById("loc");
          const dateObj = new Date(record.checkinDate);
          const day = dateObj.getDate();
          const month = getMonthName(dateObj.getMonth());
          const year = dateObj.getFullYear().toString().substr(-2);
          const formattedDate = `${day} ${month} ${year}`;

          result.push({
            // "Employee ID": record.userId,
            "Name": record.fullName,
            "Department": record.departmentName,
            "Check-in Date": formattedDate,
            "Check-in time": record.checkinTime,
            "Check-in cutoff": record.shiftCutoffTime,
            // "Check-out time": record.end_time,
            "Late Time": generateHourMinutesFromMinutes(diff),
            "Reason": record.punchinReason === '' || null ? '-' : record.punchinReason
          });
          return result;
        }, [])
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  }

  const handleChange = (event) => {
    setStaffName(event);
    if (event == "*") {
      getAttendanceReport();
    }
    const filteredStaffList = stafflateCheckinList.filter((val) => val.userId === event);
    setLateCheckinList(filteredStaffList);
    setStafflateCheckinList(stafflateCheckinList)
  }

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false
  };

  return (
    <>
      <Row className='page-title'>
        <Col xs={24} sm={19} md={19} className='page-title-head' ref={containerRef}>
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name='approval_form'
              layout='inline'
              initialValues={{
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(getKenyanDateTime()),
                end_date: momentDate(addDaysinDate(getKenyanDateTime(), 7)),
                department: '',
              }}
              onFinish={getAttendanceReport}
            >
              <Form.Item
                name='duration_type'
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Select
                  placeholder='Select...'
                  options={REPORT_DURATION_TYPES}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>

              <Form.Item
                className='outer-form'
                shouldUpdate
              >
                {
                  ({ getFieldValue }) => {
                    const duration_type = getFieldValue('duration_type');
                    return duration_type === 1 ? (
                      <>
                        <Form.Item name='start_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='Start date'
                            // disabledDate={(current) => restrictPastDate(current, 'start_date')} 
                            onChange={validateDateRange}
                            allowClear={false}
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>

                        <Form.Item
                          name='end_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='End date'
                            onChange={validateDateRange}
                            allowClear={false}
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <Form.Item
                        name='month'
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                        ]}
                      >
                        <Select
                          placeholder='Select...'
                          options={monthList}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    )
                  }
                }
              </Form.Item>
              <Form.Item
                name='staff_name'
                rules={[
                  {
                    required: false,
                    message: '',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat(ALL, STAFF_LIST)}
                  value={staffName}
                  onChange={handleChange}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
              {
                (userRoleId === 1 || userRoleId === 6) && (
                  <Form.Item
                    name='department'
                  >
                    <Select
                      placeholder="Select Department"
                      options={concat({
                        department_id: '',
                        department_name: 'All Departments'
                      }, allDepartmentList)}
                      fieldNames={{
                        label: 'department_name',
                        value: 'department_id'
                      }}
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                )
              }
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className='align-right'>
          <StyledButton onClick={prepareCSV} disabled={lateCheckinList?.length === 0 || null || undefined}>
            Export CSV file
          </StyledButton>
          <CSVLink
            header={CSVHeaders}
            data={CSVcheckinList}
            filename={"latecheckin-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          style={{ height: '600px !important' }}
          dataSource={lateCheckinList}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
          rowKey='checkin_id'
          id='Table'
          pagination={lateCheckinList.length > 20 ? paginationOptions : false}
        />
      </Spin>
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state),
  ClientId: getClientid(state),
});

export default connect(mapStateToProps, null)(LateCheckIn);
