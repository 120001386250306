import { Row, Col } from "react-bootstrap";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Button, Table, Spin } from "antd";
import { Link } from "react-router-dom";
import UISearch from "../reusableComponent/UISearch/SearchInput";
import { useEffect, useState } from "react";
import { CALL_API } from "common/API";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
    border: none;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 5px 10px;
    height: 0px !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #f4f4f4;
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;

const AppraisalHistory = ({
  displayLocationLoader,
  setPageView,
  history,
  AllDepartmentList,
  appraisal,
  fetchDataBYId,
  myappraisal,
  myAppraisal,
  setViewResponseData,
}) => {
  const btnSearch = {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    border: "none",
    margin: "0 5px",
    minWidth: "60%",
  };

  const backStyle = { color: "#26A4FF", cursor: "pointer" };

  const [viewResponse, setViewResponse] = useState(false);
  const [responseById, setResponseById] = useState(false);
  const [responseLoader, setResponseloader] = useState(false);
  const [filteredAppraisals, setFilteredAppraisals] = useState([]);
  const [appraisalName, setAppraisalName] = useState([]);

  const fetchData = async () => {
    setResponseloader(true);
    try {
      const res = await CALL_API(`appraisalform/completedappraisal`, "get");
      setResponseloader(false);
      setResponseById(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleFilterAppraisals = (id, assignId) => {
    const filtered = responseById.filter(
      (appraisal) =>
        appraisal.appraisalFormId === id && appraisal.assignId === assignId
    );
    setFilteredAppraisals(filtered);
  };

  const findDepartmentName = (departmentId) => {
    const department = AllDepartmentList.find(
      (dep) => dep.department_id === departmentId
    );
    return department ? department.department_name : "N/A";
  };

  const findAppraisalName = (appraisalFormId) => {
    const department = appraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return department ? department.title : "N/A";
  };

  const findAppraisalTime = (appraisalFormId) => {
    const department = myAppraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return department
      ? department.appraisalForm + " - " + department.appraisalTo
      : "N/A";
  };

  const appraisalHistorycolumns = [
    {
      title: "Form Title",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      render: (appraisalFormId) => findAppraisalName(appraisalFormId),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      title: "Department",
      dataIndex: "departmentId",
      key: "departmentId",
      width: "10%",
      render: (departmentId) => findDepartmentName(departmentId),
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <>
            <Button
              onClick={() => {
                setViewResponse(true);
                handleFilterAppraisals(record.appraisalFormId, record.assignId);
                setAppraisalName(record.appraisalFormId);
              }}
              type="link"
              className="fw-medium"
            >
              View form
            </Button>
          </>
        );
      },
    },
  ];

  const responseColumn = [
    {
      title: "Employee name",
      dataIndex: "userName",
      key: "userName",
      width: "10%",
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "10%",
      render: (updatedAt) => <p>{updatedAt.slice(0, 10)}</p>,
    },
    {
      title: "Department",
      dataIndex: "departmentId",
      key: "departmentId",
      width: "10%",
      render: (departmentId) => findDepartmentName(departmentId),
    },
    {
      title: "Hike percentage",
      dataIndex: "hikePercentage",
      key: "hikePercentage",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <>
            <Button
              onClick={() => {
                setPageView("historyResponse");
                setViewResponseData(record);
              }}
              type="link"
              className="fw-medium"
            >
              View form response
            </Button>
          </>
        );
      },
    },
  ];

  const myappraisalHistorycolumns = [
    {
      title: "Title",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      render: (appraisalFormId) => findAppraisalName(appraisalFormId),
    },
    {
      title: "Time Period",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      render: (appraisalFormId) => findAppraisalTime(appraisalFormId),
    },
    {
      title: "Hike percentage",
      dataIndex: "hikePercentage",
      key: "hikePercentage",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <>
            <Button
              onClick={() => {
                setPageView("historyResponse");
                setViewResponseData(record);
              }}
              type="link"
              className="fw-medium"
            >
              View form
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6}>
          <UISearch
            showFilterContent={true}
            placeholder="Search"
            btnSearchStyle={btnSearch}
          />
        </Col>
        <Col sm={12} md={6} lg={6}></Col>
      </Row>
      {myappraisal ? (
        <StyledTable
          className="mb-5"
          dataSource={history}
          columns={myappraisalHistorycolumns}
          rowKey="department_id"
          pagination={false}
        />
      ) : (
        <>
          {viewResponse ? (
            <>
              <div className="mt-2">
                <span style={backStyle} onClick={() => setViewResponse(false)}>
                  Back
                </span>
                <span className="px-2">/</span>
                <b>{findAppraisalName(appraisalName)}</b>
              </div>
              <Spin spinning={responseLoader}>
                <StyledTable
                  className="mb-5"
                  dataSource={filteredAppraisals}
                  columns={responseColumn}
                  rowKey="department_id"
                  pagination={false}
                />
              </Spin>
            </>
          ) : (
            <Spin spinning={displayLocationLoader}>
              <StyledTable
                className="mb-5"
                dataSource={history}
                columns={appraisalHistorycolumns}
                rowKey="department_id"
                pagination={false}
              />
            </Spin>
          )}
        </>
      )}
    </div>
  );
};

export default AppraisalHistory;
