import React from "react";
import styled from "@emotion/styled";
import { Row, Switch, Button } from "antd";
import {
  getUserRoleId,
  getDepartmentUserList,
  getUserList,
  getClientid,
  getRoleList,
  getUserMenuList,
} from "redux/selectors";
import { connect } from "react-redux";

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  font-weight: bold;
`;

const Styleddiv = styled(Row)`
  .styled-table {
    width: 100%;
  }

  .table-container {
    width: 100%;
    height: 470px;
    overflow-x: auto;
  }
  table,
  th {
    border: 1px solid #e6e6e6;
  }

  th {
    background: #f4f4f4 0% 0% no-repeat padding-box;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
  }

  td {
    border-top: 1px solid #f4f4f4;
    padding: 5px 10px;
    font-size: 16px;
    letter-spacing: 0.08px;
    overflow: hidden;
    white-space: nowrap;
  }

  .menu-container {
    width: 100%;
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .list-type {
    list-style-type: disc;
  }
  .form-height {
    line-height: 4;
  }
  .title {
    color: black;
    background: #f4f4f4;
    border-radius: 10px;
  }
  ul {
    list-style-type: none;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-collapse-header {
    border: 1px solid #f4f4f4;
    border-radius: 10px;
  }
  .ant-collapse-header {
    margin-top: 10px;
  }
  .ant-tabs-nav {
    background: #e6f5ff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    border-radius: 5px;
    padding-left: 20px;
  }
  .styledbtn {
    background-color: #26a4ff;
    color: white;
    border: none;
    border-radius: 10px;
    position: fixed;
    z-index: 100;
    padding: 5px 10px;
  }
  .ant-switch-checked {
    background-color: #03c83e;
  }
`;

const RolesAndPermissions = ({
  setMenuList,
  menuList,
  setStep,
  step,
  viewOnly,
  setsubmit,
}) => {
  const handleSwitchChange = (roleId, permissionType, submenuKey, checked) => {
    setMenuList((prevMenuList) => {
      const updatedList = prevMenuList.map((item) => {
        if (item.id === roleId) {
          if (submenuKey) {
            const updatedSubmenu = item.subMenu.map((subItem) => {
              if (subItem.key === submenuKey) {
                return {
                  ...subItem,
                  permission: {
                    ...subItem.permission,
                    [permissionType]: !subItem.permission[permissionType],
                  },
                  access:
                    (subItem.permission.view && permissionType !== "view") ||
                    (permissionType === "view" && !subItem.permission.view)
                      ? true
                      : false,
                };
              }
              return subItem;
            });
            const allSubMenuAccessFalse = updatedSubmenu.every(
              (subItem) => subItem.access === false
            );
            return {
              ...item,
              access:
                item.permission.view && allSubMenuAccessFalse ? false : true,
              subMenu: updatedSubmenu,
            };
          } else {
            const allSubMenuAccessFalse = item.subMenu.every(
              (subItem) => subItem.access === false
            );
            return {
              ...item,
              access:
                item.permission.view && allSubMenuAccessFalse ? false : true,
              permission: {
                ...item.permission,
                [permissionType]: !item.permission[permissionType],
              },
            };
          }
        }
        return item;
      });
      return updatedList;
    });
    setsubmit(true);
  };

  return (
    <Styleddiv>
      <table className="styled-table mt-3">
        <thead>
          <tr>
            <th className="border-0">Menu Access Level</th>
            <th className="border-0">Read</th>
            <th colspan="3" className="text-center">
              Write
            </th>
          </tr>
          <tr>
            <th className="border-0"></th>
            <th className="border-0 border-bottom-1"></th>
            <th>Create</th>
            <th>Modify</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {menuList?.map((item) => (
            <>
              {item.subMenu.length === 0 && (
                <tr key={item.id}>
                  <td>{item.title}</td>
                  <td>
                    <Switch
                      size="small"
                      defaultChecked
                      disabled={viewOnly}
                      checked={item.permission.view}
                      valuePropName="checked"
                      onChange={() => handleSwitchChange(item.id, "view")}
                    />
                  </td>
                  <td>
                    <Switch
                      size="small"
                      defaultChecked
                      disabled={viewOnly}
                      checked={item.permission.create}
                      valuePropName="checked"
                      onChange={() => handleSwitchChange(item.id, "create")}
                    />
                  </td>
                  <td>
                    <Switch
                      size="small"
                      defaultChecked
                      disabled={viewOnly}
                      checked={item.permission.edit}
                      valuePropName="checked"
                      onChange={() => handleSwitchChange(item.id, "edit")}
                    />
                  </td>
                  <td>
                    <Switch
                      size="small"
                      defaultChecked
                      disabled={viewOnly}
                      checked={item.permission.delete}
                      valuePropName="checked"
                      onChange={() => handleSwitchChange(item.id, "delete")}
                    />
                  </td>
                </tr>
              )}
              {item.subMenu?.map((submenuItem) => (
                <tr key={submenuItem.key}>
                  <td>{submenuItem.subtitle}</td>
                  <td>
                    <Switch
                      size="small"
                      defaultChecked
                      disabled={viewOnly}
                      checked={
                        submenuItem.permission && submenuItem.permission.view
                      }
                      valuePropName="checked"
                      onChange={() =>
                        handleSwitchChange(item.id, "view", submenuItem.key)
                      }
                    />
                  </td>
                  <td>
                    <Switch
                      size="small"
                      defaultChecked
                      disabled={viewOnly}
                      checked={
                        submenuItem.permission && submenuItem.permission.create
                      }
                      valuePropName="checked"
                      onChange={() =>
                        handleSwitchChange(item.id, "create", submenuItem.key)
                      }
                    />
                  </td>
                  <td>
                    <Switch
                      size="small"
                      defaultChecked
                      disabled={viewOnly}
                      checked={
                        submenuItem.permission && submenuItem.permission.edit
                      }
                      valuePropName="checked"
                      onChange={() => {
                        handleSwitchChange(item.id, "edit", submenuItem.key);
                      }}
                    />
                  </td>
                  <td>
                    <Switch
                      size="small"
                      defaultChecked
                      disabled={viewOnly}
                      checked={
                        submenuItem.permission && submenuItem.permission.delete
                      }
                      valuePropName="checked"
                      onChange={() =>
                        handleSwitchChange(item.id, "delete", submenuItem.key)
                      }
                    />
                  </td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
      {step && (
        <div className="text-end w-100 mt-3">
          <StyledButton onClick={() => setStep(step - 1)} className="mx-2">
            Back
          </StyledButton>
          <StyledButton onClick={() => setStep(step + 1)}>Next</StyledButton>
        </div>
      )}
    </Styleddiv>
  );
};
const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  departmentUserList: getDepartmentUserList(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  userMenuList: getUserMenuList(state),
});
export default connect(mapStateToProps)(RolesAndPermissions);
