import React,{ useState } from 'react'
import styled from '@emotion/styled';

const StyledViewmore = styled.div`
  a{
    color: #26A4FF !important;
    font-size:12px;
    font-weight:bold;
    white-space:nowrap;
  }
`;

function ViewMore ({
  value,
  count = 50
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return(
    <>
        {value == null || value == "" ? "-" : (<>
          {value.length  > count ? (
                    <>
                    {isExpanded ? (
                        <StyledViewmore>{value} <a onClick={() => setIsExpanded(false)} >View less</a></StyledViewmore>
                    ) : (
                        <StyledViewmore>
                        {value.substring(0, count)}... <a onClick={() => setIsExpanded(true)} >View more</a>
                        </StyledViewmore>
                    )}
                    </>
                ):<>{value}</>}
        </>)}        
    </>
  )
}


export default ViewMore