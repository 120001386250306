import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorConvertToHTML = ({content = '', onChange}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    setEditorState(newEditorState);
    onChange(htmlContent)
  };

  const containerStyle = { border: "1px solid #ccc", borderRadius: "5px", padding: "10px" };

  useEffect(() => {
    if(content !== ''){
      const contentBlock = convertFromHTML(content);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    } 
  }, [content]);

  return (
    <div style={containerStyle}>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
};

export default EditorConvertToHTML;
