import React, { useEffect, useState } from "react";
import EmployeeCard from "molecules/EmployeeCard";
import { Row, Col, Select, Button, Spin, Modal } from "antd";
import { DEVICE } from "common/Device";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getClientid,
} from "redux/selectors";
import { setUserList } from "redux/actions";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import { map, concat } from "lodash";
import Icon from "atoms/Icon";
import AddEmployee from "organisms/AddEmployee";
import EditEmployee from "organisms/EditEmployee";
import Confirm from "../../src/assets/Confirm.svg";
import { STATUS_CODE } from "common/Constants";
import { bindActionCreators } from "redux";
import TabsList from "molecules/TabsList";

const StyledCol = styled(Col)`
  padding: 10px;
`;

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .selectbox {
    width: 100%;
  }
  @media ${DEVICE.tablet} {
    .selectbox {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const StyledContainerRow = styled(Row)`
  padding-top: 20px;
  .loader_container {
    width: 100%;
    text-align: center;
  }
  .noEmployee {
    width: 100%;
    text-align: center;
    color: grey;
    margin-top: 10%;
  }
`;

const StyledRoleCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  .add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #26a4ff;
    border-radius: 5px;
    color: #ffffff;
    box-shadow: none;

    padding-left: 10px;
    cursor: pointer;
  }
  .add-title {
    margin-left: 10px;
    vertical-align: text-bottom;
  }

  @media ${DEVICE.tablet} {
    padding: 1rem;
    .role-button {
      width: 100%;
    }
  }
`;
const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 24px 0px 30px 0px;
  }
  .modal-body {
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x {
    color: #151515;
  }
  .modal-body-content {
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;
const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

function Employee({ allDepartmentList, ClientId, updateUserList }) {
  const [displayAddEmployeeModal, toggleAddEmployeeModal] = useState(false);
  const [displayEditEmployeeModal, toggleEditEmployeeModal] = useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [displayEmployeeLoader, setdisplayEmployeeLoader] = useState(false);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [deactivateEmployee, toggledeactivateEmployee] = useState(false);
  const [confirmdeactivateEmployee, toggleconfirmdeactivateEmployee] =
    useState(false);
  const [empId, setEmpId] = useState(0);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [employeeFilterList, setEmployeeFilterList] = useState([]);
  const [employeeType, setEmployeeType] = useState("1");
  const STAFF_LIST =
    employeeFilterList?.map((val) => {
      return { user_id: val?.userid, name: val?.fullname };
    }) || [];

  const items = [
    {
      id: 1,
      label: "Employee",
      component: <></>
    },
    {
      id: 2,
      label: "Ex Employee",
      component: <></>
    },
  ];
  const getFilterList = () => {
    if (employeeNameFilter !== "") {
      return employeeFilterList?.filter(
        (data) => data?.userid === employeeNameFilter
      );
    } else {
      return employeeFilterList;
    }
  };
  useEffect(() => {
    var result;
    if (employeeType) {
      result = EmployeeList?.filter((data) => data?.statusid == employeeType);
    }
    setEmployeeFilterList(result);
  }, [selectedDepartment, employeeType, EmployeeList]);

  useEffect(() => {
    getEmployeeList();
  }, [setEmployeeList]);
  const getEmployeeList = async () => {
    try {
      setdisplayEmployeeLoader(true);
      const { response } = await CALL_API(`add-users/${ClientId}`, "get", {});
      setEmployeeList(response);
      setdisplayEmployeeLoader(false);
    } catch (error) {
      console.log("[getEmployeeList] Error-->", error);
    }
  };
  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        updateUserList(userList);
      }
    }
  };
  const ViewEmployee = (EmployeeObject, isViewOnly = false) => {
    console.log("EmployeeObject--->", EmployeeObject)
    toggleEditEmployeeModal(true);
    setEmployeeDetails({
      ...EmployeeObject,
      isViewOnly,
    });
  };
  const deletedEmployee = (EmpId) => {
    toggledeactivateEmployee(true);
    setEmpId(EmpId);
  };
  const deleteEmployee = async (employee_id) => {
    togglebtnLoader(true);
    await CALL_API(`add-users/${ClientId}`, "delete", {
      user_id: employee_id,
      status_id: 0,
    });
    togglebtnLoader(false);
    toggledeactivateEmployee(false);
    toggleconfirmdeactivateEmployee(true);
    fetchMasterData();
    getEmployeeList();
  };
  const handleTabSelect = key => {
    if (key === 2) {
      setEmployeeType("0");
    }
    else{
      setEmployeeType("1");
    }
  };
  
  return (
    <>
      {!displayAddEmployeeModal && !displayEditEmployeeModal && (
        <>
          <StyledTitle className="page-title-head">
            List of Employees
          </StyledTitle>
          <TabsList
            items={items}
            handleTabSelect={handleTabSelect}
          />
          <StyledFilterRow gutter={16}>
            <Col lg={4} md={12} sm={24} xs={24}>
              <Select
                className="selectbox mt-md-2"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.name ?? "")
                    .toLowerCase()
                    .includes(input?.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.name ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.name ?? "").toLowerCase())
                }
                placeholder="Select Employee"
                options={concat(
                  {
                    user_id: "",
                    name: "All Employee",
                  },
                  STAFF_LIST
                )}
                fieldNames={{
                  label: "name",
                  value: "user_id",
                }}
                onChange={(value) => setEmployeeNameFilter(value)}
              />
            </Col>
            <Col lg={4} md={12} sm={24} xs={24}>
             
            </Col>
            <Col lg={4} md={12} sm={24} xs={24}>
            </Col>
            <Col
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="align-right mt-md-2"
            >
              <StyledRoleCol>
                <Button
                  onClick={() => toggleAddEmployeeModal(true)}
                  className={`add-button`}
                >
                  <Icon name="addemployee" />
                  <span className="add-title">Add Employee</span>
                </Button>
              </StyledRoleCol>
            </Col>
          </StyledFilterRow>
          <StyledContainerRow gutter={16}>
            {displayEmployeeLoader ? (
              <div className="loader_container">
                <Spin spinning={displayEmployeeLoader} />
              </div>
            ) : (
              <>
                {employeeFilterList?.length > 0 ? (
                  <>
                    {map(getFilterList(), (data, index) => (
                      <>
                        <StyledCol
                          key={index}
                          xs={24}
                          sm={12}
                          md={12}
                          lg={8}
                          xl={6}
                        >
                          <Link to={`/profile-details/${data.userid}`}>
                            <EmployeeCard
                              id={data.userid}
                              name={data.fullname}
                              Department={data.department_name}
                              clientid={data.clientid}
                              employeeid={data.employeeid}
                              data={data}
                              getEmployeeList={getEmployeeList}
                              ViewEmployee={ViewEmployee}
                              deletedEmployee={deletedEmployee}
                              profile={data.profileurl}
                              employeeType={employeeType}
                              rolename={data.rolename}
                            />
                          </Link>
                        </StyledCol>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <p className="noEmployee">
                      No Employee's in this Department
                    </p>
                  </>
                )}
              </>
            )}
          </StyledContainerRow>
        </>
      )}
      {displayAddEmployeeModal && (
        <AddEmployee
          isModalVisible={displayAddEmployeeModal}
          closeModal={() => toggleAddEmployeeModal(false)}
          getEmployeeList={getEmployeeList}
          setSelectedDepartment={setSelectedDepartment}
          EmployeeList={EmployeeList}
        />
      )}
      {displayEditEmployeeModal && (
        <EditEmployee
          isModalVisible={displayEditEmployeeModal}
          closeModal={() => toggleEditEmployeeModal(false)}
          getEmployeeList={getEmployeeList}
          EmployeeDetails={EmployeeDetails}
          EmployeeList={EmployeeList}
        />
      )}
      <StyleddeactivateModal
        title="Are you sure you want to terminate this Employee?"
        visible={deactivateEmployee}
        width={500}
        onCancel={() => toggledeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head" gutter={16}>
          <Col>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => toggledeactivateEmployee(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={() => deleteEmployee(empId)}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>
      <StyledModal
        visible={confirmdeactivateEmployee}
        width={500}
        onCancel={() => toggleconfirmdeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="modal-body">
          <Col>
            <img src={Confirm} alt="confirm" height={45} width={45} />
          </Col>
        </Row>
        <Row className="modal-body-content">
          <Col>
            <p>This Employee has been terminated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
}
const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(Employee);
