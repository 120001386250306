import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Row, Col, Button } from "antd";
import { DEVICE } from "common/Device";
import { DatePicker, Spin } from "antd";
import ProductivityManagerToStaffRatio from "../molecules/ProductivityManagerToStaffRatio";
import ProductivityTopAreasAttrition from "../molecules/ProductivityTopAreasAttrition";
import ProductivityBasicSalaryInfo from "../molecules/ProductivityBasicSalaryInfo";
import ProductivityOvertime from "../molecules/ProductivityOvertime";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getUserRoleId } from "redux/selectors";
import NotFound from "organisms/NotFound";
import { connect } from "react-redux";
import { CALL_API } from "common/API";
import moment from "moment";

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .top_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .btn-style{
    border-radius: 5px;
  }
  .select_field {
    display: flex;
    gap: 10px;
  }
  .selectbox {
    width: 200px;
  }
  @media ${DEVICE.tablet} {
    .select_field {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .selectbox {
      width: 100%;
    }
    .align-right {
      width: 100%;
      margin-top: 10px;
      float: right;
    }
  }
`;

function Productivity({ userRoleId }) {
  const containerRef = useRef(null);
  const container = useRef(null);
  const defaultYear = moment().year();
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [topAreasAttritionData, setTopAreasAttritionData] = useState([]);

  const generatePDF = () => {
    const reportElement = containerRef.current;
    const reportHeight = reportElement.clientHeight;
    html2canvas(reportElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new JsPDF("p", "pt", [reportHeight, reportHeight]);
      pdf.addImage(imgData, "PNG", 0, 0, reportHeight, reportHeight);
      pdf.save("Productivity.pdf");
    });
  };

  const getData = async (year) => {
    toggleReportLoader(true);
    const yearString = year.format("YYYY");
    const { topAreasOfAttritionData } = await CALL_API(
      "admin-productivity",
      "post",
      {
        method: "adminProductivity",
        parameters: {
          year: yearString,
        },
      }
    );
    setTopAreasAttritionData(topAreasOfAttritionData);
    toggleReportLoader(false);
  };
  
  useEffect(() => {
    getData(moment(`${defaultYear}`, "YYYY"));
  }, []);
  return (
    <>
      {userRoleId == 5 && (
        <>
          <StyledTitle className="page-title-head">Productivity</StyledTitle>
          <StyledFilterRow>
            <Row className="top_row">
              <div ref={container}>
                <DatePicker.YearPicker
                  className="selectbox"
                  getPopupContainer={() => container.current}
                  defaultValue={moment(`${defaultYear}`, "YYYY")}
                  clearIcon={null}
                  onChange={getData}
                />
              </div>
              <Col className="align-right">
                <Button className='btn-style' type="primary" onClick={generatePDF}>
                  <span className="add-title">Download as PDF</span>
                </Button>
              </Col>
            </Row>
          </StyledFilterRow>
          <StyledFilterRow gutter={16} ref={containerRef}>
            <Col md={24} sm={24} xs={24} xl={12}>
              <ProductivityManagerToStaffRatio />
            </Col>
            <Col md={24} sm={24} xs={24} xl={12}>
              <ProductivityBasicSalaryInfo />
            </Col>
            <Col md={24} sm={24} xs={24} xl={12}>
              <ProductivityOvertime />
            </Col>
            <Col md={24} sm={24} xs={24} xl={12}>
              <Spin spinning={displayReportLoader}>
                <ProductivityTopAreasAttrition data={topAreasAttritionData} />
              </Spin>
            </Col>
          </StyledFilterRow>
        </>
      )}
      {userRoleId != 5 && <NotFound />}
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(Productivity);
