import React from "react";
import styled from "@emotion/styled";
import { Row, Col, Button } from "antd";
import {
  getLocations,
  getAllDepartmentList,
  getRoleList,
} from "redux/selectors";
import { connect } from "react-redux";
import moment from "moment";

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  font-weight: bold;
`;

const StyledCol = styled(Col)`
 /* background: yellow; */
 padding: 20px;
 box-shadow: 0px 3px 6px #00000014;
 margin:10px;
`;

const InReview = ({
  BasicInformation,
  department,
  location,
  role,
  departmentList,
  locationList,
  roleList,
  create,
  setStep,
  step,
  loading
}) => {
  const departmentdata =
    departmentList?.filter((item) =>
      department?.includes(item.department_id)
    ) || null;
  const locationdata =
    locationList?.filter((item) => location?.includes(item.location_id)) ||
    null;
  const roledata =
    roleList?.filter((item) => role?.includes(item.roleId)) || null;



  return (
    <>
      <Row className="p-2">
        <StyledCol xl={8}>
          <h5>Basic Information</h5>
          {Object.entries(BasicInformation).map(([key, value]) => (
            <Row>
            <Col xl={12}  key={key}><b>{key.charAt(0).toUpperCase() + key.slice(1)}:</b></Col><Col xl={12}>{(key === "start_date" || key === "end_date") ? moment(value).format('MMMM D, YYYY') : value == false ? "false" : value  == true ? "true" : value}</Col>
            </Row>
          ))}
        </StyledCol>
        {roledata.length > 0 && ( 
          <StyledCol xl={8}>
            <h5>Role</h5>
            <ul>
              {roledata?.map((item) => (
                <li key={item}>{item?.roleName}</li>
              ))}
            </ul>
          </StyledCol>
        )}

        {locationdata?.length > 0 && (
          <StyledCol xl={8}>
            <h5>Location</h5>
            <ul>
              {locationdata?.map((item) => (
                <li key={item}>{item?.location_name}</li>
              ))}
            </ul>
          </StyledCol>
        )}
        {departmentdata.length > 0 && (
          <StyledCol xl={8}>
            <h5>Department</h5>
            <ul>
              {departmentdata?.map((item) => (
                <li key={item}>{item?.department_name}</li>
              ))}
            </ul>
          </StyledCol>
        )}
      </Row>
      <div className = 'w-100 text-end'>
      <StyledButton className='mt-3 mx-2' onClick={()=>setStep(step - 1)}>Back</StyledButton>
      <StyledButton className='mt-3' onClick={create} loading={loading} type="primary">Create</StyledButton>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  departmentList: getAllDepartmentList(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps)(InReview);
